<template>
  <ion-page v-touch:swipe.right="onSwipeRight" v-touch:swipe.left="onSwipeLeft">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Courses</ion-title>
      </ion-toolbar>

      <ion-list :style="isPlatform('ios') ? 'margin-top: 10px;' : ''">
        <ion-item lines="full" style="height: 0px">
        </ion-item>
        <ion-item lines="full" class="white">
          <ion-button fill="clear" slot="start" @click="refresh()" >
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="isOpenAll" fill="clear" slot="start" @click="openAll()">
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!isOpenAll" fill="clear" slot="start" @click="closeAll()">
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" closeAllcolor="primary" :md="chevronUpOutline" :ios="chevronUpOutline"></ion-icon>
          </ion-button>
          <ion-button v-if="!isPlatform('mobile')" fill="clear" slot="start" @click="print()">
              <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="printOutline" :ios="printOutline"></ion-icon>
          </ion-button>
          <ion-button :title="$t('today')" fill="clear" slot="start" @click="goDateToday()">
            <ion-icon color="primary" slot="start" :md="todayOutline" :ios="todayOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-button fill="clear" slot="start" @click="goDateBack()">
            <ion-icon slot="start" color="primary" :md="arrowBackOutline" :ios="arrowBackOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-datetime :min="(new Date()).getFullYear() - 1" :max="(new Date()).getFullYear() + 1" slot="start" v-model="selectedDate" :day-short-names="customDayShortNames" display-format="MMM YY" placeholder="Select Date"></ion-datetime>
          <ion-button fill="clear" slot="start" @click="goDateForward()">
            <ion-icon slot="start" color="primary" :md="arrowForwardOutline" :ios="arrowForwardOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-select multiple="true" slot="end" @ionChange="setCategories()" :value="0" v-model="selectedCourseSelection">
            <ion-select-option :value="0">{{ $t('all')}}</ion-select-option>
            <ion-select-option v-for="(categorie, index) in courseCategories" :key="index" :value="categorie.id">{{ categorie.name }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </ion-header>
    
    <ion-content :fullscreen="true" id='print' ref="content">
      <h3 style="margin: 15px;" v-if="courses && courses.length <= 0">{{ $t('noCourses') }}</h3>
      <ion-card :id="(course.currentSlot ? 'currentSlot' : '')" v-for="(course, index) in courses" :key="index">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="timeOutline" :ios="timeOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap" color="primary">{{ $t(dayjs(course.begin).format('dddd')) }}, {{ dayjs(course.begin).format('DD.MM.YYYY HH:mm') }} - {{ dayjs(course.end).format('HH:mm') }}</ion-label>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding" @click="courses[index].showContent = !courses[index].showContent">
            <ion-icon v-if="courses[index].showContent == false" style="zoom: 1.0" slot="start" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline"></ion-icon>
            <ion-icon v-if="courses[index].showContent == true" style="zoom: 1.0" slot="start" color="primary" :md="chevronUpOutline" :ios="chevronUpOutline"></ion-icon>
            <ion-icon v-if="course.isSeries" style="zoom: 1.0" slot="start" color="primary" :md="listOutline" :ios="listOutline"></ion-icon>
            <ion-icon @click="register(course)" v-if="isLoggedIn && course.canRegister" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" slot="start" :color="getPercentage(course.registeredPlayers, course.teiln_max).color" :md="downloadOutline" :ios="downloadOutline"></ion-icon>
            <ion-icon @click="unregister(course)" v-if="isLoggedIn && course.canUnRegister" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" slot="start" color="warning" :md="exitOutline" :ios="exitOutline"></ion-icon>
            <ion-card-title v-if="courseCategories" :color="getCourseColor(course)" :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'">{{ course.name }} {{ course.category > 0 && courseCategories.find(f => f.id == course.category) ? ' (' + courseCategories.find(f => f.id == course.category).name + ')' : '' }} - {{ course.pro }}</ion-card-title>
            <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="(course.payOnline || course.showPrice) && course.priceFloat > 0" slot="end">{{ course.price }}</ion-card-title>
          </ion-item>
        </ion-card-header>
        <ion-card-content v-if="courses[index].showContent">
          <ion-item lines="full" style="margin: 0px;">
            <ion-label v-if="(course.canRegisterError > 0 && dayjs().isBefore(dayjs(course.begin)) || course.canRegisterError == 7)" :color="course.canRegisterError == 7 ? 'success' : 'warning'" style="padding-left: 15px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ $t('tournamentRegisterError' + course.canRegisterError).replace('#', $t('course')) }}</ion-label>
          </ion-item>
          <ion-item lines="full" style="margin: 0px;">
            <ion-label v-if="isLoggedIn && (course.canUnRegisterError > 0 && course.canUnRegisterError != 4 && dayjs().isBefore(dayjs(course.begin)))" color="warning" style="padding-left: 15px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ $t('tournamentUnRegisterError' + course.canUnRegisterError).replace('#', $t('course')) }}</ion-label>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ $t('participants') }}: {{ course.registeredPlayers }} {{ $t('of') }} {{ course.teiln_max }} ( {{ $t('guests') }} {{ course.teiln_gast_max }})</ion-label>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
          <ion-progress-bar style="margin-left: 15px;"  :color="getPercentage(course.registeredPlayers, course.teiln_max).color" :value="getPercentage(course.registeredPlayers, course.teiln_max).percentage"></ion-progress-bar>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('regPeriod') }}: {{ course.registration_period }} </ion-label>
          </ion-item >
          <ion-item v-for="(cs, index) in course.coursesSeries" :key="index" lines="none" style="margin: 0px;">
            <ion-label :color="( dayjs(course.begin).isSame(dayjs(cs.begin)) || dayjs(course.begin).isBefore(dayjs(cs.begin)) ? 'success' : '')" style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ cs.Last ? $t('courseEnd') : cs.First ? $t('courseStart') :  index + 1 }}: {{ dayjs(cs.begin).format('DD.MM.YYYY HH:mm') }} -  {{ dayjs(cs.end).format('DD.MM.YYYY HH:mm') }}</ion-label>
          </ion-item >
          <ion-item v-if="course.coursename != ''" lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('coursename') }}: {{ course.coursename }} </ion-label>
          </ion-item >
          <ion-item v-if="course.placename != ''" lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('placename') }}: {{ course.placename }} </ion-label>
          </ion-item >
          <ion-item v-if="course.priceinfo != ''" lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('priceinfo') }}: {{ course.priceinfo }} </ion-label>
          </ion-item >
          <ion-item v-if="course.onlinecomments != ''" lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" class="ion-text-wrap">{{ $t('onlinecomments') }}: {{ course.onlinecomments }} </ion-label>
          </ion-item >
          <ion-item class="ion-no-padding" v-if="course.showEntryList">
            <ion-label style="padding-left: 15px;" class="ion-text-wrap">{{ $t('participants') }}:</ion-label>
            <ion-icon @click="showEntryList(course)" :style="isPlatform('mobile') ? 'zoom: 0.8' : 'zoom: 1.2'" slot="end" color="primary" :md="listOutline" :ios="listOutline"></ion-icon>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonButton, IonCard, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCardTitle, IonCardSubtitle, 
  IonCardHeader, IonCardContent, IonIcon, IonDatetime, IonItem, IonList, IonInput, loadingController, IonLabel,
  modalController, IonImg, isPlatform, IonProgressBar, toastController, alertController, IonSelect, IonSelectOption
  } from '@ionic/vue';
import { arrowForwardOutline, arrowBackOutline, todayOutline, helpCircleOutline,refreshOutline, chevronDownOutline,
         chevronUpOutline, downloadOutline, listOutline, medalOutline, documentTextOutline, timeOutline,
         printOutline, manSharp, womanSharp, listCircleOutline, exitOutline,  } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import findCoursesByMonthQueryFile from '../graphql/findCoursesByMonth.query.gql'
import findCourseCategoriesQueryFile from '../graphql/findCourseCategories.query.gql'
import unregisterCourseMutationFile from '../graphql/unregisterCourse.mutation.gql'
import translate from '@/plugins/translate';
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import RegisterCourseDialog from './dialogs/RegisterCourseDialog'
import { useRoute, useRouter } from 'vue-router';
import ShowCourseEntryListDialog from './dialogs/ShowCourseEntryListDialog'

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard, IonItem, IonList, IonInput, IonImg, IonProgressBar, IonSelect, IonSelectOption,
    IonButton, IonCardTitle, IonCardSubtitle, IonCardHeader, IonCardContent, IonIcon, IonDatetime, IonLabel
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      isLoggedIn : function() { 
        if (this.isLoggedIn != undefined && this.isLoggedIn != this.$store.getters.isLoggedIn) {
          this.findCoursesByMonthRefetch()
        }
        return this.$store.getters.isLoggedIn
      },
      UserData : function(){ return this.$store.getters.UserData},
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    if (!route.params.date) {
      router.push('/courses/' + dayjs.tz(new Date()).format('YYYY-MM'))
    }
    let selectedDate = ref(dayjs.tz(new Date()).format('YYYY-MM'))
    if (route.params.date)
      selectedDate = ref(dayjs.tz(new Date(route.params.date)).format('YYYY-MM'))
    let loadingSpinner = null
    let isOpenAll = ref(true)
    let selectedCourseSelection = ref([0])
    const content = ref();
    const contentToScroll = ref(400)
    const currentSlot = ref();
    const customDayShortNames = [
      translate('sunday'),
      translate('monday'),
      translate('tuesday'),
      translate('wednesday'),
      translate('thursday'),
      translate('friday'),
      translate('saturday')
    ];

    const { refetch: findCoursesByMonthRefetch, result: findCoursesByMonthResult, loading: findCoursesByMonthLoading } = useQuery(findCoursesByMonthQueryFile, () => ({ date: dayjs(selectedDate.value).format('YYYY-MM-DD'), categories: JSON.stringify(selectedCourseSelection.value) }), { fetchPolicy: 'no-cache' })
    const courses = useResult(findCoursesByMonthResult, null, data => data.findCoursesByMonth.courses)

    const { result: findCourseCategoriesResult } = useQuery(findCourseCategoriesQueryFile, null, { fetchPolicy: 'no-cache' })
    const courseCategories = useResult(findCourseCategoriesResult, null, data => data.findCourseCategories.categories)

    const { mutate: unregisterCourseMutation } = useMutation(unregisterCourseMutationFile, { fetchPolicy: 'no-cache' });

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!findCoursesByMonthLoading.value) {
          setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.offsetTop) }, 1000)
          loadingSpinner.dismiss() 
        }
      })
    }).catch(err => { })

    watch(
      () => route.params.date,
      (date, prevId) => {
        if (route.path.startsWith('/courses') && date != undefined)
          selectedDate.value = dayjs.tz(new Date(route.params.date)).format('YYYY-MM')
      }
    )

    watch(findCoursesByMonthLoading, (findCoursesByMonthLoading, prevLoading) => {
      if (!findCoursesByMonthLoading && loadingSpinner != null) {
        setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.offsetTop) }, 1000)
        loadingSpinner.dismiss()
        return
      }
      else if (findCoursesByMonthLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!findCoursesByMonthLoading) {
                  setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.offsetTop) }, 1000)
                  loadingSpinner.dismiss() 
                }
              })
            }).catch(err => { })
    })

    return {
      findCoursesByMonthRefetch, findCoursesByMonthLoading, loadingSpinner,
      customDayShortNames,  selectedDate, courses, dayjs, isPlatform,
      todayOutline, arrowBackOutline, arrowForwardOutline,helpCircleOutline,refreshOutline, chevronDownOutline, 
      chevronUpOutline, downloadOutline, listOutline, medalOutline, documentTextOutline, timeOutline,
      printOutline, manSharp, womanSharp, isOpenAll, listCircleOutline, exitOutline,
      unregisterCourseMutation, selectedCourseSelection, courseCategories, content, contentToScroll, currentSlot
    }
  },
  methods: {
    async showEntryList(course) {
      const modal = await modalController
        .create({
          component: ShowCourseEntryListDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              selectedCourse: course,
            }
          },
        })
      return modal.present();
    },
    onSwipeRight() {
      this.goDateBack()
	  },
    onSwipeLeft() {
      this.goDateForward()
	  },
    setCategories() {

    },
    getCourseColor(course) {
      if (course.canRegisterError == 7)
        return 'success'
      if (dayjs().isBefore(dayjs(course.begin)) && course.canRegisterError > 0 )
        return 'warning'
    },
    getPercentage (bookedPlayers, maxPlayers) {
      const percentage = (bookedPlayers / maxPlayers) == 0 ? 1: bookedPlayers / maxPlayers
      let color = "successlight"
      if (bookedPlayers == maxPlayers)
        color = "danger"
      else if (percentage > 0 && percentage <= 0.25)
        color = "success"
      else if (percentage > 0.25 && percentage <= 0.5)
        color = "warninglight"
      else if (percentage > 0.5 && percentage <= 0.75)
        color = "warning"
      else if (percentage > 0.75 && bookedPlayers != 0)
        color = "dangerlight"

      return { percentage: percentage, color: color }
    },
    async register(course) {
      const modal = await modalController
        .create({
          component: RegisterCourseDialog,
          componentProps: {
            propsData: {
              course: course,
            }
          },
        })
        modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              this.findCoursesByMonthRefetch()
            }
          })
      return modal.present();

    },
    async unregister(course) {
      const alert = await alertController
        .create({
          header: this.$t('course'),
          message: this.$t('unregister') + ': ' +  course.name + '?',
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                this.unregisterCourseMutation({ id: parseInt(course.id), language: this.$store.getters.language})
                .then(res => {
                  toastController
                    .create({
                      message: this.$t('unregisterSuccessful'),
                      duration: 2000,
                      color: 'warning'
                    }).then(res => res.present())
                    this.findCoursesByMonthRefetch()
                })
                .catch(err => {
                  toastController
                    .create({
                      message: this.$t('unregisterNotSuccessful' + err),
                      duration: 2000,
                      color: 'danger',
                    }).then(res => res.present())
                })
              },
            },
          ],
        });
      return alert.present();
    },
    refresh() {
      this.isOpenAll = true
      this.findCoursesByMonthRefetch()
    },
    openAll() {
      for (const t of this.courses) {
        t.showContent = true
      }
      this.isOpenAll = false
    },
    closeAll() {
      for (const t of this.courses) {
        t.showContent = false
      }
      this.isOpenAll = true
    },
    print() {
      window.print();    
    },
    goDateToday() {
      this.$router.push('/courses/' + dayjs.tz(new Date()).format('YYYY-MM'))
      this.selectedDate = dayjs.tz(new Date()).format('YYYY-MM')
    },
    goDateBack() {
      this.$router.push('/courses/' + dayjs.tz(this.selectedDate).subtract(1, 'month').format('YYYY-MM'))
      this.selectedDate = dayjs.tz(this.selectedDate).subtract(1, 'month').format('YYYY-MM')
    },
    goDateForward() {
      this.$router.push('/courses/' + dayjs.tz(this.selectedDate).add(1, 'month').format('YYYY-MM'))
      this.selectedDate = dayjs.tz(this.selectedDate).add(1, 'month').format('YYYY-MM')
    },
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

ion-button {
  --padding-end: 0;
  --inner-padding-end: 0;
  margin-inline: 0px 15px;
}

ion-item {
  --ion-background-color: white !important;
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-item.white {
  --ion-background-color: white !important;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>