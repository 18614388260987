<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Teetimes</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <h3 style="margin-left: 15px;" v-if="teetimes && teetimes.length <= 0">{{ $t('noTeetimes') }}</h3>
      <ion-card v-for="(teetime, index) in teetimes" :key="index">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding" router-direction="root" :router-link="'/teetime/' + teetime.id + '/' + dayjs(new Date()).tz().format('YYYY-MM-DD')">
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="golfOutline" :ios="golfOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap" color="primary">{{ teetime.name }}</ion-label>
          </ion-item>
        </ion-card-header>
        <ion-item lines="full">
          <ion-grid>
            <ion-row>
              <ion-col style="text-align: left;font-size: 14px;" :size="index == 5 ? 6 : 1" :size-lg="index == 5 ? 6 : 1" :size-sm="index == 5 ? 2 : 2" :size-xs="index == 5 ? 2 : 2" v-for="(col, index) in teetime.nextSlots" :key="index" router-direction="root" :router-link="'/teetime/' + teetime.id + '/' + dayjs(col.date).tz().format('YYYY-MM-DD')">
                <ion-label>{{ dayjs(col.date).tz().format('DD.MM.') }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col style="text-align: left;" :size="index == 5 ? 6 : 1" :size-lg="index == 5 ? 6 : 1" :size-sm="index == 5 ? 2 : 2" :size-xs="index == 5 ? 2 : 2" v-for="(col, index) in teetime.nextSlots" :key="index" router-direction="root" :router-link="'/teetime/' + teetime.id + '/' + dayjs(col.date).tz().format('YYYY-MM-DD')">
                <ion-icon style="zoom: 1.0" color="success" :md="calendarClearSharp" :ios="calendarClearSharp"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item v-if="teetime.dayComment != '' || teetime.additionalText != '' || teetime.sunset != ''">
          <ion-grid class="ion-no-padding">
            <ion-row class="ion-no-padding">
              <span style="color: orange;" v-if="teetime && teetime.sunset != ''">{{ $t('sunsetInfoToday')}}:&nbsp;</span><span v-html="teetime.sunset" class="ion-no-padding ion-no-margin" style="margin-left: 0px; white-space: pre-wrap;  color: orange;" v-if="teetime && teetime.sunset != ''"></span>
            </ion-row>
            <ion-row class="ion-no-padding">
              <span v-if="teetime && teetime.dayComment != ''">{{ $t('courseInfoToday')}}:&nbsp;</span><span v-html="teetime.dayComment" class="ion-no-padding ion-no-margin" style="margin-left: 0px; white-space: pre-wrap;" v-if="teetime && teetime.dayComment != ''"></span>
            </ion-row>
            <ion-row>
              <span class="ion-no-padding ion-no-margin" v-if="teetime && teetime.additionalText != ''" v-html="teetime.additionalText" ></span>
            </ion-row>
          </ion-grid>
        </ion-item>

      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController } from '@ionic/vue';
import { defineComponent, watch } from 'vue';
import { personOutline, calendarClearSharp, golfOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getTeetimesQueryFile from '../graphql/getTeetimes.query.gql'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Teetimes',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null
   const router = useRouter()

    const {onResult, result: getTeetimesResult, loading: getTeetimesLoading } = useQuery(getTeetimesQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const teetimes = useResult(getTeetimesResult, null, data => data.getTeetimes.teetimes)
    onResult(queryResult => {
      if (queryResult.data && queryResult.data.getTeetimes && queryResult.data.getTeetimes.teetimes.length == 1) {
        router.push('/teetime/' + queryResult.data.getTeetimes.teetimes[0].id + '/' + dayjs.tz().format('YYYY-MM-DD'))
      }
    })

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getTeetimesLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getTeetimesLoading, (getTeetimesLoading, prevLoading) => {
      if (!getTeetimesLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getTeetimesLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getTeetimesLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      teetimes, personOutline, calendarClearSharp, dayjs, golfOutline
    }
  },
  methods: {
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>