<template>
  <ion-page>
      <ion-content :fullscreen="true">
        <ion-grid class="ion-no-padding ion-no-margin">
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-button @click="doRefresh()" slot="start" fill="clear" class="ion-no-padding" >
                  <ion-icon color="primary" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
                </ion-button>
                <ion-select v-if="paceOfPlayFound && paceOfPlayFound.length > 0" @ionChange="changeRound($event.target.value)" :value="selectedRound.roundID">
                  <ion-select-option v-for="(round, index) in paceOfPlayFound" :key="index" :value="round.roundID">{{ round.roundName }}</ion-select-option>
                </ion-select>
                <ion-label v-else>Keine Turniere vorhanden</ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          </ion-grid>

          <EasyDataTable v-if="paceOfPlayFound && paceOfPlayFound.length > 0"
            :headers="headers"
            :items="items" alternating
            :rows-per-page="1000"
            table-class-name="customize-table"
            :hide-footer="true">
            <template #empty-message>
              <div>{{ 'Keine Daten vorhanden' }}</div>
            </template>
            <template #header-time1="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time2="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time3="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time4="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time5="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time6="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time7="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time8="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time9="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time10="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time11="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time12="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time13="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time14="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time15="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time16="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time17="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #header-time18="header">
              <div class="customize-header" style="text-align: center;">
                {{ header.text.split('<br>')[0] }}<br>
                {{ header.text.split('<br>')[1] }}
              </div>
            </template>
            <template #item-spielgruppe="item">
              <div style="font-weight: bold;">{{ item.spielgruppe.index }}</div>
              <div>{{ item.spielgruppe.startTime }}</div>
              <div>{{ item.spielgruppe.startTee }}</div>
            </template>
            <template #item-spieler="item">
              <div v-for="(person, index) in item.spieler" :key="index">{{ person.name }}</div>
            </template>
            <template #item-time1="item">
              <div :style="item.spielgruppe.startTeeNum == 1 ? 'color: red;' : ''">{{ 1 }} {{ item.time1.isHW ? 'HW' : '' }}<ion-icon v-if="item.time1.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time1.currentHole == true ? 'background-color: green;' : ''">{{ item.time1.time }}</div>
            </template>
            <template #item-time2="item">
              <div :style="item.spielgruppe.startTeeNum == 2 ? 'color: red;' : ''">{{ 2 }} {{ item.time2.isHW ? 'HW' : '' }}<ion-icon v-if="item.time2.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time2.currentHole == true ? 'background-color: green;' : ''">{{ item.time2.time }}</div>
            </template>
            <template #item-time3="item">
              <div :style="item.spielgruppe.startTeeNum == 3 ? 'color: red;' : ''">{{ 3 }} {{ item.time3.isHW ? 'HW' : '' }}<ion-icon v-if="item.time3.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time3.currentHole == true ? 'background-color: green;' : ''">{{ item.time3.time }}</div>
            </template>
            <template #item-time4="item">
              <div :style="item.spielgruppe.startTeeNum == 4 ? 'color: red;' : ''">{{ 4 }} {{ item.time4.isHW ? 'HW' : '' }}<ion-icon v-if="item.time4.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time4.currentHole == true ? 'background-color: green;' : ''">{{ item.time4.time }}</div>
            </template>
            <template #item-time5="item">
              <div :style="item.spielgruppe.startTeeNum == 5 ? 'color: red;' : ''">{{ 5}} {{ item.time5.isHW ? 'HW' : '' }}<ion-icon v-if="item.time5.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time5.currentHole == true ? 'background-color: green;' : ''">{{ item.time5.time }}</div>
            </template>
            <template #item-time6="item">
              <div :style="item.spielgruppe.startTeeNum == 6 ? 'color: red;' : ''">{{ 6 }} {{ item.time6.isHW ? 'HW' : '' }}<ion-icon v-if="item.time6.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time6.currentHole == true ? 'background-color: green;' : ''">{{ item.time6.time }}</div>
            </template>
            <template #item-time7="item">
              <div :style="item.spielgruppe.startTeeNum == 7 ? 'color: red;' : ''">{{ 7 }} {{ item.time7.isHW ? 'HW' : '' }}<ion-icon v-if="item.time7.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time7.currentHole == true ? 'background-color: green;' : ''">{{ item.time7.time }}</div>
            </template>
            <template #item-time8="item">
              <div :style="item.spielgruppe.startTeeNum == 8 ? 'color: red;' : ''">{{ 8 }} {{ item.time8.isHW ? 'HW' : '' }}<ion-icon v-if="item.time8.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time8.currentHole == true ? 'background-color: green;' : ''">{{ item.time8.time }}</div>
            </template>
            <template #item-time9="item">
              <div :style="item.spielgruppe.startTeeNum == 9 ? 'color: red;' : ''">{{ 9 }} {{ item.time9.isHW ? 'HW' : '' }}<ion-icon v-if="item.time9.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time9.currentHole == true ? 'background-color: green;' : ''">{{ item.time9.time }}</div>
            </template>

            <template #item-time10="item">
              <div :style="item.spielgruppe.startTeeNum == 10 ? 'color: red;' : ''">{{ 10 }} {{ item.time10.isHW ? 'HW' : '' }}<ion-icon v-if="item.time10.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time10.currentHole == true ? 'background-color: green;' : ''">{{ item.time10.time }}</div>
            </template>
            <template #item-time11="item">
              <div :style="item.spielgruppe.startTeeNum == 11 ? 'color: red;' : ''">{{ 11 }} {{ item.time11.isHW ? 'HW' : '' }}<ion-icon v-if="item.time11.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time11.currentHole == true ? 'background-color: green;' : ''">{{ item.time11.time }}</div>
            </template>
            <template #item-time12="item">
              <div :style="item.spielgruppe.startTeeNum == 12 ? 'color: red;' : ''">{{ 12 }} {{ item.time12.isHW ? 'HW' : '' }}<ion-icon v-if="item.time12.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time12.currentHole == true ? 'background-color: green;' : ''">{{ item.time12.time }}</div>
            </template>
            <template #item-time13="item">
              <div :style="item.spielgruppe.startTeeNum == 13 ? 'color: red;' : ''">{{ 13 }} {{ item.time13.isHW ? 'HW' : '' }}<ion-icon v-if="item.time13.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time13.currentHole == true ? 'background-color: green;' : ''">{{ item.time13.time }}</div>
            </template>
            <template #item-time14="item">
              <div :style="item.spielgruppe.startTeeNum == 14 ? 'color: red;' : ''">{{ 14 }} {{ item.time14.isHW ? 'HW' : '' }}<ion-icon v-if="item.time14.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time14.currentHole == true ? 'background-color: green;' : ''">{{ item.time14.time }}</div>
            </template>
            <template #item-time15="item">
              <div :style="item.spielgruppe.startTeeNum == 15 ? 'color: red;' : ''">{{ 15 }} {{ item.time15.isHW ? 'HW' : '' }}<ion-icon v-if="item.time15.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time15.currentHole == true ? 'background-color: green;' : ''">{{ item.time15.time }}</div>
            </template>
            <template #item-time16="item">
              <div :style="item.spielgruppe.startTeeNum == 16 ? 'color: red;' : ''">{{ 16 }} {{ item.time16.isHW ? 'HW' : '' }}<ion-icon v-if="item.time16.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time16.currentHole == true ? 'background-color: green;' : ''">{{ item.time16.time }}</div>
            </template>
            <template #item-time17="item">
              <div :style="item.spielgruppe.startTeeNum == 17 ? 'color: red;' : ''">{{ 17 }} {{ item.time17.isHW ? 'HW' : '' }}<ion-icon v-if="item.time17.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time17.currentHole == true ? 'background-color: green;' : ''">{{ item.time17.time }}</div>
            </template>
            <template #item-time18="item">
              <div :style="item.spielgruppe.startTeeNum == 18 ? 'color: red;' : ''">{{ 18 }} {{ item.time18.isHW ? 'HW' : '' }}<ion-icon v-if="item.time18.hasScore" color="success" :md="createOutline" :ios="createOutline"></ion-icon></div>
              <div :style="item.time18.currentHole == true ? 'background-color: green;' : ''">{{ item.time18.time }}</div>
            </template>

          </EasyDataTable>

    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,IonImg,IonSelect, IonSelectOption,
IonInput, IonItem, IonList } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import getPaceOfPlayQueryFile from '../graphql/getPaceOfPlay.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import { timeOutline, carOutline, createOutline, refreshOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput, IonSelect, IonSelectOption,
    IonHeader, IonItem, IonList,
    IonPage, IonImg,
    IonTitle, IonIcon,
    IonToolbar, IonGrid, IonRow, IonCol,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    
    const { result: paceOfPlayResult, refetch: paceOfPlayRefetch } = useQuery(getPaceOfPlayQueryFile, null, () => ({ enabled: true, fetchPolicy: 'no-cache', pollInterval: 60000 }))
    const paceOfPlayFound = useResult(paceOfPlayResult, null, data => data.getPaceOfPlay.rounds)
    let selectedRound = ref(null)
    const headers = ref([ 
      { text: "Spielgruppe", value: "spielgruppe" },
      { text: "Spieler", value: "spieler" },
    ])
    const items = ref([])

    watch(paceOfPlayFound, (newVal, preVal) => {
      console.log("🚀 ~ file: PaceOfPlay.vue:253 ~ watch ~ newVal:", newVal)
      if (newVal && newVal[0]) {
        if (selectedRound.value == null)
          selectedRound.value = newVal[0]
        else 
          selectedRound.value = newVal.find(f => f.roundID == selectedRound.value.roundID)

        headers.value = [ 
          { text: "Spielgruppe", value: "spielgruppe" },
          { text: "Spieler", value: "spieler" },
        ]
        items.value = []

        for (const hole of selectedRound.value.roundHoles) {
          headers.value.push({ text: hole.holeNum.toString() + "<br>(" + hole.interval + ")", value: "time" + hole.holeNum })
        }
        for (const flight of selectedRound.value.flights) {
          items.value.push({ "spielgruppe": { index: 'Spielgruppe ' + flight.flightIndex, startTime: flight.startTime, startTeeNum: flight.startTee, startTee: "Tee: " + flight.startTee, lastScoreTee: flight.lastScoreTee }, "spieler" : flight.players, 
          "time1" : flight.paceOfPlayTimes.find(f => f.holeNum == 1),
          "time2" : flight.paceOfPlayTimes.find(f => f.holeNum == 2),
          "time3" : flight.paceOfPlayTimes.find(f => f.holeNum == 3),
          "time4" : flight.paceOfPlayTimes.find(f => f.holeNum == 4),
          "time5" : flight.paceOfPlayTimes.find(f => f.holeNum == 5),
          "time6" : flight.paceOfPlayTimes.find(f => f.holeNum == 6),
          "time7" : flight.paceOfPlayTimes.find(f => f.holeNum == 7),
          "time8" : flight.paceOfPlayTimes.find(f => f.holeNum == 8),
          "time9" : flight.paceOfPlayTimes.find(f => f.holeNum == 9),
          "time10" : flight.paceOfPlayTimes.find(f => f.holeNum == 10),
          "time11" : flight.paceOfPlayTimes.find(f => f.holeNum == 11),
          "time12" : flight.paceOfPlayTimes.find(f => f.holeNum == 12),
          "time13" : flight.paceOfPlayTimes.find(f => f.holeNum == 13),
          "time14" : flight.paceOfPlayTimes.find(f => f.holeNum == 14),
          "time15" : flight.paceOfPlayTimes.find(f => f.holeNum == 15),
          "time16" : flight.paceOfPlayTimes.find(f => f.holeNum == 16),
          "time17" : flight.paceOfPlayTimes.find(f => f.holeNum == 17),
          "time18" : flight.paceOfPlayTimes.find(f => f.holeNum == 18)
          })
        }
      }
    })

    return {
      paceOfPlayFound, selectedRound, headers, items, createOutline, refreshOutline, paceOfPlayRefetch
    }
  },
  methods: {
    doRefresh() {
      this.paceOfPlayRefetch()
    },
    changeRound(round) {
      this.selectedRound = this.paceOfPlayFound.find(f => f.roundID == round)
      this.headers = [
        { text: "Spielgruppe", value: "spielgruppe" },
        { text: "Spieler", value: "spieler" },
      ]
      this.items = []
      for (const hole of this.selectedRound.roundHoles) {
          this.headers.push({ text: hole.holeNum.toString() + "<br>(" + hole.interval + ")", value: "time" + hole.holeNum })
        }
        for (const flight of this.selectedRound.flights) {
          this.items.push({ "spielgruppe": { index: 'Spielgruppe ' + flight.flightIndex, startTime: flight.startTime, startTeeNum: flight.startTee, startTee: "Tee: " + flight.startTee, lastScoreTee: flight.lastScoreTee }, "spieler" : flight.players, 
          "time1" : flight.paceOfPlayTimes.find(f => f.holeNum == 1),
          "time2" : flight.paceOfPlayTimes.find(f => f.holeNum == 2),
          "time3" : flight.paceOfPlayTimes.find(f => f.holeNum == 3),
          "time4" : flight.paceOfPlayTimes.find(f => f.holeNum == 4),
          "time5" : flight.paceOfPlayTimes.find(f => f.holeNum == 5),
          "time6" : flight.paceOfPlayTimes.find(f => f.holeNum == 6),
          "time7" : flight.paceOfPlayTimes.find(f => f.holeNum == 7),
          "time8" : flight.paceOfPlayTimes.find(f => f.holeNum == 8),
          "time9" : flight.paceOfPlayTimes.find(f => f.holeNum == 9),
          "time10" : flight.paceOfPlayTimes.find(f => f.holeNum == 10),
          "time11" : flight.paceOfPlayTimes.find(f => f.holeNum == 11),
          "time12" : flight.paceOfPlayTimes.find(f => f.holeNum == 12),
          "time13" : flight.paceOfPlayTimes.find(f => f.holeNum == 13),
          "time14" : flight.paceOfPlayTimes.find(f => f.holeNum == 14),
          "time15" : flight.paceOfPlayTimes.find(f => f.holeNum == 15),
          "time16" : flight.paceOfPlayTimes.find(f => f.holeNum == 16),
          "time17" : flight.paceOfPlayTimes.find(f => f.holeNum == 17),
          "time18" : flight.paceOfPlayTimes.find(f => f.holeNum == 18)
          })
        }
    },
  }
});
</script>

<style scoped>

.customize-table {
  --easy-table-header-font-size: 16px;
  --easy-table-body-row-font-size: 16px;
  --easy-table-body-even-row-background-color: lightgrey;
  --easy-table-body-row-hover-background-color: grey;
}

main {
  padding-top: 5px;
  padding-left: 10px;
    position: relative;
    z-index: 1;
}

main div.tees ul,
main div.tees p {
    margin: 0;
}

main div.tees {
    background: #e8e8e8 !important;
    padding: 3px;
}

main table.fixed,
main div.tees {
    position: fixed;
    left: 50%;
    margin-left: -512px;
    width: 1024px;
    background: #fff;
}

main table.main.scroll {
    margin-top: 45px;
}

.main {
    width: 100%;
}

main table tbody tr:nth-child(odd) {
    background: #e8e8e8;
}

.main thead th:nth-of-type(2) {
    text-align: left;
}

.main thead th span.interval {
    font-weight: normal;
}

.main tbody td,
.main tbody th,
.main thead th {
    padding: 5px;
}

main table tbody th span.starttime,
main table tbody th span.starttee {
    font-weight: normal;
}


main table tbody td {
    text-align: center;
}

main table tbody tr td:nth-of-type(1) {
    width: 100%;
}

main table tbody td:first-of-type {
    text-align: left;
}

main table th span {
    display: block;
}

.card {
  background-color: rgb(75, 59, 219); 
}

.playercard {
   height: auto;
}

.player {
   padding: 5px !important;
}

.minion { 
  font-family: "Minion, Arial" !important;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

</style>