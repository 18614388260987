<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Profile</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <section>
        <!--<ion-card v-if="isLoggedIn" id="paypalPayment">
          <ion-card-header>
            <ion-card-title>{{ $t('paypalPayment')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content v-if="!isSuccessfullBraintreePaypalAuthorized">
            <ion-list>
              <ion-card-title v-if="paypalLoading">{{ $t('loading')}}</ion-card-title>
              <ion-card-title v-if="paypalError">{{ paypalError }}</ion-card-title>
              <div class="form-group text-center">
                  <div id="paypalButton"></div>
              </div>
              <ion-button style="margin-top: 15px;" color="primary" @click="requestPaypalAuthorize()">{{ $t('authorizePayments')}}</ion-button>
            </ion-list>
          </ion-card-content>
          <ion-card-content v-else>
            <ion-label>{{ $t('paypalAuthorized')}}</ion-label><br>
            <ion-button style="margin-top: 15px;" color="warning" @click="revokePaypalAuthorize()">{{ $t('revokePayments')}}</ion-button>
          </ion-card-content>
        </ion-card>

        <ion-card v-if="isLoggedIn" id="creditCardPayment">
          <ion-card-header>
            <ion-card-title>{{ $t('creditCardPayment')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content v-if="!isSuccessfullBraintreeCreditCardAuthorized">
            <ion-list>
              <ion-card-title v-if="creditCardLoading">{{ $t('loading')}}</ion-card-title>
              <ion-card-title v-if="creditCardError">{{ creditCardError }}</ion-card-title>
              <div id="dropin-container"></div>
              <div v-show="showCreditCardInfo && !creditCardLoading && showCreditCardAddButton">  
                  <ion-button style="margin-top: 15px;" color="success" @click="sendCreditCardData()">{{ $t('accept')}}</ion-button>
              </div>
              <ion-button v-show="!showCreditCardInfo" style="margin-top: 15px;" color="primary" @click="preparePayWithCreditCard()">{{ $t('authorizePayments')}}</ion-button>
            </ion-list>
          </ion-card-content>
          <ion-card-content v-else>
            <ion-label>{{ $t('creditCardAuthorized')}}</ion-label><br>
            <ion-label>{{ maskedNumber }} ({{ expirationDate }})</ion-label><br>
            <ion-button style="margin-top: 15px;" color="warning" @click="revokeCreditCardAuthorize()">{{ $t('revokePayments')}}</ion-button>
          </ion-card-content>
        </ion-card>-->

      <ion-card>
        <ion-card-header>
          <ion-card-title>{{ $t('userData')}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
             <!-- <section>
             <ion-item>
                <ion-label>{{ $t('language') }}</ion-label>
                <ion-select :value="language" @ionChange="setLanguage($event.target.value)">
                  <ion-select-option value="de">{{ $t('de') }}</ion-select-option>
                  <ion-select-option value="en">{{ $t('en') }}</ion-select-option>
                  <ion-select-option value="it">{{ $t('it') }}</ion-select-option>
                </ion-select>
              </ion-item>
            </section>-->
            <section v-if="isLoggedIn">
              <ion-item>
                <ion-label position="stacked">{{ $t('firstName') }}</ion-label>
                <ion-input disabled="true" placeholder="Max" type="text" v-model="UserData.firstName"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ $t('lastName') }}</ion-label>
                <ion-input disabled="true" placeholder="Mustermann" type="text" v-model="UserData.lastName"></ion-input>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ $t('birthdate') }}</ion-label>
                <ion-datetime disabled="true" value="1990-02-20" display-format="DD.MM.YYYY"  placeholder="Select Date" v-model="UserData.birthdate" ></ion-datetime>
              </ion-item>
              <ion-item>
                <ion-label position="stacked">{{ $t('nickname') }}</ion-label>
                <ion-input :disabled="selectedNickname == 'demouser'" :placeholder="$t('nickname')" type="text" v-model="selectedNickname" @ionChange="nicknameChanged()"></ion-input>
                <ion-icon v-if="nicknameOK && selectedNickname.trim() != '' && selectedNicknameChanged > 0" style="zoom: 1.0;" color="success" :md="checkmarkOutline" :ios="checkmarkOutline"></ion-icon>
                <ion-icon v-if="!nicknameOK && selectedNickname.trim() != '' && selectedNicknameChanged > 0" style="zoom: 1.0;" color="danger" :md="closeOutline" :ios="closeOutline"></ion-icon>
                <ion-button :disabled="!nicknameOK || selectedNickname.trim() == '' || selectedNicknameChanged == 0" expand="block" color="primary" @click="saveNickname()">{{ $t('save')}}</ion-button>
              </ion-item>
              <ion-item>
                <ion-button :disabled="selectedNickname == 'demouser'" color="warning"  @click="deleteAccount()">
                  {{ $t('deleteAccount')}}
                </ion-button>
              </ion-item>

              <ion-item v-if="clubInfo && clubInfo.clubInfo && (clubInfo.clubInfo.maskedNumber == '494436' || clubInfo.clubInfo.maskedNumber == '499008')">
                <ion-checkbox @click="selectionChangedGolfChain()" color="primary" :disabled="setGolfChain == undefined || setGolfChain == true" v-model="setGolfChain"
                  :checked="setGolfChain" slot="start">
                </ion-checkbox>
                <ion-label class="ion-text-wrap" style="margin-left: 5px;">{{ $t("golfChain") }}</ion-label>
              </ion-item>
<!--              <ion-item>
                <ion-label position="stacked">{{ $t('hcp') }}</ion-label>
                <ion-label position="stacked" style="font-size: 20px">{{ UserData.hcp }}</ion-label>
                <ion-label position="stacked" style="font-size: 20px">{{ dayjs(UserData.hcpLastSync).format('DD.MM.YYYY HH:mm:ss') }}</ion-label><br>
                <ion-button style="margin-bottom: 15px" color="primary" @click="syncHCP()">{{ $t('syncHCP')}}</ion-button>
              </ion-item> -->
              <!-- <ion-button  style="margin-top: 15px;" expand="block" color="primary" @click="saveUserData()">{{ $t('save')}}</ion-button>-->
            </section>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-card v-if="isLoggedIn">
        <ion-card-header>
          <ion-card-title>{{ $t('profilePicture') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item>
              <ion-avatar slot="start" v-if="UserData.imageUrl" >
                <img :src="UserData.imageUrl">
              </ion-avatar>
              <ion-icon v-if="!UserData.imageUrl" style="margin-top: 15px" :ios="personSharp" :md="personSharp"></ion-icon>
              <ion-button v-if="UserData.imageUrl" color="warning" slot="start" @click="deletePicture()">
                <ion-icon color="danger" :ios="trashOutline" :md="trashOutline"></ion-icon>
              </ion-button>
            </ion-item>
            <ion-item v-if="UserData.imageUrl">
              <ion-label>{{ $t("showAvatar") }}</ion-label>
              <ion-checkbox color="primary" 
                :checked="UserData.showAvatar" @ionChange="selectionChanged($event.target.checked)" slot="start">
              </ion-checkbox>
            </ion-item>            
            <ion-item>
              <div v-if="imageUrl" class="image-wrapper" style="margin-top: 10px;">
                <img :src="imageUrl ? imageUrl.dataUrl : null" />
              </div>
              <ion-toolbar style="margin-top: 0px;">
                <ion-button slot="start"  expand="block" color="primary" @click="takePicture()">{{ $t('takePicture')}}</ion-button>
                <ion-button slot="end" v-if="imageUrl" expand="block" color="primary" @click="uploadPicture()">{{ $t('upload')}}</ion-button>
              </ion-toolbar>
            </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>

      <ion-card v-if="isLoggedIn">
        <ion-card-header>
          <ion-card-title>{{ $t('displayOptions') }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list>
            <ion-item>
              <ion-label class="ion-text-wrap">{{ $t("showName") }}</ion-label>
              <ion-checkbox color="primary" 
                :checked="UserData.showName" @ionChange="selectionChangedShowName($event.target.checked)" slot="start">
              </ion-checkbox>
            </ion-item>            
            <ion-item>
              <ion-label class="ion-text-wrap">{{ $t("showHCP") }}</ion-label>
              <ion-checkbox color="primary" 
                :checked="UserData.showHCP" @ionChange="selectionChangedShowHCP($event.target.checked)" slot="start">
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label class="ion-text-wrap">{{ $t("showGender") }}</ion-label>
              <ion-checkbox color="primary" 
                :checked="UserData.showGender" @ionChange="selectionChangedShowGender($event.target.checked)" slot="start">
              </ion-checkbox>
            </ion-item>
            <ion-item>
              <ion-label class="ion-text-wrap">{{ $t("allow4USearch") }}</ion-label>
              <ion-checkbox color="primary" 
                :checked="allow4USearch" @ionChange="selectionChangedAllow4USearch($event.target.checked)" slot="start">
              </ion-checkbox>
            </ion-item>                 
            <ion-item>
              <ion-label>{{ $t("privacyInfoscreen") }}</ion-label>
              <ion-checkbox color="primary" 
                :checked="!privacyInfoscreen" @ionChange="selectionChangedPrivacyInfoscreen($event.target.checked)" slot="start">
              </ion-checkbox>
            </ion-item>       

          </ion-list>
        </ion-card-content>
      </ion-card>

      </section>

    </ion-content>
  </ion-page>
</template>


<script>

import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar, alertController,
  modalController, IonLabel, IonList, IonSelectOption, IonSelect, IonItem,
  IonCardTitle, IonCardHeader, IonInput, IonDatetime, IonCardContent, IonCard,
  toastController, IonAvatar, IonIcon, IonCheckbox, IonGrid, IonRow, IonCol
} from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import RegisterDialog from './Register'
import LoginDialog from './Login'
import { getClubInfo } from '@/composition'

import {
  useMutation, useQuery, useResult,
} from '@vue/apollo-composable'
import Storage from "../plugins/storage.js";

import getPersonProfileQueryFile from '../graphql/getPersonProfile.query.gql'
import checkNicknameQueryFile from '../graphql/checkNickname.query.gql'
import { ImageResizer, ImageResizerOptions } from '@ionic-native/image-resizer';
import { personSharp, trashOutline, addCircleOutline, removeCircleOutline, checkmarkOutline, closeOutline } from 'ionicons/icons';
import * as dayjs from 'dayjs';
import { Camera, CameraSource, CameraResultType } from '@capacitor/camera';
//import { Geolocation } from '@capacitor/geolocation';

/*import {
  Plugins,
  CameraSource,
  CameraResultType,
  CameraPhoto,
} from "@capacitor/core";*/

//const { Camera } = Plugins;
const { imageResizer } = ImageResizer;

import requestPasswordResetMutationFile from '../graphql/requestPasswordReset.mutation.gql'
import uploadUserImageMutationFile from '../graphql/uploadUserImage.mutation.gql'
import deleteUserImageMutationFile from '../graphql/deleteUserImage.mutation.gql'
import changeShowAvatarMutationFile from '../graphql/changeShowAvatar.mutation.gql'
import changeShowNameMutationFile from '../graphql/changeShowName.mutation.gql'
import changeShowHCPMutationFile from '../graphql/changeShowHCP.mutation.gql'
import changeShowGenderMutationFile from '../graphql/changeShowGender.mutation.gql'
import changeAllow4USearchMutationFile from '../graphql/changeAllow4USearch.mutation.gql'
import createBraintreeCustomer from '../graphql/createBraintreeCustomer.mutation.gql'
import revokeBraintreePayment from '../graphql/revokeBraintreePayment.mutation.gql'
import getBraintreeClientToken from '../graphql/getBraintreeClientToken.mutation.gql'
import removeBraintreeCreditCard from '../graphql/removeBraintreeCreditCard.mutation.gql'
import saveNicknameMutationFile from '../graphql/saveNickname.mutation.gql'
import deleteAccountMutationFile from '../graphql/deleteAccount.mutation.gql'
import syncHCPMutationFile from '../graphql/syncHCP.mutation.gql'
import logoutUserMutationFile from '../graphql/logoutUser.mutation.gql'
import { client, hostedFields, paypalCheckout, threeDSecure } from 'braintree-web';

//import AddCreditDialog from './dialogs/AddCreditDialog'

import getPersonAddressFile from '../graphql/getPersonAddress.query.gql'
import savePersonAddressMutationFile from '../graphql/savePersonAddress.mutation.gql'
import getPersonFile from '../graphql/getPerson.query.gql'
import savePersonInfoScreenMutationFile from '../graphql/savePersonInfoScreen.mutation.gql'
import savePersonGolfChainMutationFile from '../graphql/savePersonGolfChain.mutation.gql'

//import paypal from 'paypal-checkout';
var Dropin = require('braintree-web-drop-in')

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonList, IonLabel, IonSelectOption, IonSelect, IonItem,
    IonCardTitle, IonCardHeader, IonInput, IonDatetime, IonCardContent, IonCard,IonAvatar, IonIcon,
    IonCheckbox, IonGrid, IonRow, IonCol
  },
  setup() {
    let selectedNickname = ref('')
    const selectedNicknameChanged = ref(0)
    const {onResult, result: getPersonProfileResult, refetch: getPersonProfileRefetch } = useQuery(getPersonProfileQueryFile, () => ({ }), () => ({ fetchPolicy: 'no-cache' }) )
    const nickname = useResult(getPersonProfileResult, null, data => data.getPersonProfile.nickname)
    const allow4USearch = useResult(getPersonProfileResult, null, data => data.getPersonProfile.allow4USearch)
    const privacyInfoscreen = useResult(getPersonProfileResult, null, data => data.getPersonProfile.privacyInfoscreen)
    const isGolfChain = useResult(getPersonProfileResult, null, data => data.getPersonProfile.isGolfChain)
    const setGolfChain = ref(false)
    const clubInfo = ref(getClubInfo())

    watch(isGolfChain, (isGolfChainNew, isGolfChainOld) => {
      setGolfChain.value = isGolfChainNew
    })
    onResult(queryResult => {

      if (queryResult.data && queryResult.data.getPersonProfile && queryResult.data.getPersonProfile.nickname) {
        selectedNickname.value = queryResult.data.getPersonProfile.nickname
        if (selectedNickname.value.trim() != '') selectedNicknameChanged.value = -1
      }})

    const {result: checkNicknameResult, refetch: checkNicknameRefetch } = useQuery(checkNicknameQueryFile, () => ({ nickname: selectedNickname.value }), () => ({ fetchPolicy: 'no-cache', enabled: selectedNickname.value && selectedNickname.value.length > 0 }) )
    const nicknameOK = useResult(checkNicknameResult, null, data => data.checkNickname.success)

    const { mutate: requestPasswordResetMutation } = useMutation(requestPasswordResetMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: uploadUserImageMutation } = useMutation(uploadUserImageMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteUserImageMutation } = useMutation(deleteUserImageMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: changeShowAvatarMutation } = useMutation(changeShowAvatarMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: changeShowNameMutation } = useMutation(changeShowNameMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: changeShowHCPMutation } = useMutation(changeShowHCPMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: changeShowGenderMutation } = useMutation(changeShowGenderMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: changeAllow4USearchMutation } = useMutation(changeAllow4USearchMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: syncHCPMutation } = useMutation(syncHCPMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createBraintreeCustomerMutation } = useMutation(createBraintreeCustomer);
    const { mutate: revokeBraintreePaymentMutation } = useMutation(revokeBraintreePayment);
    const { mutate: getBraintreeClientTokenMutation } = useMutation(getBraintreeClientToken);
    const { mutate: removeBraintreeCreditCardMutation } = useMutation(removeBraintreeCreditCard);
    const { mutate: saveNicknameMutation } = useMutation(saveNicknameMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteAccountMutation } = useMutation(deleteAccountMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: logoutUserMutation } = useMutation(logoutUserMutationFile, { fetchPolicy: 'no-cache' });

    const { mutate: savePersonAddressMutation } = useMutation(savePersonAddressMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: savePersonInfoScreenMutation } = useMutation(savePersonInfoScreenMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: savePersonGolfChainMutation } = useMutation(savePersonGolfChainMutationFile, { fetchPolicy: 'no-cache' });
    

    return {
      requestPasswordResetMutation,uploadUserImageMutation, deleteUserImageMutation, changeShowAvatarMutation,
      changeShowNameMutation, changeShowHCPMutation, changeShowGenderMutation, syncHCPMutation, createBraintreeCustomerMutation,
      personSharp, trashOutline, dayjs, revokeBraintreePaymentMutation, getBraintreeClientTokenMutation, removeBraintreeCreditCardMutation, addCircleOutline, removeCircleOutline, nickname, selectedNickname, nicknameOK, checkmarkOutline, closeOutline, selectedNicknameChanged, saveNicknameMutation, getPersonProfileRefetch, checkNicknameRefetch, deleteAccountMutation, logoutUserMutation,
      savePersonAddressMutation,
      privacyInfoscreen,savePersonInfoScreenMutation, savePersonGolfChainMutation,
      allow4USearch, changeAllow4USearchMutation, clubInfo, isGolfChain, setGolfChain
    }
  },
  computed: {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      language : function(){ return this.$store.getters.language},
      UserData : function(){ return this.$store.getters.UserData},
      selectedClub : function(){ return this.$store.getters.selectedClub},
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      maskedNumber : function(){ return this.$store.getters.maskedNumber},
      expirationDate : function(){ return this.$store.getters.expirationDate },
  },
  data() {
    return {
      imageUrl: null,
      paypalLoading: false,
      paypalError: '',
      showCreditCardInfo: false,
      showCreditCardAddButton: true,
      dropInInstance: false,
      creditCardLoading: false,
      creditCardError: '',
      credentials: {
        sandbox: 'Ad38lxH_U0Jf922RtIYsNt0mk2ShzIGmp7EEGMsFlybSgwibV0lvhw2inxuippjMMy2kxKxCgsOpq4xA',
        production: ''
      }
    }
  },
  mounted() {
    this.$store.dispatch('login')
    //const script = document.createElement("script")
    //script.src = "https://www.paypalobjects.com/api/checkout.js"
    //const coordinates = await Geolocation.getCurrentPosition();
    //console.log('Current position:', coordinates);
  },
  methods: {
    async deleteAccount() {
      const alert = await alertController
        .create({
          header: this.$t('deleteAccount'),
          message: this.$t('wantToDeleteAccount'),
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                this.deleteAccountMutation()
                .then(res => {
                  if (res.data.deleteAccount.success) {
                    toastController
                      .create({
                        message: this.$t('changeSuccessful'),
                        duration: 3000,
                        color: 'warning'
                      }).then(res => res.present())

                    // logout
                    this.logout()
                  }
                  else {
                    toastController
                      .create({
                        message: this.$t('changeNotSuccessful'),
                        duration: 3000,
                        color: 'danger'
                      }).then(res => res.present())
                  }
                })
              },
            },
          ],
        });
      return alert.present();
    },
    async logout() {
        const refreshToken = await Storage.getItem('refreshToken')
        this.logoutUserMutation({
          refreshToken: refreshToken,
          deviceToken: ''
        })
        .then(res => {
          this.$router.push('/home')
          this.$store.dispatch('logout')
        })
        .catch(err => {
        })
    },
    saveNickname() {
      this.saveNicknameMutation( { nickname: this.selectedNickname })
      .then(res => {
        if (res.data.saveNickname.success) {
          this.selectedNicknameChanged = 0
          toastController
            .create({
              message: this.$t('changeSuccessful'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
        }
        else {
          this.selectedNicknameChanged = 0
          toastController
            .create({
              message: this.$t('changeNotSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })

    },
    syncHCP() {
      this.syncHCPMutation({ personId: this.UserData.id})
      .then(res => {
        if (res.data.syncHCP.success) {
          toastController
            .create({
              message: this.$t('hcpSyncOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
          this.$store.dispatch('setUserHCP', res.data.syncHCP.hcp)
          this.$store.dispatch('setUserHCPDate', res.data.syncHCP.hcpLastSync)
          this.$store.dispatch('refreshToken')
        }
        else {
          toastController
            .create({
              message: this.$t('hcpErrorSync'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('hcpErrorSync'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
    async nicknameChanged() {
      this.selectedNicknameChanged++
    },
    async selectionChanged(value) {
      this.changeShowAvatarMutation({ id: this.UserData.id, showAvatar: value })
      .then(res => {
        this.$store.dispatch('setUserDataShowAvatar', value)
        this.$store.dispatch('refreshToken')
      })
      .catch(err => {
      }) 
    },
    async selectionChangedShowName(value) {
      this.changeShowNameMutation({ id: this.UserData.id, showName: value })
      .then(res => {
        this.$store.dispatch('setUserDataShowName', value)
        this.$store.dispatch('refreshToken')
      })
      .catch(err => {
      }) 
    },
    async selectionChangedShowHCP(value) {
      console.log('selectionChangedShowHCP',value)
      this.changeShowHCPMutation({ id: this.UserData.id, showHCP: value })
      .then(res => {
        this.$store.dispatch('setUserDataShowHCP', value)
        this.$store.dispatch('refreshToken')
      })
      .catch(err => {
      }) 
    },
    async selectionChangedShowGender(value) {
      this.changeShowGenderMutation({ id: this.UserData.id, showGender: value })
      .then(res => {
        this.$store.dispatch('setUserDataShowGender', value)
        this.$store.dispatch('refreshToken')
      })
      .catch(err => {
      }) 
    },
    async selectionChangedAllow4USearch(value) {
      this.changeAllow4USearchMutation({ allow4USearch: value })
      .then(res => {
      })
      .catch(err => {
      }) 
    },  
    async selectionChangedGolfChain() {
      const alert = await alertController
        .create({
          header: this.$t('setGolfChainHeader'),
          message: this.$t('setGolfChainText'),
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
              handler: async () => {
                this.setGolfChain = false
              },
            },
            {
              text: this.$t('ok'),
              handler: async () => {
                // save setting
                this.savePersonGolfChainMutation( { golfChain: this.setGolfChain })
                .then(res => {
                  if (res.data.savePersonGolfChain.success) {     
                  }
                  else {
                    toastController
                      .create({
                        message: this.$t('changeNotSuccessful'),
                        duration: 3000,
                        color: 'danger'
                      }).then(res => res.present())
                  }
                })
              },
            },
          ],
        });
      return alert.present();
    },
    async selectionChangedPrivacyInfoscreen(value) {      
      this.savePersonInfoScreenMutation( { privacyInfoscreen: value })
      .then(res => {
        if (res.data.savePersonInfoScreen.success) {     
        }
        else {
          toastController
            .create({
              message: this.$t('changeNotSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })

    },
    async setLanguage(language) {
      await Storage.setItem('language', language);
      setTimeout(() => { this.$router.go() }, 400)
      
    },
    uploadPicture() {
      this.uploadUserImageMutation({ dataUrl: this.imageUrl.dataUrl, id: this.UserData.id })
      .then(res => {
        this.$store.dispatch('setUserDataImageUrl', res.data.uploadUserImage)
        this.$store.dispatch('refreshToken')
        this.imageUrl = null
      })
      .catch(err => {
      }) 
    },
    deletePicture() {
      this.deleteUserImageMutation({ id: this.UserData.id })
      .then(res => {
        this.$store.dispatch('deleteUserDataImageUrl')
        this.$store.dispatch('refreshToken')
      })
      .catch(err => {
      }) 
    },
    async takePicture() {
      try {
        const image = await Camera.getPhoto({
          quality: 100,
          allowEditing: true,
          resultType: CameraResultType.DataUrl,
          source: CameraSource.Prompt,
        });

        const dim = await getImageDimensions(image.dataUrl)
        const imageResize = await resizedataURL(image.dataUrl, dim.w, dim.h, 200)
        // image.base64_data will contain the base64 encoded result as
        // a JPEG, with the data-uri prefix added
        image.dataUrl = imageResize
        this.imageUrl = image;
        return image;

      } catch (e) {
        return e;
      }
    },
    revokeCreditCardAuthorize(){
      this.revokeBraintreePaymentMutation({ input: {
                                            "type": 'CreditCard',
                                            } })
      .then(res => {
        var result = res.data.revokeBraintreePayment.success
        if(result==true){
          this.$store.dispatch('setSuccessfullBraintreeCreditCardAuthorized', false)
          toastController.create({message: this.$t('paymentMethodDeleted') ,duration: 3000,color: 'warning',}).then(res => res.present())
        }else{
          toastController.create({message: this.$t('notSuccessful'),duration: 3000,color: 'danger',}).then(res => res.present())
        }
      })
      .catch(err => {        
          toastController
            .create({
              message: err,
              duration: 10000,
              color: 'danger',
            }).then(res => res.present())
      }) 
    },
    revokePaypalAuthorize(){
      this.revokeBraintreePaymentMutation({ input: {
                                            "type": 'PayPalAccount',
                                            } })
      .then(res => {
        var result = res.data.revokeBraintreePayment.success
        if(result==true){
          this.$store.dispatch('setSuccessfullBraintreePaypalAuthorized', false)
          toastController.create({message: this.$t('paymentMethodDeleted'),duration: 3000,color: 'warning',}).then(res => res.present())
        }else{
          toastController.create({message: this.$t('notSuccessful'),duration: 3000,color: 'danger',}).then(res => res.present())
        }
      })
      .catch(err => {        
          toastController
            .create({
              message: err,
              duration: 10000,
              color: 'danger',
            }).then(res => res.present())
      }) 
    },
    requestPaypalAuthorize(){   
      // TODO create clientId in server side      
      this.initBraintree();
    },
    async initBraintree() {

        client.create({
            authorization: 'sandbox_387z2srv_v7n2535n6snzkw74'
        })
        .then(clientInstance => {
          
            return paypalCheckout.create({ client: clientInstance });
        })
        .then(instances => {
            const paypalInstance = instances;
            paypal.Button.render({
                env: 'sandbox',
                style: {
                    label: 'paypal',
                    size: 'responsive',
                    shape: 'rect'
                },

                payment: async () => {
                     const p = await paypalInstance.createPayment({
                      flow: 'vault', // Required

                      // The following are optional params
                      //billingAgreementDescription: 'Club In One'
                    })
                     console.log("🚀 ~ file: Profile.vue ~ line 528 ~ initBraintree ~ p", p)
                    return p
                },
                onAuthorize: (data, options) => {
                console.log("🚀 ~ file: Profile.vue ~ line 532 ~ initBraintree ~ data", data)
                    return paypalInstance.tokenizePayment(data).then(payload => {
                        console.log("🚀 ~ file: Profile.vue ~ line 534 ~ returnpaypalInstance.tokenizePayment ~ payload", payload)
                        if(payload && payload.nonce){
                            this.createBraintreeCustomerMutation({ input: {
                                                                  "nonceFromTheClient": payload.nonce,
                                                                  "type": payload.type,
                                                                  } })
                            .then(res => {
                              var result = res.data.createBraintreeCustomer.success
                              if(result==true){
                                this.$store.dispatch('setSuccessfullBraintreePaypalAuthorized', true)
                                toastController.create({message: this.$t('paymentMethodCreated'),duration: 3000,color: 'success',}).then(res => res.present())
                              }else{
                                toastController.create({message: this.$t('notSuccessful'),duration: 10000,color: 'danger',}).then(res => res.present())
                              }
                            })
                            .catch(err => {        
                                toastController
                                  .create({
                                    message: err,
                                    duration: 10000,
                                    color: 'danger',
                                  }).then(res => res.present())
                            }) 
                          
                        }else{
                          this.paypalError = this.$t('notSuccessful');
                        }

                    })
                },
                onCancel: (data) => {
                },
                onError: (error) => {
                    this.paypalError = error;
                }
            }, '#paypalButton')

        })
        .catch(err => {
            this.paypalError = err;
        })
    },
    async preparePayWithCreditCard() {
      this.creditCardLoading = true
      this.showCreditCardInfo = true
      this.showCreditCardAddButton = true
      let clientToken = ''
      await this.getBraintreeClientTokenMutation()
      .then(res => {
        clientToken = res.data.getBraintreeClientToken.clientToken
      })
      .catch(err => {
          this.showCreditCardInfo = false
          this.creditCardError = err
      })
      
      if (clientToken != '') {
        await Dropin.create({
          authorization: clientToken,
          selector: '#dropin-container',
          locale: this.language + '_' + this.language.toUpperCase(),
          threeDSecure: true
        })
        .then(instance => {
          this.dropInInstance = instance
        })
        .catch(err => {
            this.showCreditCardInfo = false
            this.creditCardError = err;
        })
        this.creditCardLoading = false
      }
    }, 
    async sendCreditCardData() {
      if(this.dropInInstance)
      {
        this.showCreditCardAddButton = false
        await this.dropInInstance.requestPaymentMethod({
          threeDSecure: {
            amount: '0.00',
          }
        })
        .then(async payload => {
          if (!payload.liabilityShifted && payload.liabilityShiftPossible) {
            this.creditCardError = this.$t('creditCardAuthorizedFailed')
            await this.removeBraintreeCreditCardMutation()
            this.dropInInstance.clearSelectedPaymentMethod()
            this.showCreditCardAddButton = true
            return
          }
          this.createBraintreeCustomerMutation({ input: {
                                                          "nonceFromTheClient": payload.nonce,
                                                          "type": payload.type,
                                                          } })
                    .then(res => {
                      var result = res.data.createBraintreeCustomer.success
                      if(result==true){
                        this.$store.dispatch('setSuccessfullBraintreeCreditCardAuthorized', true)
                        this.$store.dispatch('setMaskedNumber', payload.details.bin + '******' + payload.details.lastFour)
                        this.$store.dispatch('setExpirationDate', payload.details.expirationMonth + '/' + payload.details.expirationYear)
                        toastController.create({message: this.$t('paymentMethodCreated'),duration: 3000,color: 'success',}).then(res => res.present())
                        this.showCreditCardInfo = false
                      }else{
                        toastController.create({message: this.$t('notSuccessful'),duration: 10000,color: 'danger',}).then(res => res.present())
                      }
                    })
                    .catch(err => {        
                        toastController
                          .create({
                            message: err,
                            duration: 10000,
                            color: 'danger',
                          }).then(res => res.present())
                    }) 
          //showNonce(payload, true);
          // send nonce and verification data to your server        
        })
        .catch(err => {
            this.creditCardError = err;
        })

            /*this.hostedFieldsInstance.tokenize()
            .then(payload => {
                if(payload && payload.nonce){
                    this.createBraintreeCustomerMutation({ input: {
                                                          "nonceFromTheClient": payload.nonce,
                                                          "type": payload.type,
                                                          } })
                    .then(res => {

                      client.create({
                        // Use the generated client token to instantiate the Braintree client.
                        authorization: res.data.createBraintreeCustomer.clientToken
                      }).then(function (clientInstance) {
                        return threeDSecure.create({
                          version: 2, // Will use 3DS2 whenever possible
                          client: clientInstance
                        });
                      }).then(function (threeDSecureInstance) {
                            threeDSecureInstance.verifyCard({
                                amount: '0.00',
                                nonce: res.data.createBraintreeCustomer.nonce,
                                bin: payload.details.bin,
                                onLookupComplete: function (data, next) {
                                    // use `data` here, then call `next()`
                                    next();
                                  }
                            }).then(function (response) {
                              var liabilityShifted = response.liabilityShifted; // true || false
                              var liabilityShiftPossible =  response.liabilityShiftPossible; // true || false
                              
                              // liabilityShiftPossible indicates that the payment method was eligible for 3D Secure. If liabilityShifted is false, then the user failed 3D Secure authentication. In this situation, the card brands recommend asking the user for another form of payment. However, if you have server-side risk assessment processes that allow for it, you can still use the new nonce to create a transaction. If you want to use a nonce that did not pass 3D Secure authentication, you need to set the required option to false in your server integration.

                              // liabilityShifted indicates that 3D Secure worked and authentication succeeded. This will also be true if the issuing bank does not support 3D Secure, but the payment method does. In both cases, the liability for fraud has been shifted to the bank. You should go on creating a transaction using the new nonce.

                              // If both of the above values are false then this card was ineligible for 3D Secure. You can continue to create the transaction with the new nonce. However, liability shift will not apply to this transaction. This case may be useful if you would like to ask the user for additional verification (AVS, etc).

                              // Send response.nonce to your server for use in your integration
                              // The 3D Secure Authentication ID can be found
                              //  at response.threeDSecureInfo.threeDSecureAuthenticationId
                            }).catch(function (error) {
                              
                              // Handle error
                            });
                      }).catch(function (err) {
                        // Handle component creation error
                      });

                      var result = res.data.createBraintreeCustomer.success
                      if(result==true){
                        this.$store.dispatch('setSuccessfullBraintreeCreditCardAuthorized', true)
                        toastController.create({message: this.$t('paymentMethodCreated'),duration: 3000,color: 'success',}).then(res => res.present())
                      }else{
                        toastController.create({message: this.$t('notSuccessful'),duration: 10000,color: 'danger',}).then(res => res.present())
                      }
                    })
                    .catch(err => {        
                        toastController
                          .create({
                            message: err,
                            duration: 10000,
                            color: 'danger',
                          }).then(res => res.present())
                    }) 
                  
                }else{
                  this.creditCardError = this.$t('notSuccessful');
                }

            })
            .catch(err => {
                if(typeof err.message !== 'undefined')
                {
                    this.creditCardError = err.message;
                }
                else
                {
                    this.creditCardError = this.$t('notSuccessful');
                }
            })*/
      }
    },
  }
});

function getImageDimensions(file) {
  return new Promise (function (resolved, rejected) {
    var i = new Image()
    i.onload = function(){
      resolved({w: i.width, h: i.height})
    };
    i.src = file
  })
}

function resizedataURL(datas, wantedWidth, wantedHeight, dWidth){
    return new Promise(async function(resolve,reject){

        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function()
        {        
            // We create a canvas and get its context.
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            // crop
            var cropleftright = wantedWidth > wantedHeight ? ((wantedWidth - wantedHeight) / 2) : 0
            wantedWidth = wantedWidth > wantedHeight ? wantedHeight : wantedWidth
            var croptopbottom = wantedWidth < wantedHeight ? ((wantedHeight - wantedWidth) / 2) : 0
            wantedHeight = wantedWidth < wantedHeight ? wantedWidth : wantedHeight

            // We set the dimensions at the wanted size.
            canvas.width = dWidth;
            canvas.height = dWidth;

            // We resize the image with the canvas method drawImage();
            ctx.drawImage(this, cropleftright, croptopbottom, wantedWidth, wantedHeight, 0, 0, dWidth, dWidth);

            var dataURI = canvas.toDataURL();

            // This is the return of the Promise
            resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;

    })
}

</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>
