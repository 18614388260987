<template>
  <ion-page>
    <ion-content>
      <div style="text-align:center; margin: 15px;" data-header-ad-wrapper>
        <VueScriptComponent v-if="adLinkTop && adLinkTop !=''" :script="adLinkTop" />
      </div>
        <ion-grid>
          <ion-row>
            <ion-col>
                <div class="cf wrap noTop">
                <div class="controls cf">
                  <div class="refresh">
                    Aktualisierung alle <select name="refresh" class="refresh" v-model="selectedRefresh">
                      <option v-for="refresh in refreshSelect" :key="refresh.id" :value="refresh.id">{{ refresh.name }}</option>
                      </select>
                      <a @click="refreshScore()" class="refresh"><img src="~@/assets/images/refresh.gif"></a>
                  </div>
                </div>
              </div>
            </ion-col>
          </ion-row>
          <ion-row class="ion-no-margin ion-no-padding">
            <table style="margin-left: 10px;margin-top: 0px;margin-bottom: 10px;">
            <tr>
            <td style="vertical-align: center; width: auto !important;">
              <select v-if="scoringMode != 3 && viewType.length > 1" name="viewMode" title="Ansicht" class="query" v-model="selectedViewType">
                <option v-for="vType in viewType" :key="vType.id" :value="vType.id">{{ vType.name }}</option>
              </select><br v-if="scoringMode != 3 && viewType.length > 1">
              <select v-if="selectedViewType == 1" name="rId" title="Runde" class="query" v-model="selectedSingleRound">
                <option  v-for="round in singleScoresRounds" :key="round.id" :value="round.id">{{ $t('round')  }} {{ round.roundIndex }}</option>
              </select>
            </td>
            <td style="vertical-align: top;width: 60px;">
              <div><img style="margin-left: 10px;" width="60" :src="logoImg"></div>
            </td>
            <td style="vertical-align: center;width: auto; ">
              <h3 v-if="matchDays" style="margin-left:15px;">{{ matchDays[0].name }}</h3>
            </td>
            </tr>
            </table>    
          </ion-row>
          <ion-row  v-if="currentTicker && currentTicker.tickerText != ''">
            <ion-col>
              <marquee-text class="ticker-text" :key="currentTicker.id" :duration="8" :repeat="15">
                {{ currentTicker.tickerText }}&nbsp; &nbsp; &nbsp; 
              </marquee-text>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid v-if="teamScores.length == 0 && singleScoresRounds.length == 0 && !getWebTeamScoreLoading && !getWebSingleScoreLoading">
          <ion-row>
            <ion-col>
              <div class="head">
                <p>{{ $t('noScoresFound') }}</p>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid v-if="scoringMode != 3 && teamScores.length > 0 && selectedViewType == 0"> <!-- Team View -->
          <ion-row>
            <ion-col>

              <div v-for="(team, index) in teamScores" :key="index" :value="team">
                <div class="head">
                  <span class="rank">{{ team.rank }}</span><span class="club">{{ team.name  }}</span><span class="score">{{ getSign(team.totalScore)    }}{{ team.totalScore  }}</span>
                </div>

                <div  v-for="round in team.rounds" :key="round" :value="round">
                  <div class="roundInfo">Runde {{ round.roundIndex  }} -  {{ round.playForm  }}</div>

                  <table class="ranking">
                      <thead>
                      <tr>
                      <th>Name</th>
                      <th>Club</th>
                      <th>HCPI&#8482;</th>
                      <th>+/-</th>
                      <th>{{ $t('thru') }}</th>
                      <th>{{ $t('total') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                        <template v-for="player in round.players" :key="player">
                          <tr :class="player.partnerNum == 2 ? 'teamEnd' : ''">
                            <td style="width: 20%"><span @click="player.showScorecard = !player.showScorecard">{{ player.name }}</span></td>
                            <td>{{ player.clubName }}</td>
                            <td>{{ player.hcp }}</td>
                            <td>{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.isStreicher ? '(' + getPlusSign(player.score) + ')' : getPlusSign(player.score) }}</td>
                            <td>{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles }}</td>
                            <td>{{ player.partnerNum == 2 ? '' : player.showTeetime != '' ? player.showTeetime : player.playedHoles == 'F' ? player.isStreicher ? '(' + player.strokes + ')' : player.strokes : '-' }}</td>
                          </tr>
                          <tr v-if="player.showScorecard" class="playerScore">
                            <td colspan="6">
                              <table class="playerScore">
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th v-for="i in 18" :key="i">{{ i }}</th>
                                    <th>+/-</th>
                                    <th>{{ $t('total') }}</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Par</td>
                                    <td class="small" v-for="par in player.pars" :key="par">{{ par }}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                <tr>
                                <td></td>
                                <td :class="'small ' +  getScoreClass(player.pars[index], player.scores[index])" v-for="(score, index) in player.scores" :key="index" :value="score">{{ score == 0 ? '-' : score  }}</td>
                                <td class="small">{{ player.score }}</td>
                                <td>{{ player.strokes }}</td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <p class="legend">
                            <span class="square eagle"></span> - Eagle oder besser ({{ getParDiff(player.pars, player.scores, -2) }})
                            <span class="square birdie"></span> - Birdie ({{ getParDiff(player.pars, player.scores, -1) }})
                            <span class="square par"></span> - Par ({{ getParDiff(player.pars, player.scores, 0) }})
                            <span class="square bogey"></span> - Bogey ({{ getParDiff(player.pars, player.scores, 1) }})
                            <span class="square doublebogey"></span> - Double Bogey ({{ getParDiff(player.pars, player.scores, 2) }})
                            <span class="square triplebogey"></span> - Triple Bogey oder schlechter ({{ getParDiff(player.pars, player.scores, 3) }})
                          </p>
                        </td>
                        </tr>
                        </template>
                        <tr class="sum">
                          <td>{{ $t('total') }}:</td>
                          <td></td>
                          <td></td>
                          <td><strong>{{ getSign(round.score) }}{{ round.score }}</strong></td>
                          <td></td>
                          <td><strong v-if="round.players.filter(f => f.playedHoles != 'F').length == 0">{{ round.strokes }}</strong></td>
                       </tr>
                      </tbody>
                    </table>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid v-if="scoringMode != 3 && singleScoresRounds.length > 0 && selectedViewType == 1"> <!-- Single View -->
          <ion-row>
            <ion-col>
              <table class="singleRanking">
                <thead>
                <tr>
                  <th>{{ $t('position') }}</th>
                  <th>Name</th>
                  <th>Club</th>
                  <th>HCPI&#8482;</th>
                  <th>+/-</th>
                  <th>{{ $t('thru') }}</th>
                  <th v-for="round in singleScoresRoundsDisplay" :key="round">R{{ round.roundIndex }}</th>
                  <th>{{ $t('total') }}</th>
                </tr>
                </thead>
                <tbody>
                  <template v-for="player in singleScoresPlayers" :key="player">
                    <tr :class="player.partnerNum == 2 ? 'teamEnd' : ''">
                      <td>{{ player.partnerNum == 2 ? '' : player.rank }}</td>
                      <td>
                          <img style="vertical-align:middle" width="16" v-if="player.flag && player.flag != ''" :src="require('@/assets/images/flags_iso/' + player.flag + '.png')"><span style="margin-top:0px;" @click="player.showScorecard = !player.showScorecard">{{ player.name }}</span>
                      </td>
                      <td>{{ player.clubName }}</td>
                      <td>{{ player.hcp }}</td>
                      <td>{{ player.partnerNum == 2 ? '' : getPlusSign(player.totalScore) }}</td>
                      <td>{{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : player.playedHoles }}</td>
                      <td v-for="(round, index) in singleScoresRoundsDisplay" :key="index">{{ player.partnerNum == 2 ? '' : player.prevStrokes[index] > 0 ? player.prevStrokes[index]: getPlusSign(player.score) }}</td>
                      <td>{{ player.showTeetime != '' ? player.partnerNum == 2 ? '' : player.showTeetime : player.partnerNum == 2 ? '' : player.prevStrokes && player.prevStrokes.length > 0 && player.prevStrokes.find(f => f == 0) ? '-' : player.totalStrokes }}</td>
                    </tr>
                    <tr v-if="player.showScorecard" class="playerScore">
                      <td colspan="6">
                        <table class="playerScore">
                          <thead>
                            <tr>
                              <th></th>
                              <th v-for="i in 18" :key="i">{{ i }}</th>
                              <th>+/-</th>
                              <th>{{ $t('total') }}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Par</td>
                              <td class="small" v-for="par in player.pars" :key="par">{{ par }}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td :class="'small ' +  getScoreClass(player.pars[index], player.scores[index])" v-for="(score, index) in player.scores" :key="index" :value="score">{{ score == 0 ? '-' : score }}</td>
                              <td class="small">{{ getPlusSign(player.score) }}</td>
                              <td>{{ player.strokes }}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <p class="legend">
                          <span class="square eagle"></span> - {{ $t('eagle') }} ({{ getParDiff(player.pars, player.scores, -2) }})
                          <span class="square birdie"></span> - {{ $t('birdie') }} ({{ getParDiff(player.pars, player.scores, -1) }})
                          <span class="square par"></span> - {{ $t('par') }} ({{ getParDiff(player.pars, player.scores, 0) }})
                          <span class="square bogey"></span> - {{ $t('bogey') }} ({{ getParDiff(player.pars, player.scores, 1) }})
                          <span class="square doublebogey"></span> - {{ $t('doublebogey') }} ({{ getParDiff(player.pars, player.scores, 2) }})
                          <span class="square triplebogey"></span> - {{ $t('triplebogey') }} ({{ getParDiff(player.pars, player.scores, 3) }})
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              </ion-col>
          </ion-row>
        </ion-grid>

        <ion-grid v-if="scoringMode == 3"> <!-- Matchplay View -->
          <ion-row>
            <ion-col>
              <table class="ranking matchplay">
                <colgroup>
                  <col width="*">
                  <col width="100">
                  <col width="100">
                  <col width="100">
                  <col width="*">
                </colgroup>
                <tbody>
                  <tr>
                  <th style="text-align:right">Spieler</th>
                  <th style="text-align: center">Punkte</th>
                  <th style="text-align: center">Ergebnis</th>
                  <th style="text-align: center">Punkte</th>
                  <th style="text-align:left">Spieler</th>
                  </tr>
                  <template v-for="player in singleScoresPlayers" :key="player">
                    <tr style="cursor: pointer;" @click="player.showScorecard = !player.showScorecard">
                      <td style="text-align:right">
                      <a >{{ player.name }} <span style="font-size: smaller">({{ player.hcp }})</span></a><br>
                      <span style="font-size: smaller">{{ player.clubName }}</span>
                      </td>
                      <td style="text-align: center;">
                      <a>{{ player.score }}</a>
                      </td>
                      <td style="text-align: center;">
                      <a>{{ player.scoreLabel }}<br v-if="player.isCompleted != ''">{{ player.isCompleted }}
                      </a>
                      </td>
                      <td style="text-align: center;">
                      <a>{{ player.scoreOpponent }}</a>
                      </td>
                      <td>
                        <a >{{ player.opponentName }} <span style="font-size: smaller">({{ player.opponentHcp }})</span></a><br>
                      <span style="font-size: smaller">{{ player.opponentClubName }}</span>
                      </td>
                    </tr>
                    <tr v-if="player.showScorecard" class="playerScore">
                      <td colspan="5">
                        <table class="playerScore matchplay">
                          <tbody>
                            <tr>
                              <td>Loch</td>
                              <td v-for="i in 18" :key="i">{{ i }}</td>
                            </tr>
                            <tr>
                              <td>{{ player.name }}</td>
                              <td v-for="label in player.scoresLabel" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                            </tr>
                            <tr class="as">
                              <td></td>
                              <td v-for="label in player.scoresLabel" :key="label">{{ label == 'AS' || label == '--' ? label : '' }}</td>
                            </tr>
                            <tr>
                              <td>{{ player.opponentName }}</td>
                              <td v-for="label in player.scoresLabelOpponent" :key="label">{{ label != 'AS' && label != '--' ? label : '' }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </template>
              </tbody>
              </table>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div style="max-width: 1024px; width: auto; margin:0 auto;">
                <div><img width="1024" :src="footerImg"></div>
              </div>                
              <div style="font-size:small; color: black; margin:0 auto;">
                <div style="margin: 10px;"><a href="https://www.clubinone.de" target="_blank">Powered by CLUB IN ONE</a></div>
              </div>                

      </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonContent, IonGrid, IonRow, IonCol, loadingController  } from '@ionic/vue';
import { defineComponent, computed, watch, ref, getCurrentInstance } from 'vue';
import { useQuery } from '@vue/apollo-composable'
import getWebNavigationQueryFile from '../graphql/getWebNavigation.query.gql'
import getWebTeamScoreQueryFile from '../graphql/getWebTeamScore.query.gql'
import getWebSingleScoreQueryFile from '../graphql/getWebSingleScore.query.gql'
import {  } from 'ionicons/icons';
import VueScriptComponent from 'vue-script-component'
import { useRoute, useRouter } from 'vue-router';
require('@/assets/images/arrow.gif')
import MarqueeText from 'vue-marquee-text-component'

export default defineComponent({
  name: 'Home',
  components: {
    VueScriptComponent, IonPage, IonContent, IonGrid, IonRow, IonCol, MarqueeText
  },
  data() {
    return {
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    let loadingSpinner = null
    let isLoaded = ref(false)
    let selectedNav = ref(undefined)
    let selectedMatchDay = ref(0)
    let selectedViewType = ref(-1)
    let selectedRefresh = ref(1)
    let selectedSingleRound = ref(0)
    let singleScoresRoundsDisplay = ref([])
    const app = getCurrentInstance()
    const changeLanguage = app.appContext.config.globalProperties.$changeLanguage

    const { result: getWebNavigationResult, refetch: getWebNavigationRefetch } = useQuery(getWebNavigationQueryFile, () => ({ tid: parseInt(route.params.tid) }), () => ({ fetchPolicy: 'no-cache', enabled: route.params.tid != undefined } ))

    const { result: getWebTeamScoreResult, refetch: getWebTeamScoreRefetch, loading: getWebTeamScoreLoading } = useQuery(getWebTeamScoreQueryFile, () => ({ matchDayId: parseFloat(selectedMatchDay.value), viewType: selectedViewType.value }), () => ({ fetchPolicy: 'no-cache', pollInterval: selectedRefresh.value == 0 ? 0 : 60000*5,  enabled: selectedMatchDay.value != 0 && selectedViewType.value == 0 } ))

    const { result: getWebSingleScoreResult, refetch: getWebSingleScoreRefetch, loading: getWebSingleScoreLoading } = useQuery(getWebSingleScoreQueryFile, () => ({ matchDayId: parseFloat(selectedMatchDay.value), viewType: selectedViewType.value, roundId: parseInt(selectedSingleRound.value) }), () => ({ fetchPolicy: 'no-cache', pollInterval: selectedRefresh.value == 0 ? 0 : 60000*5, enabled: selectedMatchDay.value != 0 && selectedViewType.value == 1 } ))


    const refreshSelect = [{ id: 0, name: 'Nein' }, { id: 1, name: '5 Minuten', selected: true }]
    const adLinkTop = computed(() => getWebNavigationResult.value?.getWebNavigation.adLinkTop ?? '')
    const scoringMode = computed(() => getWebNavigationResult.value?.getWebNavigation.scoringMode ?? '')
    const leaguesNav = computed(() => getWebNavigationResult.value?.getWebNavigation.leaguesNav ?? '')
    const viewType = computed(() => getWebNavigationResult.value?.getWebNavigation.viewType ?? '')
    const matchDays = computed(() => getWebNavigationResult.value?.getWebNavigation.matchDays ?? '')
    const footerImg = computed(() => getWebNavigationResult.value?.getWebNavigation.footerImg ?? '')
    const logoImg = computed(() => getWebNavigationResult.value?.getWebNavigation.logoImg ?? '')
    const teamScores = computed(() => getWebTeamScoreResult.value?.getWebTeamScore.teams ?? [])
    const singleScoresPlayers = computed(() => getWebSingleScoreResult.value?.getWebSingleScore.players ?? [])
    const singleScoresRounds = computed(() => getWebSingleScoreResult.value?.getWebSingleScore.rounds ?? [])
    const lang = computed(() => getWebNavigationResult.value?.getWebNavigation.lang ?? '')
    const tickerTeam = computed(() => getWebTeamScoreResult.value?.getWebTeamScore.currentTicker ?? '')
    const tickerSingle = computed(() => getWebSingleScoreResult.value?.getWebSingleScore.currentTicker ?? '')
    const currentTicker = computed({
        get() {
          if (tickerTeam.value && tickerTeam.value != '') return tickerTeam.value
          if (tickerSingle.value && tickerSingle.value != '') return tickerSingle.value
          return ''
        },
      })

    watch(viewType, (newVal, oldVal) => {
      // Einzel is in list
      if (newVal.find(f => f.id == 1))
        selectedViewType.value = 1
      else
        selectedViewType.value = 0
    })

    watch(lang, (newVal, oldVal) => {
      changeLanguage(newVal)
    })


    watch(matchDays, (newVal, oldVal) => {
      selectedMatchDay.value =  newVal.find(f => f.selected == true).id
    })
    watch(singleScoresRounds, (newVal, oldVal) => {
      if (newVal && newVal.lenght > 0 && selectedSingleRound.value == 0 || !newVal.find(f => f.id == selectedSingleRound.value)) {
        selectedSingleRound.value =  newVal.find(f => f.selected == true).id
      }
    })

    watch(selectedNav, (newVal, oldVal) => {
      if (oldVal != undefined && newVal != oldVal) {
        isLoaded.value = false
        router.push('/score/' + newVal + '/' + route.params.pid)
        //getWebNavigationRefetch()
      }
    })

    watch(selectedMatchDay, (newVal, oldVal) => {
      isLoaded.value = false
    })
    watch(selectedViewType, (newVal, oldVal) => {
      isLoaded.value = false
    })
    watch(selectedSingleRound, (newVal, oldVal) => {
      const roundIndex = singleScoresRounds.value.find(f => f.id == newVal).roundIndex
      singleScoresRoundsDisplay.value = singleScoresRounds.value.filter(f => f.roundIndex <= roundIndex)
      if (oldVal != 0)
        isLoaded.value = false
    })

    watch(getWebSingleScoreLoading, (newLoading, pervLoading)=> {
      if (!newLoading && loadingSpinner != null) {
          isLoaded.value = true
          loadingSpinner.dismiss()
          loadingSpinner = null
          return
      }
      else if (!isLoaded.value && newLoading) {
        isLoaded.value = true
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!getWebSingleScoreLoading.value && loadingSpinner) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                }
              })
            }).catch(err => { })
      }
    })
    
    watch(getWebTeamScoreLoading, (newLoading, pervLoading)=> {
      if (!newLoading && loadingSpinner != null) {
          isLoaded.value = true
          loadingSpinner.dismiss()
          loadingSpinner = null
          return
      }
      else if (!isLoaded.value && newLoading) {
        isLoaded.value = true
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!getWebTeamScoreLoading.value && loadingSpinner) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                }
              })
            }).catch(err => { })
      }
    })
    
    return {
      adLinkTop, leaguesNav, selectedNav, viewType, selectedViewType, matchDays, selectedMatchDay, selectedRefresh, refreshSelect, getWebSingleScoreRefetch, getWebTeamScoreRefetch, teamScores, singleScoresPlayers, singleScoresRounds, currentTicker, getWebTeamScoreLoading, getWebSingleScoreLoading, isLoaded, selectedSingleRound, footerImg, logoImg, scoringMode, singleScoresRoundsDisplay, lang
    }
  },
  methods: {
    getParDiff(pars, scores, value) {
      let count = 0
      let parDiffCount = 0
      for (const par of pars) {
        if (value != 3 && scores[count] - par  == value)
          parDiffCount++
        if (value == 3 && scores[count] - par  >= value)
          parDiffCount++
        count++
      }
      return parDiffCount
    },
    getScoreClass(par, score) {
      if (score - par == 0)
        return 'par'
      if (score - par == -1)
        return 'birdie'
      if (score - par <= -2)
        return 'eagle'
      if (score - par == 1)
        return 'bogey'
      if (score - par == 2)
        return 'doublebogey'
      if (score - par > 2)
        return 'triplebogey'
    },
    getSign(score) {
      return score == 0 ? '' : score > 0 ? '+' : ''
    },
    getPlusSign(score) {
      if (score == 0)
        return 'Par'
      return score > 0 ? '+' + score : score
    },
    refreshScore() {
      this.isLoaded = false
      if (this.selectedViewType == 0)
        this.getWebTeamScoreRefetch()
      else
        this.getWebSingleScoreRefetch()
    }
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style scoped>

.cf:after {
    content: "";
    display: table;
    clear: both;
}

div.wrap,
div.error {
    padding: 7px;
}

div.wrap.noTop {
    padding-top: 0;
}

div.wrap ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

div.wrap > ul > li {
    padding-right: 4px;
    padding-bottom: 15px;
    width: 25%;
    float: left;
}

div.wrap ul li h2 {
    font: bold 1.5em/1.5 "Open Sans", Arial, Verdana, sans-serif;
    padding-bottom: 3px;
    border-bottom: 1px solid;
    margin: 0;
}

div.wrap > ul > li > ul {
    padding-right: 30px;
    padding-top: 7px;
}

div.wrap > ul > li > ul > li {
    padding: 10px 5px 10px 1em;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
}

div.wrap > ul > li > ul > li > ul {
    padding-left: 20px;
}

div.wrap > ul > li > ul > li > ul {
    margin-top: 5px;
    padding-top: 5px;
    border-top: solid 2px;
    display: none;
}

div.wrap > ul > li > ul > li > ul > li {
    padding: 5px 0;
}

div.wrap ul a {
    text-decoration: none;
    font-weight: normal;
}

div.wrap ul a:hover {
    text-decoration: underline;
}

div.refresh {
    float: right;
    line-height: 1;
    padding-top: 7px;
}

div.refresh select {
    font-size: 12px;
    margin: 0;
    width: 100px;
}

div.refresh a {
    display: inline-block;
    cursor: pointer;
}

div.filter {
    float: left;
    max-width: 400px;
    padding-left: 7px;
}

div.filter a {
    text-decoration: none;
    background: transparent url('~@/assets/images/arrow.gif') no-repeat right center;
    padding-right: 20px;
}

div.filter select {
    display: block;
    width: 100%;
}

ion-content {
    margin: 0;
    padding: 0;
    font: normal 12px/1.3 Arial, Verdana, sans-serif !important;
}

select {
    margin-top: 7px;
    font-size: 1.2em;
    font-weight: 600;
    height: 30px;
    max-width: 100%;
    padding: 4px 6px;
    border: 1px solid;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-bottom: -4px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}

div.controls {
    margin: 0 0 20px 0;
}

div.head {
    font-size: 19px;
    font-weight: bold;
    border-bottom: 1px solid;
}

div.head span {
    display: inline-block;
}

div.head span.rank {
    width: 50px;
    padding-left: 5px;
}

div.head span.score {
    width: 70px;
    float: right;
    text-align: center;
}

.ticker-text {
    height: 150%;
    font-size: 25px;
    color: red;
}

div.roundInfo {
    padding: 16px 0 5px 0;
    font-size: 18px;
}

table.ranking span,
table.singleRanking span {
    text-decoration: none;
    cursor: pointer;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking,
table.singleRanking {
    width: 100%;
    table-layout: auto;
    margin-bottom: 30px;
}

table.ranking tr,
table.singleRanking tr {
    height: 30px;
}

table.ranking > thead > tr > th,
table.singleRanking > thead > tr > th {
    padding: 3px 5px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    width: 75px;
}

table.ranking > thead > tr > th:nth-child(1),
table.ranking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(3),
table.singleRanking > tbody > tr > td:nth-child(2),
table.singleRanking > tbody > tr > td:nth-child(3) {
    width: auto;
    text-align: left !important;
}

table.ranking > thead > tr > th:first-child,
table.ranking > tbody > tr > td:first-child,
table.singleRanking > thead > tr > th:first-child,
table.singleRanking > tbody > tr > td:first-child {
    border-left: none;
}

table.ranking > thead > tr > th:last-child,
table.ranking > tbody > tr > td:last-child,
table.singleRanking > thead > tr > th:last-child,
table.singleRanking > tbody > tr > td:last-child {
    border-right: none;
}

table.ranking > tbody > tr > td,
table.singleRanking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.ranking.matchplay > tbody > tr > td {
    text-align: left;
}

table.ranking > tbody > tr > td:nth-child(1),
table.ranking > tbody > tr > td:nth-child(2) {
    text-align: left;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking > tbody > tr.teamEnd > td,
table.singleRanking > tbody > tr.teamEnd > td {
    border-bottom: solid 3px;
}

table.ranking tr.playerScore,
table.singleRanking tr.playerScore {
    display: table-row;
}

table.ranking tr.playerScore > td,
table.singleRanking tr.playerScore > td {
    padding: 5px 3px;
}

table.ranking a,
table.singleRanking a {
    text-decoration: none;
}

table.ranking a:hover,
table.singleRanking a:hover {
    text-decoration: underline;
}

table.ranking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.playerScore {
    width: 100%;
}

table.playerScore thead tr th {
    font-weight: normal;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > tbody > tr > td {
    border: solid 1px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > thead > tr > th:first-child,
table.playerScore > tbody > tr > td:first-child {
    border-left: none;
}

table.playerScore > thead > tr > th:last-child,
table.playerScore > tbody > tr > td:last-child {
    border-right: none;
}

table.playerScore td.small,
table.playerScore th.small {
    width: 32px;
}

table.playerScore tr :nth-child(2) {
    width: 40px;
}

table.playerScore tr :nth-child(21) {
    width: 58px;
}

table.playerScore tbody tr td {
}

table.playerScore tbody tr td:first-child {
    font-weight: normal !important;
    text-align: right;
}

table.playerScore tbody tr:first-child td {
    font-weight: bold;
}

table.playerScore.matchplay tr td {
    width: 50px;
}

table.playerScore.matchplay tr td:first-child {
    width: auto;
}

table.playerScore.matchplay tr.as td {
}

.eagle {
    background: #ff9050 !important;
}

.birdie {
    background: #ff7070 !important;
}

.par {
    background: lightgray !important;
}

.bogey {
    background: #80bb80 !important;
}

.doublebogey {
    background: #a0a0cc !important;
}

.triplebogey {
    background: #60608c !important;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-bottom: -4px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}
</style>