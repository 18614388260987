import { createApp, reactive, provide, h } from 'vue'
import App from './App.vue'
import router from './router';
import { ApolloClient, InMemoryCache, gql, from } from '@apollo/client';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { Observable } from 'apollo-link';
import jwt_decode from "jwt-decode";
import { IonicVue, isPlatform } from '@ionic/vue';
import CountryFlag from 'vue-country-flag-next'
//import VCalendar from 'v-calendar';
import * as IonComponents from '@ionic/vue';
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import VueObserveVisibility from 'vue-observe-visibility'
import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { createStore } from 'vuex'
import braintree from './braintree'

import { ApolloLink, concat } from 'apollo-link';
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { RetryLink } from 'apollo-link-retry'
import { setContext } from '@apollo/client/link/context';
import { toastController } from '@ionic/vue';
import translateGlobalProperties from './plugins/translateGlobalProperties'
import translate from './plugins/translate'
//import { Storage } from '@capacitor/storage';
import Storage from "./plugins/storage";
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import VueApexCharts from "vue3-apexcharts";
import vueBraintree from 'vue-braintree'
import VueCountdown from '@chenfengyuan/vue-countdown'
import i18next from 'i18next';
import vueTouchEvents from "vue3-touch-events"

const en = require('./i18nData/en');
const de = require('./i18nData/de');
const it = require('./i18nData/it');
const fr = require('./i18nData/fr');
const cz = require('./i18nData/cz');
const nl = require('./i18nData/nl');
const es = require('./i18nData/es');
const cn = require('./i18nData/cn');

i18next.init({
  lng: 'de',
  debug: false,
  resources: {
    en,
    de,
    it,
    fr,
    cz,
    nl,
    es,
    cn
  }
})

Storage.getItem('language').then(res => {
  const lang = res ? res : 'de'
  i18next.changeLanguage(lang)
})


const diplayErrorToast = (error) => {
  toastController
  .create({
    message: error,
    duration: 5000,
    color: 'danger'
  }).then(res => res.present())

}
export const store = createStore({
  state () {
    return {
      selectedOrganization: '',
      token:  '',
      userdata: '',
      language: 'de',
      selectedClub: null,
      selectedPro: null,
      selectedIndex: 0,
      clubs: null,
      httpLink: 'https://backend-cio-online-frontend-mobile.smrtp.net'
    }
  },
  modules: {
    braintree,
  },
  actions: {
    logout({commit}){
      return new Promise(async (resolve, reject) => {
        commit('logout')
        Storage.removeItem('accessToken')
        Storage.removeItem('refreshToken')
        resolve()
      })
    },
    login({commit}){
      return new Promise((resolve, reject) => {
        commit('login')
        resolve()
      })
    },
    setToken({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setToken', value)
        resolve()
      })
    },
    setUserdata({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserdata', value)
        resolve()
      })
    },
    setLanguage({commit}){
      return new Promise((resolve, reject) => {
        commit('setLanguage')
        resolve()
      })
    },
    setHttpLink({commit}){
      return new Promise((resolve, reject) => {
        commit('setHttpLink')
        resolve()
      })
    },
    setClubs({commit}){
      return new Promise((resolve, reject) => {
        commit('setClubs')
        resolve()
      })
    },
    getClubs({commit}){
      return new Promise((resolve, reject) => {
        commit('getClubs')
        resolve()
      })
    },
    getSelectedClub({commit}){
      return new Promise((resolve, reject) => {
        commit('getSelectedClub')
        resolve()
      })
    },
    setSelectedClub({commit}, club){
      return new Promise((resolve, reject) => {
        commit('setSelectedClub', club)
        resolve()
      })
    },
    getSelectedPro({commit}){
      return new Promise((resolve, reject) => {
        commit('getSelectedPro')
        resolve()
      })
    },
    setSelectedPro({commit}, pro){
      return new Promise((resolve, reject) => {
        commit('setSelectedPro', pro)
        resolve()
      })
    },
    getSelectedIndex({commit}){
      return new Promise((resolve, reject) => {
        commit('getSelectedIndex')
        resolve()
      })
    },
    setSelectedIndex({commit}, index){
      return new Promise((resolve, reject) => {
        commit('setSelectedIndex', index)
        resolve()
      })
    },
    setAccessControl({commit}, ac){
      return new Promise((resolve, reject) => {
        commit('setAccessControl', ac)
        resolve()
      })
    },
    setUserDataImageUrl({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataImageUrl', value)
        resolve()
      })
    },
    setUserHCP({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserHCP', value)
        resolve()
      })
    },
    setUserHCPDate({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserHCPDate', value)
        resolve()
      })
    },
    setUserDataShowAvatar({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataShowAvatar', value)
        resolve()
      })
    },
    setUserDataDoRecharge({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataDoRecharge', value)
        resolve()
      })
    },
    setUserDataRechargeLimit({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataRechargeLimit', value)
        resolve()
      })
    },
    setUserDataRechargeAmount({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataRechargeAmount', value)
        resolve()
      })
    },
    setUserDataShowName({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataShowName', value)
        resolve()
      })
    },
    setUserDataShowHCP({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataShowHCP', value)
        resolve()
      })
    },
    setUserDataShowGender({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataShowGender', value)
        resolve()
      })
    },
    deleteUserDataImageUrl({commit}){
      return new Promise((resolve, reject) => {
        commit('deleteUserDataImageUrl')
        resolve()
      })
    },
    setUserDataFriendBatch({commit}, value){
      return new Promise((resolve, reject) => {
        commit('setUserDataFriendBatch', value)
        resolve()
      })
    },
    refreshToken({commit}){
      return new Promise((resolve, reject) => {
        commit('refreshToken')
        resolve()
      })
    },
  },
  mutations: {
    async logout(state){
      state.token = ''
      state.userdata = null
      //store.dispatch('token')
    },
    async login(state){
      state.token = await Storage.getItem('accessToken') || ''
      if (state.token != "") {
        state.userdata = jwt_decode(state.token)
//        store.dispatch('token')
      }
    },
    setToken(state, value){
      state.token = value
    },
    setUserdata(state, value){
      state.userdata = value
    },
    async setLanguage(state){
      state.language = await Storage.getItem('language') || 'de'
    },
    async setHttpLink(state){
      if (isPlatform('capacitor'))
        state.httpLink = await Storage.getItem('httpLink') || "https://backend-cio-online-frontend-mobile.smrtp.net"
      else if (window.location.hostname == "localhost")
        state.httpLink = "http://localhost:4000"
      else
        state.httpLink = await Storage.getItem('httpLink') || (window.location.protocol + '//backend-' + window.location.hostname)
    },
    setClubs(state){
      Storage.setItem('clubs', state.clubs)
    },
    async getClubs(state){
      state.clubs = await Storage.getItem('clubs') || null
    },
    async getSelectedClub(state){
      await Storage.getItem('selectedClub')
        .then(res => {
          state.selectedClub = res
          return
        })
    },
    setSelectedClub(state, club){
      state.selectedClub = club
      Storage.setItem('selectedClub', club)
    },
    async getSelectedPro(state){
      await Storage.getItem('selectedPro')
        .then(res => {
          state.selectedPro = JSON.parse(res)
          return
        })
    },
    setSelectedPro(state, pro){
      state.selectedPro = pro
      Storage.setItem('selectedPro', pro)
    },
    async getSelectedIndex(state){
      await Storage.getItem('selectedIndex')
        .then(res => {
          state.selectedIndex = res
          return
        })
    },
    setSelectedIndex(state, index){
      state.selectedIndex = index
      Storage.setItem('selectedIndex', index)
    },
    setAccessControl(state, ac){
      state.ac = ac
    },
    setUserDataImageUrl(state, value){
      state.userdata.imageUrl = value
    },
    setUserHCP(state, value){
      state.userdata.hcp = value
    },
    setUserHCPDate(state, value){
      state.userdata.hcpLastSync = value
    },
    setUserDataShowAvatar(state, value){
      state.userdata.showAvatar = value
    },
    setUserDataDoRecharge(state, value){
      state.userdata.doRecharge = value
    },
    setUserDataRechargeLimit(state, value){
      state.userdata.rechargeLimit = value
    },
    setUserDataRechargeAmount(state, value){
      state.userdata.rechargeAmount = value
    },
    setUserDataShowName(state, value){
      state.userdata.showName = value
    },
    setUserDataShowHCP(state, value){
      state.userdata.showHCP = value
    },
    setUserDataShowGender(state, value){
      state.userdata.showGender = value
    },
    setUserDataFriendBatch(state, value) {
      state.userdata.friendBatchNum = value
    },
    deleteUserDataImageUrl(state){
      state.userdata.imageUrl = null
    },
    async refreshToken(state){
      await getNewToken()
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    UserData: state => state.userdata,
    language: state => state.language,
    clubs: state => state.clubs,
    selectedClub: state => state.selectedClub,
    selectedPro: state => state.selectedPro,
    selectedIndex: state => state.selectedIndex,
    ac: state => state.ac,
    token: state => {
      return state.token;
    },
    httpLink: state => state.httpLink
  }
})

//store.setToken()
store.dispatch('login')
store.dispatch('setLanguage')
store.dispatch('getClubs')
store.dispatch('getSelectedClub')
store.dispatch('getSelectedPro')
store.dispatch('getSelectedIndex')
store.dispatch('setHttpLink')

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  //console.log("🚀 ~ file: main.js:420 ~ errorLink ~ graphQLErrors:", graphQLErrors[0])
  if (graphQLErrors && graphQLErrors[0].message === 'Unauthenticated') {
    return new Observable(observer => {
        getNewToken()
          .then(newAccessToken => {  
            store.token = newAccessToken
            store.userdata = jwt_decode(newAccessToken)
            //store.dispatch('setToken', newAccessToken)
            //store.dispatch('setUserdata', jwt_decode(newAccessToken))
            operation.setContext(({ headers = {} }) => ({
              headers: {
                // Re-add old headers
                ...headers,
                // Switch out old access token for new one
                authorization: `Bearer ${newAccessToken}`,
              }
            }));

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };

            return forward(operation).subscribe(subscriber);
          })
          .catch(error => {
            //console.log("🚀 ~ file: main.js:447 ~ errorLink ~ error:", error)
            // No refresh or client token available, we force user to login
            observer.error(error);
          });
      });

  } else if (graphQLErrors) {
      graphQLErrors.forEach(async error => {
        if (error.extensions.code == 'df6b0154') {
          await store.dispatch('logout')
          router.push('/home')
        }
        if (error.extensions.code != translate(error.extensions.code))
            diplayErrorToast(translate(error.extensions.code) + (error.extensions.exception && error.extensions.exception.code ? ' (' + error.extensions.exception.code + ')' : ''))
      })
    }
  }
)

const retry = new RetryLink(
  { 
    attempts : { max : Infinity },
    delay: { initial: 30000, max: 30000, jitter: false }, 
  }) 
const httpLink = new HttpLink({
  uri:  (() => store.getters['httpLink'])
  //uri:  'http://localhost:4000'
})

const defaultOptions = { 
  watchQuery: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  },
  query: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  },
  mutate: {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  }
}

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = store.getters.token;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});


const links = ApolloLink.from([
  authLink,
  errorLink,
  concat(retry, httpLink),
]);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: links,
  defaultOptions,
  fetchOptions: {
    mode: 'no-cors',
  },
})

//const test = { id: '5fc8f04fad915f9ded0839b9', name: 'Leons Test Org.'}
//store.clubs = JSON.stringify(test)
//store.dispatch('setClubs')
    
export default store

Storage.getItem('accessToken').then(res => {
  store.token = res ? res : ''
  Storage.getItem('language').then(res => {
    const lang = res ? res : 'de'
    i18next.changeLanguage(lang)
    const app = createApp(
      {
        setup () {
          provide(DefaultApolloClient, apolloClient)
        },
        render() {
          return h(App)
        }
      }
    ).use(translateGlobalProperties)
    .use(IonicVue)
    .use(router)
    .use(store)
    .use(VueApexCharts)
    .use(vueBraintree)
    .use(vueTouchEvents)
    .use(VueObserveVisibility)

    app.use(SetupCalendar, {})

    app.component(VueCountdown.name, VueCountdown)
    app.component(CountryFlag.name, CountryFlag)
    app.component('DatePicker', DatePicker)
    app.component('Calendar', Calendar)
    app.component('EasyDataTable', Vue3EasyDataTable)
    //app.use(VCalendar, {})
    
    // Import all Ion Components at once
    Object.keys(IonComponents).forEach(key => {
      if (/^Ion[A-Z]\w+$/.test(key)) {
          app.component(key, IonComponents[key]);
      }
    });
  
    router.isReady().then(() => {
      app.mount('#app');
      defineCustomElements(window)
    });
  })
})


const mutation = gql`
mutation refreshAccessToken($refreshToken: String!) {
    refreshAccessToken(input: {
        refreshToken: $refreshToken
    }) {
        accessToken
    }
}`

const getNewToken = async () => {
  const refreshToken = await Storage.getItem('refreshToken')
  const newAccessToken = await apolloClient.mutate({
    mutation,
    variables: { refreshToken: refreshToken }
  })
  .then (async res => {
    const { accessToken } = res.data.refreshAccessToken;
    Storage.setItem('accessToken', accessToken)
    
    store.dispatch('setToken', accessToken)
    store.dispatch('setUserdata', jwt_decode(accessToken))
    return accessToken;
  })
  .catch(err => {
    console.error(err)
  })
  return newAccessToken
};


