<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('helpTournament') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
          <ion-icon color="primary" :md="timeOutline" :ios="timeOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentTime") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="primary" :md="chevronDownOutline" :ios="chevronDownOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentDown") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="primary" :md="chevronUpOutline" :ios="chevronUpOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentUp") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="success" :md="downloadOutline" :ios="downloadOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentEnter") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="danger" :md="downloadOutline" :ios="downloadOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentEnterWaitlist") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="danger" :md="exitOutline" :ios="exitOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentUnregister") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="female" :md="womanSharp" :ios="womanSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentGenderFemale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="male" :md="manSharp" :ios="manSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentGenderMale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="primary" :md="documentTextOutline" :ios="documentTextOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentAnnouncment") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="primary" :md="listOutline" :ios="listOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentML") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="primary" :md="listCircleOutline" :ios="listCircleOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentSL") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="primary" :md="medalOutline" :ios="medalOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("helpTournamentEL") }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { arrowForwardOutline, arrowBackOutline, todayOutline, helpCircleOutline,refreshOutline, chevronDownOutline,
         chevronUpOutline, listOutline, medalOutline, documentTextOutline, timeOutline,
         printOutline, manSharp, womanSharp, listCircleOutline, closeOutline, downloadOutline, exitOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'HelpBookingTeetimeDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider },
  setup() {
    return {
      arrowForwardOutline, arrowBackOutline, todayOutline, helpCircleOutline,refreshOutline, chevronDownOutline,
         chevronUpOutline, listOutline, medalOutline, documentTextOutline, timeOutline,
         printOutline, manSharp, womanSharp, listCircleOutline, closeOutline, downloadOutline, exitOutline
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>