<template>
  <ion-page>
      <ion-content :fullscreen="true">
        <ion-grid class="ion-no-padding ion-no-margin">
          <ion-row>
            <ion-col :size="teetimesFound1 ? 6 : 12">
              <div :style="'margin-left: ' + (teetimesFound1 ? '1': '10') + '%;margin-right: ' + (teetimesFound1 ? '1': '10') + '%; margin-bottom: 20px;'">
                <ion-item  v-if="courseName">
                  <ion-img style="width: 60px; height:60px; margin-right: 10px;" :src="logo" />
                  <ion-label :style="'color: #' + courseColor + ';'" :class="fontName && fontName != '' ? fontName + ' course' : 'course'">{{ courseName }}</ion-label>
                  <ion-label :class="fontName && fontName != '' ? fontName + ' course' : 'course'" slot="end" :style="'color: #' + courseColor + ';'">{{ dayjs().format('DD.MM.YYYY') }}</ion-label>
                </ion-item>
                <ion-item  v-if="comment && comment != ''" :class="fontName && fontName != '' ? fontName : 'course'">
                    <span v-html="comment" class="comment ion-no-padding ion-no-margin" style="margin-left: 0px; white-space: pre-wrap;" ></span>
                </ion-item>
                <ion-item v-if="cartsAllowed != undefined">
                  <ion-icon style="zoom: 1.4; margin-right: 10px;" :color="cartsAllowed ? 'success' : 'danger'" :md="carOutline" :ios="carOutline"></ion-icon>
                  <ion-label :style="cartsAllowed ? 'color: green;' : 'color: red;'" :class="fontName && fontName != '' ? fontName + ' comment ion-text-wrap' : 'comment ion-text-wrap'">{{ cartsAllowed ? 'Carts erlaubt' : 'Carts verboten' }}</ion-label>
                </ion-item>
                <ion-card :style="'background-color: #' + courseColor + ''">
                  <ion-item style="margin: 3px;">
                    <ion-grid>
                      <ion-row v-for="(teetime, index) in teetimesFound" :key="index">
                        <ion-col :class="fontName && fontName != '' ? fontName + ' time rows' : 'time rows'">{{ dayjs(teetime.time).format('HH:mm') }}</ion-col>
                        <ion-col  v-for="(player, index) in teetime.players" :key="index" class="rows">
                          <ion-card :color="teetime.lock ? 'warning' : (player.name == '' ? 'success' : 'primary')" :class="fontName && fontName != '' ? fontName + ' playercard ion-no-padding' : 'playercard ion-no-padding'">
                            <ion-card-content class="ion-no-margin ion-no-padding player">{{ player.name }}<br>{{ player.club }}<br>&nbsp;</ion-card-content>
                          </ion-card>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
                </ion-card>
                </div>
              </ion-col>
              <ion-col v-if="teetimesFound1" size="6">
              <div style="margin-left: 1%;margin-right: 1%; margin-bottom: 20px;">
                <ion-item  v-if="courseName1">
                  <ion-img style="width: 60px; height:60px; margin-right: 10px;" :src="logo" />
                  <ion-label :style="'color: #' + courseColor1 + ';'" :class="fontName && fontName != '' ? fontName + ' course' : 'course'">{{ courseName1 }}</ion-label>
                  <ion-label :class="fontName && fontName != '' ? fontName + ' course' : 'course'" slot="end" :style="'color: #' + courseColor1 + ';'">{{ dayjs().format('DD.MM.YYYY') }}</ion-label>
                </ion-item>
                <ion-item  v-if="comment1 && comment1 != ''" :class="fontName && fontName != '' ? fontName : 'course'">
                    <span v-html="comment1" class="comment ion-no-padding ion-no-margin" style="margin-left: 0px; white-space: pre-wrap;" ></span>
                </ion-item>
                <ion-item v-if="cartsAllowed1 != undefined">
                  <ion-icon style="zoom: 1.4; margin-right: 10px;" :color="cartsAllowed ? 'success' : 'danger'" :md="carOutline" :ios="carOutline"></ion-icon>
                  <ion-label :style="cartsAllowed1 ? 'color: green;' : 'color: red;'" :class="fontName && fontName != '' ? fontName + ' comment ion-text-wrap' : 'comment ion-text-wrap'">{{ cartsAllowed1 ? 'Carts erlaubt' : 'Carts verboten' }}</ion-label>
                </ion-item>
                <ion-card :style="'background-color: #' + courseColor1 + ''">
                  <ion-item style="margin: 3px;">
                    <ion-grid>
                      <ion-row v-for="(teetime, index) in teetimesFound1" :key="index">
                        <ion-col :class="fontName && fontName != '' ? fontName + ' time rows' : 'time rows'">{{ dayjs(teetime.time).format('HH:mm') }}</ion-col>
                        <ion-col  v-for="(player, index) in teetime.players" :key="index" class="rows">
                          <ion-card :color="teetime.lock ? 'warning' : (player.name == '' ? 'success' : 'primary')" :class="fontName && fontName != '' ? fontName + ' playercard ion-no-padding' : 'playercard ion-no-padding'">
                            <ion-card-content class="ion-no-margin ion-no-padding player">{{ player.name }}<br>{{ player.club }}<br>&nbsp;</ion-card-content>
                          </ion-card>
                        </ion-col>
                      </ion-row>
                    </ion-grid>
                  </ion-item>
                </ion-card>
                </div>
              </ion-col>
            </ion-row>
        </ion-grid>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,IonImg,
IonInput, IonItem, IonList } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import findTeetimesQueryFile from '../graphql/findTeetimes.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import { timeOutline, carOutline } from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput,
    IonHeader, IonItem, IonList,
    IonPage, IonImg,
    IonTitle, IonIcon,
    IonToolbar, IonGrid, IonRow, IonCol,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const { result: findTeetimesResult } = useQuery(findTeetimesQueryFile, () => ({ id: parseInt(route.params.id), type: route.query.type ? route.query.type.toString() : '' }), () => ({ enabled: route.params.id != undefined, fetchPolicy: 'no-cache', pollInterval: 21000 }))
    const teetimesFound = useResult(findTeetimesResult, null, data => data.findTeetimes.teetimes)
    const courseName = useResult(findTeetimesResult, null, data => data.findTeetimes.coursename)
    const courseColor = useResult(findTeetimesResult, null, data => data.findTeetimes.coursecolor)
    const logo = useResult(findTeetimesResult, null, data => data.findTeetimes.logo)
    const idToUseInfoscreen = useResult(findTeetimesResult, null, data => data.findTeetimes.id)
    const type = useResult(findTeetimesResult, null, data => data.findTeetimes.type)
    const random = useResult(findTeetimesResult, null, data => data.findTeetimes.random)
    const comment = useResult(findTeetimesResult, null, data => data.findTeetimes.comment)
    const cartsAllowed = useResult(findTeetimesResult, null, data => data.findTeetimes.cartsAllowed)
    const fontName = useResult(findTeetimesResult, null, data => data.findTeetimes.fontName)

    const teetimesFound1 = useResult(findTeetimesResult, null, data => data.findTeetimes.teetimes1)
    const courseName1 = useResult(findTeetimesResult, null, data => data.findTeetimes.coursename1)
    const courseColor1 = useResult(findTeetimesResult, null, data => data.findTeetimes.coursecolor1)
    const logo1 = useResult(findTeetimesResult, null, data => data.findTeetimes.logo1)
    const comment1 = useResult(findTeetimesResult, null, data => data.findTeetimes.comment1)
    const cartsAllowed1 = useResult(findTeetimesResult, null, data => data.findTeetimes.cartsAllowed1)

    watch(random, (newVal, prevVal) => {
      if (!route.path.startsWith('/infoscreen'))
        return
      if (idToUseInfoscreen.value == -1) {
        setTimeout(() => { router.push(('/nextcompetitionsscreen')) }, 20000)
      }
      else if (idToUseInfoscreen.value == 0) {
        setTimeout(() => { router.push(('/startlistscreen')) }, 20000)
      }
      else if (idToUseInfoscreen.value != route.params.id) {
          setTimeout(() => { router.push('/infoscreen/' + idToUseInfoscreen.value + (type.value == ''? '': '?type=' + type.value) ) }, 20000)
      }
    })

    return {
      timeOutline, teetimesFound, dayjs, courseName, courseColor, logo, comment, cartsAllowed, carOutline, fontName, teetimesFound1, courseName1, courseColor1, logo1, comment1, cartsAllowed1
    }
  },
});
</script>

<style scoped>

.card {
  background-color: rgb(75, 59, 219); 
}

.playercard {
   height: auto;
}

.player {
   padding: 5px !important;
}

.minion { 
  font-family: "Minion, Arial" !important;
}

ion-col.rows {
   margin: 0px;
   padding: 0px;
   font-weight: bold;
   border-bottom: 1px solid grey;
   vertical-align: center;
}

ion-col.header {
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    vertical-align: center;
  }

ion-col.time {
    font-size: 24px; 
    font-weight: bold;
  }

.course {
    font-size: 24px; 
    font-weight: bold;
  }

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
.comment {
    font-size: 16px; 
    font-weight: bold;
  }

</style>