<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Wellness</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <h3 style="margin-left: 15px;" v-if="wellnesses && wellnesses.length <= 0">{{ $t('noWellnesses') }}</h3>
      <ion-card v-for="(wellness, index) in wellnesses" :key="index">
        <ion-card-header>
          <ion-item :color="getPercentageColor(wellness.percentageBooked, wellness.numSlots)" lines="full" class="ion-no-padding" @click="$router.push( { name: 'wellnesse_date', params: {id: wellness.id, date: dayjs().tz().format('YYYY-MM-DD') }} )">
            <ion-icon style="zoom: 1.0" slot="start" color="" :md="reorderTwoOutline" :ios="reorderTwoOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap clickable" color="">{{ wellness.name }} ({{ $t('today') }} {{ wellness.bookedSlots }} {{ $t('of') }} {{ wellness.numSlots }} {{ $t('booked') }})</ion-label>
          </ion-item>
        </ion-card-header>
        <ion-item lines="full">
          <ion-label class="ion-text-wrap" slot="start">{{ $t('nextFreeSlot') }}: {{ wellness.nextFreeSlot }}</ion-label>
        </ion-item>
        <ion-item lines="full">
          <ion-grid>
            <ion-row>
              <ion-col style="text-align: left;font-size: 14px;" :size="index == 5 ? 6 : 1" :size-lg="index == 5 ? 6 : 1" :size-sm="index == 5 ? 2 : 2" :size-xs="index == 5 ? 2 : 2" v-for="(col, index) in wellness.nextSlots" :key="index" router-direction="root" :router-link="'/wellnesse/' + wellness.id + '/' + dayjs(col.date).tz().format('YYYY-MM-DD')">
                <ion-label>{{ dayjs(col.date).tz().format('DD.MM.') }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col style="text-align: left;" :size="index == 5 ? 6 : 1" :size-lg="index == 5 ? 6 : 1" :size-sm="index == 5 ? 2 : 2" :size-xs="index == 5 ? 2 : 2" v-for="(col, index) in wellness.nextSlots" :key="index" router-direction="root" :router-link="'/wellnesse/' + wellness.id + '/' + dayjs(col.date).tz().format('YYYY-MM-DD')">
                <ion-icon style="zoom: 1.0" :color="index == 0 ? getPercentageColor(wellness.percentageBooked, wellness.numSlots) : getPercentageColor(col.percentageBooked)" :md="calendarClearSharp" :ios="calendarClearSharp"></ion-icon>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController } from '@ionic/vue';
import { defineComponent, watch } from 'vue';
import { personOutline, calendarClearSharp, reorderTwoOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getWellnessesQueryFile from '../graphql/getWellnesses.query.gql'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')

export default defineComponent({
  name: 'Wellnesses',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null

    const { result: getWellnessesResult, loading: getWellnessesLoading } = useQuery(getWellnessesQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const wellnesses = useResult(getWellnessesResult, null, data => data.getWellnesses.wellnesses)

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getWellnessesLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getWellnessesLoading, (getWellnessesLoading, prevLoading) => {
      if (!getWellnessesLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getWellnessesLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getWellnessesLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      wellnesses, personOutline, calendarClearSharp, dayjs, reorderTwoOutline
    }
  },
  methods: {
    getPercentageColor (percentage, numSlots) {
      let color = "successlight"
      if (numSlots == 0)
        color = "danger"
      else if (percentage == 0)
        color = "success"
      else if (percentage > 0 && percentage <= 0.25)
        color = "success"
      else if (percentage > 0.25 && percentage <= 0.5)
        color = "warninglight"
      else if (percentage > 0.5 && percentage <= 0.75)
        color = "warning"
      else if (percentage > 0.75 && percentage < 1)
        color = "warning"
      else if (percentage == 1)
        color = "danger"

      return color
    },
  }
});
</script>

<style scoped>

.clickable {
    cursor: pointer;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>