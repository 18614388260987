<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('aboHistory') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="selectedAbo">
          {{ selectedAbo.value }}
        </ion-card-title>
      </ion-card-header>
      <ion-label v-if="!aboHistory || aboHistory.length <= 0" style="margin-left: 15px;" class="ion-text-wrap">{{ $t('noHistory') }}</ion-label>
      <ion-list lines="full" class="ion-no-margin">
        <ion-item v-for="(item, index) in aboHistory" :key="index" :value="item">
            <ion-label class="ion-text-wrap">{{ item.date }}: {{ item.text }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getAboHistoryQueryFile from '../../graphql/getAboHistory.query.gql'
import * as dayjs from 'dayjs'; 

export default defineComponent({
  name: 'ShowEntryListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonAvatar,
              },
  props: ['propsData'],
  setup(props) {
    let selectedAbo = ref(props.propsData.selectedAbo)
    const { result: getAboHistoryResult } = useQuery(getAboHistoryQueryFile, () => ({ id: selectedAbo.value.id }), { fetchPolicy: 'no-cache' })
    let aboHistory = useResult(getAboHistoryResult, null, data => data.getAboHistory.history)

    return { dayjs, closeOutline, selectedAbo, aboHistory, isPlatform
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>