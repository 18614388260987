<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('bookingTeetimeHelp') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
          <ion-icon color="member" :md="womanSharp" :ios="womanSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("memberFemale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="member" :md="manSharp" :ios="manSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("memberMale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="guest" :md="womanSharp" :ios="womanSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("guestFemale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon color="guest" :md="manSharp" :ios="manSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("guestMale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="squareSharp" color="success" :ios="squareSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("myBooking") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="squareSharp" color="warning" :ios="squareSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("teetimeLocked") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="pawSharp" :ios="pawSharp" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("withDog") }}</ion-label>
      </ion-item>
      <ion-item>
          <svg width="25" height="25">
            <circle cx="12" cy="12" r="10" fill="#eb445a" />
            <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="12px" font-family="Arial" dy=".3em">9</text>
          </svg>          
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("with9Hole") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="heartOutline" color="member" :ios="heartOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("withPlayWithMe") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="downloadOutline" color="success" :ios="downloadOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("makeBooking") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="colorWandOutline" color="primary" :ios="colorWandOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("editBooking") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="calendarNumberOutline" color="warning" :ios="calendarNumberOutline" style="zoom: 1.0"></ion-icon>
          <ion-label  class="ion-text-wrap">&nbsp;{{ $t("sunsetBooking") }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, heartOutline, calendarNumberOutline,
          personAddOutline, peopleOutline, openOutline, manSharp, womanSharp, downloadOutline, colorWandOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'HelpBookingTeetimeDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider },
  setup() {
    return {
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline,
       peopleOutline, openOutline, manSharp, womanSharp, downloadOutline, heartOutline, colorWandOutline, calendarNumberOutline
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>