<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('registerTournament') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title v-if="course">
          {{ course.name }}
        </ion-card-title>
        <ion-card-title v-if="course">
          {{ dayjs(course.begin).format('DD.MM.YYYY HH:mm') }}
        </ion-card-title>
      </ion-card-header>
      <ion-list  v-show="course" lines="full" class="ion-no-margin">
        <ion-item v-if="UserData">
          <ion-label class="ion-text-wrap" style="margin-left: 5px">
            <h2>{{ UserData.title }} {{ UserData.lastName }} {{ UserData.firstName }} ({{ UserData.hcp > 54 ? '--': UserData.hcp }})</h2>
          </ion-label>
          <ion-avatar @contextmenu.prevent="" slot="end" v-if="UserData.imageUrl">
            <img :src="UserData.imageUrl">
          </ion-avatar>
        </ion-item>
        <ion-item>
          <ion-icon slot="start" color="primary" :md="documentTextOutline" :ios="documentTextOutline" style="zoom: 1.0"></ion-icon>
          <ion-input :placeholder="$t('comment')" v-model="selectedComment"></ion-input>
        </ion-item>
        <ion-item v-if="course && course.payOnline && course.priceFloat > 0">
          <h3>{{ $t('amountToPay') }}: {{ course.price }}</h3>
          <ion-checkbox v-if="course" :disabled="course.forceCoursePayment && course.payCoursefee" v-model="course.payCoursefee" slot="end"></ion-checkbox>
        </ion-item>
        
        <ion-list v-show="course && course.payOnline && course.payCoursefee && course.priceFloat > 0">
          
          <ion-item position="stacked">{{ $t('bookAndPay')}}</ion-item>
          <ion-item v-if="(clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canLastschriftRegisterCourse)">
              <ion-label slot="start">{{ $t('payLastschrift')}}</ion-label>
              <ion-checkbox v-model="lastschriftChecked" slot="end"></ion-checkbox>
            </ion-item>
            
            <ion-item v-if="lastschriftChecked">
              <ion-label class="ion-text-wrap">{{ $t('payLastschriftHint')}}</ion-label>
              <ion-button @click="createCourseRegistrationByLastschrift()" color="success">{{ $t('bookAndPay') }}</ion-button>
            </ion-item>

            
            <ion-item v-show="!lastschriftChecked">
              <div  id="paypalButton"></div>
            </ion-item>
        </ion-list>


        <ion-button v-if="course && (!course.payOnline || !course.payCoursefee)" :disabled="sendDisabled" style="margin-top: 15px;" expand="block" color="primary" @click="doBooking()">{{ course.payOnline && course.payCoursefee ? $t('makeTournamentRegistrationAndPay') : $t('makeTournamentRegistration') }}</ion-button>
      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController,IonCardContent, IonTextarea, IonSearchbar,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonSelect, IonSelectOption,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle,
IonListHeader, IonAvatar, isPlatform, loadingController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline,
         addCircleOutline, trashOutline } from 'ionicons/icons';
import { useMutation } from '@vue/apollo-composable'
import * as dayjs from 'dayjs';
import registerCourseMutationFile from '../../graphql/registerCourse.mutation.gql'
import createPaymentMutationFile from '../../graphql/createPayment.mutation.gql'
import { loadScript } from "@paypal/paypal-js";
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'RegisterCourseDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonSelect, IonSelectOption, IonTextarea, IonSearchbar,
                IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let course = ref()
    let selectedComment = ref('')
    let sendDisabled = ref(false)
    const clubInfo = ref(getClubInfo())
    const lastschriftChecked = ref(false)

    const { mutate: registerCourseMutation } = useMutation(registerCourseMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createPaymentMutation } = useMutation(createPaymentMutationFile, { fetchPolicy: 'no-cache' });

    return {
      registerCourseMutation, sendDisabled,
      dayjs, course, selectedComment, addCircleOutline, trashOutline, isPlatform, createPaymentMutation,
      closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline, clubInfo,
      lastschriftChecked
    }
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      UserData : function(){ return this.$store.getters.UserData},
      paymenttype : function(){ 
        return 'PayPalAccount'
/*        if (this.isSuccessfullBraintreePaypalAuthorized && this.isSuccessfullBraintreeCreditCardAuthorized )
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreePaypalAuthorized)
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreeCreditCardAuthorized)
          return 'CreditCard' */
       },
  },
  mounted() {
    this.course = this.propsData.course
    loadScript({ "client-id": this.clubInfo.clubInfo.paypalClientID, currency: this.clubInfo.clubInfo.currency }).then((paypal) => {
    paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          
        },
        createOrder: async () => {
          const p = await this.createPaymentMutation({ description: 'Kursbuchung ' + this.course.name, amount: parseFloat(this.course.priceFloat), paymentType: 'payPal' })
          if (!p.data.createPayment.success) {
            return ''
          }
          return p.data.createPayment.token
         },
         onError: async (err) => {
            toastController
              .create({
                message: this.$t('invalidPayment'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
         },
         onApprove: async (data,actions) => {
              this.buttonDisabled = true
              loadingController.create().then(res => {
                res.present()
              }).catch(err => { })
              this.registerCourseMutation({ id: parseInt(this.course.id), comment: this.selectedComment, 
                isMobile: isPlatform('mobile'), price: this.course.payCoursefee ? this.course.priceFloat : 0, paymentType: this.paymenttype, language: this.$store.getters.language, orderId: data.orderID })
            .then(res => {
                toastController
                  .create({
                    message: this.$t('registerSuccessful'),
                    duration: 3000,
                    color: 'success'
                  }).then(res => res.present())
                  loadingController.dismiss()
                  return modalController.dismiss(true)
            })
            .catch(err => {
                loadingController.dismiss()
                toastController
                  .create({
                    message: this.$t('registerNotSuccessful') + ': ' + err,
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
            }) 
         },
         onCancel: async () => {
          await this.createPaymentMutation({ amount: parseFloat(this.course.priceFloat), paymentType: 'payPalCancel' })
         }
     }).render("#paypalButton")});
  },
  methods: {
    doBooking() {
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.sendDisabled = true
      this.registerCourseMutation({ id: parseInt(this.course.id), comment: this.selectedComment, 
        isMobile: isPlatform('mobile'), price: this.course.payCoursefee ? this.course.priceFloat : 0, paymentType: this.paymenttype, language: this.$store.getters.language })
      .then(res => {
        modalController.dismiss(true)
        toastController
          .create({
            message: this.$t('registerSuccessful'),
            duration: 2000,
            color: 'success'
          }).then(res => res.present())
          loadingController.dismiss()
          return
      })
      .catch(err => {
        loadingController.dismiss()
        toastController
          .create({
            message: this.$t('registerNotSuccessful'),
            duration: 2000,
            color: 'danger',
          }).then(res => res.present())
      })

    },
    closeDialog() {
      return modalController.dismiss()
    },
    createCourseRegistrationByLastschrift(){      
      this.buttonDisabled = true
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.registerCourseMutation({ id: parseInt(this.course.id), comment: this.selectedComment, 
        isMobile: isPlatform('mobile'), price: this.course.payCoursefee ? this.course.priceFloat : 0, paymentType: 'Lastschrift', language: this.$store.getters.language })
      .then(res => {
          toastController
            .create({
              message: this.$t('registerSuccessful'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
            loadingController.dismiss()
            return modalController.dismiss(true)
      })
      .catch(err => {
          loadingController.dismiss()
          toastController
            .create({
              message: this.$t('registerNotSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
         
    }
  }
});
</script>