<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('friendsHelp') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
          <ion-icon :md="checkmarkOutline" color="success" :ios="checkmarkOutline" style="zoom: 1.0"></ion-icon>
          <ion-label>&nbsp;{{ $t("friendsAccepted") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="hourglassOutline" color="warning" :ios="hourglassOutline" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsNotYetAcceppted") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="thumbsDownOutline" color="danger" :ios="thumbsDownOutline" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsHelpDeclined") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="trashOutline" color="danger" :ios="trashOutline" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsDelete") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="womanSharp" color="female" :ios="womanSharp" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsFemale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="manSharp" color="male" :ios="manSharp" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsMale") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="addCircleOutline" color="success" :ios="addCircleOutline" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsAdd") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="chevronDownOutline" color="primary" :ios="chevronDownOutline" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsOpenDetail") }}</ion-label>
      </ion-item>
      <ion-item>
          <ion-icon :md="chevronUpOutline" color="primary" :ios="chevronUpOutline" style="zoom: 1.0"></ion-icon>
          <ion-label class="ion-text-wrap">&nbsp;{{ $t("friendsCloseDetail") }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, trashOutline, addCircleOutline, chevronDownOutline,
          personAddOutline,  peopleOutline, openOutline, manSharp, womanSharp, downloadOutline, checkmarkOutline, hourglassOutline, chevronUpOutline, thumbsDownOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'HelpBookingTeetimeDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider },
  setup() {
    return {
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline, addCircleOutline,
       peopleOutline, openOutline, manSharp, womanSharp, downloadOutline, checkmarkOutline, hourglassOutline, trashOutline, chevronUpOutline, chevronDownOutline, thumbsDownOutline
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>