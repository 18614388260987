<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title slot="start">{{ $t('addFriendGroup') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">{{ $t('name')}}</ion-label>
        <ion-input type="text" v-model="friendGroupName"></ion-input>
      </ion-item>

      <ion-button :disabled="friendGroupName.trim() == ''" @click="save()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonSelect, IonSelectOption, IonButton, IonContent, 
          IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonToggle,
          IonCol, IonRow, modalController, IonDatetime } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline } from 'ionicons/icons';
import addFriendGroupMutationFile from '../../graphql/addFriendGroup.mutation.gql'
import { useMutation } from '@vue/apollo-composable'

export default defineComponent({
  name: 'EditBookingTypeDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonToggle, IonDatetime },
  props: ['propsData'],
  setup() {
    let friendGroupName = ref('')
    const { mutate: addFriendGroupMutation } = useMutation(addFriendGroupMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline, addFriendGroupMutation, friendGroupName
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
    async save() {
      this.addFriendGroupMutation({ 
        name: this.friendGroupName, 
        })
      .then(res => {
          toastController
            .create({
              message: this.$t('addSuccessful'),
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
            return modalController.dismiss(true)
        })
        .catch(err => {
          toastController
            .create({
              message: this.$t('addNotSuccessful'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    },
  }
});


</script>