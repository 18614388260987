<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title slot="start">{{ $t('addRPR') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  
  <ion-content v-if="selectedHCPI == undefined && syncHCPI" class="ion-padding">
    <ion-card>
      <ion-card-header>{{ $t('syncHCPI') }}</ion-card-header>
    </ion-card>
  </ion-content>

  <ion-content v-if="selectedHCPI == undefined && !syncHCPI" class="ion-padding">
    <ion-card>
      <ion-card-header color="danger">{{ $t('noRPR') }}</ion-card-header>
    </ion-card>
  </ion-content>

  <ion-content v-show="selectedHCPI != undefined" class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-grid v-if="courses && courses.length > 0">
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('coursename')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-select  interface="action-sheet" v-model="selectedCourse">
                  <ion-select-option v-for="(item, index) in courses" :key="index" :value="item">{{ item.name }} ({{ item.numHoles }} {{ $t('holes')  }})</ion-select-option>          
                </ion-select>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('tee')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-select  interface="action-sheet" v-model="selectedTee">
                  <ion-select-option v-for="(item, index) in selectedCourse.tees" :key="index" :value="item">{{ item.name }}  {{ (item.gender == 1 ? $t('gentleman') : $t('ladies')) }}</ion-select-option>          
                </ion-select>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('hcpi')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-label>{{ selectedHCPI }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('marker')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-label v-if="selectedMarker">{{ selectedMarker.nameLong }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-searchbar :placeholder="$t('searchMarker')" v-model="searchText" @ionClear="searchText = ''"></ion-searchbar>
              </ion-col>
            </ion-row>
            <ion-row v-if="searchText != '' && playersFound && playersFound.length > 0">
              <ion-col>
                <ion-list>
                  <ion-list-header>
                    {{ $t('searchresult') }}
                  </ion-list-header>
                  <ion-item class="ion-text-wrap" v-for="(player, i) in playersFound" :key="i" @click="selectedMarker = player;searchText = ''">
                    <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
                    <ion-label class="ion-text-wrap" style="margin-left: 5px">
                      <h2>{{ player.nameLong }}</h2>
                    </ion-label>
                    <ion-avatar @contextmenu.prevent="" slot="end" v-if="player.showAvatar && player.avatarLink != ''">
                      <img :src="player.avatarLink">
                    </ion-avatar>
                  </ion-item>
                </ion-list>
              </ion-col>
            </ion-row>
          </ion-grid>
      </ion-card-header>

      <ion-card-content v-show="priceRPR <= 0 && selectedMarker != undefined && selectedTee != undefined">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button :disabled="addClicked" @click="addRPRRound()" color="success">{{ $t('addRPRRound') }}</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
      
      <ion-card-content v-show="priceRPR > 0 && selectedMarker != undefined && selectedTee != undefined">
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-label class="ion-text-wrap" >{{ $t('amount')}}: </ion-label>
            </ion-col>
            <ion-col>
              <ion-label>{{ priceRPR }} {{ clubInfo.clubInfo.currency }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row v-if="(clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canLastschriftRPR)">
            <ion-col>
              <ion-label>{{ $t('payLastschrift')}}</ion-label>
            </ion-col>
            <ion-col>
              <ion-checkbox v-model="lastschriftChecked"></ion-checkbox>
            </ion-col>
          </ion-row>
          <ion-row v-if="lastschriftChecked">
            <ion-col>
              <ion-label class="ion-text-wrap">{{ $t('payLastschriftHint')}}</ion-label>
            </ion-col>
            <ion-col>
              <ion-button @click="rechargeByLastschrift()" color="success">{{ $t('addRPRLastschrift') }}</ion-button>
            </ion-col>
          </ion-row>
          <ion-row v-show="clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canPaypal && !lastschriftChecked">
            <ion-col>
              <ion-label position="stacked">{{ $t('paymenttype')}}</ion-label>
              <div style="margin-top: 5px;" id="paypalButton"></div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card-content>
    </ion-card>

  </ion-content>
</template>

<script>
import { toastController, IonSelect, IonSelectOption, IonButton, IonContent, 
          IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonToggle,
          IonCol, IonRow, modalController, IonDatetime, loadingController } from '@ionic/vue';
import { defineComponent, ref, computed, watch } from 'vue';
import { closeOutline, manSharp, womanSharp, personOutline } from 'ionicons/icons';
import addRPRMutationFile from '../../graphql/addRPR.mutation.gql'
import payRPRMutationFile from '../../graphql/payRPR.mutation.gql'
import createPaymentMutationFile from '../../graphql/createPayment.mutation.gql'
import findPlayersQueryFile from '../../graphql/findPlayers.query.gql'
import syncHCPMutationFile from '../../graphql/syncHCP.mutation.gql'
import { useMutation, useResult, useQuery } from '@vue/apollo-composable'
import { loadScript } from "@paypal/paypal-js";
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'AddRPRDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption, IonToggle, IonDatetime },
  props: ['propsData'],
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
  },
  mounted() {
    loadScript({ "client-id": this.clubInfo.clubInfo.paypalClientID, currency: this.clubInfo.clubInfo.currency }).then((paypal) => {
    paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          
        },
        createOrder: async () => {
          const p = await this.createPaymentMutation({ description: 'RPR', amount: this.priceRPR, paymentType: 'payPal' })
          if (!p.data.createPayment.success) {
            return ''
          }
          return p.data.createPayment.token
         },
         onError: async () => {
            toastController
              .create({
                message: this.$t('invalidPayment'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
         },
         onApprove: async (data,actions) => {
            this.buttonDisabled = true
            loadingController.create().then(res => {
              res.present()
            }).catch(err => { })
            this.payRPRMutation({ 
              amount:this.priceRPR,
              type: this.paymenttype,
              orderId: data.orderID
              })
            .then(res => {
                this.addRPRRound()
                loadingController.dismiss()
            })
            .catch(err => {
                loadingController.dismiss()
                toastController
                  .create({
                    message: this.$t('addNotSuccessful') + ': ' + err,
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
            }) 
         },
         onCancel: async () => {
          await this.createPaymentMutation({ amount: this.priceRPR, paymentType: 'payPalCancel' })
         }
     }).render("#paypalButton")});

    this.paymenttype = 'PayPalAccount'
    /*if (this.isSuccessfullBraintreePaypalAuthorized && this.isSuccessfullBraintreeCreditCardAuthorized )
      this.paymenttype = 'PayPalAccount'
    else if (this.isSuccessfullBraintreePaypalAuthorized)
      this.paymenttype = 'PayPalAccount'
    else if (this.isSuccessfullBraintreeCreditCardAuthorized)
      this.paymenttype = 'CreditCard' */
  },
  setup(props) {
    const paymenttype = ref('')
    const rechargeDisabled = ref(false)
    const clubInfo = ref(getClubInfo())
    const lastschriftChecked = ref(false)
    let selectedCourse = ref(props.propsData.selectedCourse)
    let selectedTee = ref(props.propsData.selectedTee)
    let courses = props.propsData.courses.filter(f=>f.is_dgv == true)
    let priceRPR = props.propsData.priceRPR
    let selectedHCPI = ref(undefined)
    let syncHCPI = ref(true)
    let searchText = ref('')
    const selectedMarker = ref(undefined)
    const addClicked = ref(false)

    const { mutate: addRPRMutation } = useMutation(addRPRMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: payRPRMutation } = useMutation(payRPRMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createPaymentMutation } = useMutation(createPaymentMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: syncHCPMutation } = useMutation(syncHCPMutationFile, { fetchPolicy: 'no-cache' });

    const { result: findPlayersResult } = useQuery(findPlayersQueryFile, () => ({ isRPR: true, searchTerm: searchText.value.length > 3 ? searchText.value : '' }), () => ({ fetchPolicy: 'no-cache', enabled: searchText.value.length > 3 != '' }))
    const playersFound = useResult(findPlayersResult, null, data => data.findPlayers.players)

    watch(selectedCourse, (newVal, oldVal) => {
      selectedCourse.value.tees = courses.find(f=>f.cid == newVal.cid).tees
      if (selectedCourse.value.tees && selectedCourse.value.tees.length > 0)
        selectedTee.value = selectedCourse.value.tees[0]
      else
        selectedTee.value = undefined
    })

    syncHCPMutation().then(res => {
      if (res.data.syncHCP.success == true) {
        selectedHCPI.value = res.data.syncHCP.hcp
      }
      syncHCPI.value = false
    })

    return {
      closeOutline, addRPRMutation, payRPRMutation, paymenttype, rechargeDisabled, createPaymentMutation, clubInfo, lastschriftChecked, selectedCourse, selectedTee, selectedHCPI, courses, syncHCPI, playersFound, searchText, manSharp, womanSharp, personOutline, selectedMarker, priceRPR, addClicked
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
    addRPRRound() {
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.addClicked = true
      this.addRPRMutation({ courseId: this.selectedCourse.cid, teeId: this.selectedTee.tid, markerId: this.selectedMarker.id })
        .then(res => {
          loadingController.dismiss()
          if (res.data.addRPR.success == true) {
            toastController
                    .create({
                      message: this.$t('addSuccessful'),
                      duration: 3000,
                      color: 'success'
                    }).then(res => res.present())
            return modalController.dismiss(true)
          }
          else
          toastController
                    .create({
                      message: this.$t('addNotSuccessful'),
                      duration: 3000,
                      color: 'danger'
                    }).then(res => res.present())
      })
      .catch(err => {
        loadingController.dismiss()
        toastController
          .create({
            message: this.$t('addNotSuccessful') + ': ' + err,
            duration: 3000,
            color: 'danger'
          }).then(res => res.present())
      }) 
    },
    async rechargeByLastschrift() {
      this.payRPRMutation({ 
              amount: this.priceRPR,
              type: 'Lastschrift',
              orderId: ''
              })
            .then(res => {
              if (res.data.payRPR.success == true){
                this.addRPRRound()
              }
              else if (res.data.payRPR.success == false && res.data.payRPR.errorCode && res.data.payRPR.errorCode != '') {
                toastController
                  .create({
                    message: this.$t(res.data.payRPR.errorCode),
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
              }
              else {
                toastController
                  .create({
                    message: this.$t('addNotSuccessful'),
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
              }
              
            })
            .catch(err => {
                loadingController.dismiss()
                toastController
                  .create({
                    message: this.$t('addNotSuccessful') + ': ' + err,
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
            }) 

    },
    async recharge() {
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.rechargeDisabled = true
      this.payPayMutation({ 
        amount: this.priceRPR,
        type: this.paymenttype
        })
      .then(res => {
        if (res && res.data && res.data.payRPR && res.data.payRPR.success) {
          toastController
            .create({
              message: this.$t('addSuccessful'),
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
            loadingController.dismiss()
            return modalController.dismiss(this.priceRPR)
        }
        else {
          loadingController.dismiss()
          toastController
            .create({
              message: res.data.payCredit.errorCode + this.$t('addNotSuccessful'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        }
        })
      .catch(err => {
          toastController
            .create({
              message: err + this.$t('addNotSuccessful'),
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    },
  }
});


</script>

<style scoped>
ion-item {
  --ion-background-color: white !important;
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

</style>