<template>
  <div>
    <signature-pad
      :modelValue="signatureFile"
      @input="onInput"
      :width="300"
      :height="150"
      :options="{ penColor: 'black', backgroundColor: 'lightgray' }"
      :customStyle="{ border: 'black 2px solid' }"
      saveType="image/png"
      saveOutput="data_url"
      ref="signaturePad"
    >
    </signature-pad>
    <ion-button :disabled="isClearDisabled" @click="clearSignature">{{$t('clear')}}</ion-button>
    <ion-button :disabled="isConfirmDisabled" @click="saveSignature">{{$t('confirm')}}</ion-button>
  </div>
</template>

<script>

import { ref, computed } from "vue";
import { IonButton, } from '@ionic/vue';
import SignaturePad from "vue3-signature-pad";
export default {
  name: "Signature",
  components: {
    SignaturePad,IonButton
  },
  props: {
    isConfirmed: {
      required: true,
      type: Boolean
    },
  },
  setup(props, { emit }) {
    const signatureDataURL = ref(null)
    const signatureFile = ref(null);
    const signaturePad = ref(null);

    const isConfirmed = computed(() => props.isConfirmed ) 

    const isConfirmDisabled = computed(() => signatureDataURL.value == null || props.isConfirmed || signatureDataURL.value == 'data:,' ) 
    const isClearDisabled = computed(() => signatureDataURL.value == null  || props.isConfirmed ) 
    
    const getSignaturePad = () => {
      if (!signaturePad.value) {
        throw new Error("No signature pad ref could be found");
      }
      
      return signaturePad.value;
    };

    const clearSignature = () => {
      getSignaturePad().clearSignature();
      emit('clearSignature', '')
    };

    const saveSignature = () => {
      const signature = getSignaturePad().saveSignature();
      //props.signature = signatureDataURL.value
      //isConfirmed.value = true
      emit('saveSignature', signatureDataURL.value)
      //return signature;
    };

    const onInput = (value) => {
      getSignaturePad().resizeCanvas()
      if (!value) {
        signatureDataURL.value = null;
        signatureFile.value = null;
      } else if (value instanceof File) {
        signatureDataURL.value = null;
        signatureFile.value = value;
      } else {
        signatureDataURL.value = value;
        signatureFile.value = null;
      }
      emit('update:modelValue', signatureDataURL.value)
    };

    return {
      // state
      signaturePad,
      signatureDataURL,
      signatureFile,
      // methods
      clearSignature,
      saveSignature,
      onInput, isConfirmed, isConfirmDisabled, isClearDisabled
    };
  },
  mounted() {
    this.signaturePad.resizeCanvas()
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

