<template>
  <ion-page v-touch:swipe.right="onSwipeRight" v-touch:swipe.left="onSwipeLeft">
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Pros</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-header :style="isPlatform('ios') ? 'margin-top: 10px;' : ''" :translucent="true">
      <ion-list>
        <ion-item v-if="selectedPro && selectedPro.selectedItem" lines="full" class="itemFlight">
          <ion-button fill="clear" slot="start" @click="openProHelp()" >
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="helpCircleOutline" :ios="helpCircleOutline"></ion-icon>
          </ion-button>
          <ion-button fill="clear" slot="start" @click="refresh()" >
            <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'" color="primary" :md="refreshOutline" :ios="refreshOutline"></ion-icon>
          </ion-button>
          <ion-select interface="action-sheet" slot="end" v-model="selectedSelection" @ionChange="itemChanged($event.target.value)">
            <ion-select-option v-for="(item, index) in selectedPro.selection" :key="index" :value="item">{{ item.name }}</ion-select-option>          
          </ion-select>
        </ion-item>
        <ion-item lines="full" v-if="selectedPro">
          <ion-button :title="$t('today')" fill="clear" slot="start" @click="goDateToday()" >
            <ion-icon color="primary" :md="todayOutline" :ios="todayOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-button fill="clear" slot="start" @click="goDateBack()" >
            <ion-icon :md="arrowBackOutline" :ios="arrowBackOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
          <ion-datetime :min="(new Date()).getFullYear() - 1" :max="(new Date()).getFullYear() + 1" slot="start" v-model="selectedDate" :day-short-names="customDayShortNames" display-format="DDD, DD MMM YY" placeholder="Select Date"></ion-datetime>
          <ion-button fill="clear" slot="start" @click="goDateForward()" >
            <ion-icon :md="arrowForwardOutline" :ios="arrowForwardOutline" :style="isPlatform('mobile') ? 'zoom: 1.0' : 'zoom: 1.2'"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-list>

    </ion-header>


    <ion-content :fullscreen="true" ref="content">
      <ion-refresher v-if="isPlatform('mobile')" slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-grid v-if="selectedPro && selectedDate" style="z-index: 999; !important" >
        <ion-row class="ion-align-items-stretch">
          <ion-col v-for="(slot, index) in selectedPro.slots" :key="index" size="4" size-lg="4" size-sm="6" size-xs="12">
            <ion-card :id='setId(slot)' :ref='setId(slot)'>
              <ion-card-header>
                <ion-item lines="full">
                  <ion-card-title slot="start">{{ dayjs(slot.date).tz().format('HH:mm') }}</ion-card-title>
                  <ion-card-title v-if="!slot.isPast && (selectedPro.paymentEnabled || selectedPro.showPrice)"  slot="end">{{ slot.price }} </ion-card-title>
                </ion-item>
                  <ion-list style="padding:0;" v-if="isLoggedIn && !slot.isPast && slot.canBook">
                    <ion-item v-if="slot.isFree" lines="full" class="itemFlight">
                      <ion-select interface="popover"  value="me">
                        <ion-select-option value="me">{{ $t('@me') }}
                        </ion-select-option>
                      </ion-select>
                      <ion-button v-if="((selectedPro && !selectedPro.paymentEnabled) || slot.priceFloat == 0)" fill="clear" @click="doBooking(slot)" >
                        <ion-icon style="zoom: 1.4; vertical-align: middle;" color="success" :md="downloadOutline" :ios="downloadOutline"></ion-icon>
                      </ion-button>
                      <ion-button fill="clear" @click="editBooking(slot)" >
                        <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="colorWandOutline" :ios="colorWandOutline"></ion-icon>
                      </ion-button>
                    </ion-item>
                    <ion-item v-if="slot.isMe || (selectedSelection && UserData.proCalID == selectedSelection.id && slot.bookingId > 0)" lines="full" :color="slot.isMe ? 'success' : ''">
                      <ion-label v-if="!(UserData.proCalID == selectedSelection.id && slot.bookingId > 0)" class="ion-text-wrap">{{ UserData.lastName }} {{ UserData.firstName }} </ion-label>
                      <ion-label v-if="(UserData.proCalID == selectedSelection.id && slot.bookingId > 0)" class="ion-text-wrap">{{ slot.subject }} </ion-label>
                      <ion-icon @click="deleteBooking(slot)" v-if="slot.canDelete && !slot.isPast && isLoggedIn" :md="trashOutline" :ios="trashOutline" color="danger" style="zoom: 0.8"></ion-icon>
                    </ion-item>
                  </ion-list>
                <ion-item lines="full">
                  <ion-progress-bar v-if="slot.isPast" :value="0"></ion-progress-bar>
                  <ion-progress-bar v-else-if="slot.isFree" color="success" value="100"></ion-progress-bar>
                  <ion-progress-bar v-else color="danger" value="100"></ion-progress-bar>
                </ion-item>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonRefresher, IonGrid, IonRow, IonCol, isPlatform, IonRefresherContent, IonCardTitle,
        IonSelect, IonSelectOption, IonDatetime, IonList, IonInput, loadingController, IonProgressBar, alertController } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { personOutline, todayOutline, arrowBackOutline, arrowForwardOutline, helpCircleOutline, refreshOutline,
        openOutline, trashOutline, downloadOutline, colorWandOutline } from 'ionicons/icons';
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getProQueryFile from '../graphql/getPro.query.gql'
import { useRoute, useRouter } from 'vue-router';
import translate from '@/plugins/translate';
import saveProBookingMutationFile from '../graphql/saveProBooking.mutation.gql'
import deleteProBookingMutationFile from '../graphql/deleteProBooking.mutation.gql'
import EditProBookingDialog from './dialogs/EditProBookingDialog'
import EditProBookingForProDialog from './dialogs/EditProBookingForProDialog'
import HelpProDialog from './HelpProDialog'

export default defineComponent({
  name: 'Pros',
  components: {
    IonContent, IonRefresher, IonList, IonProgressBar,
    IonHeader, IonGrid, IonRow, IonCol,
    IonPage, IonRefresherContent,
    IonTitle, IonCardTitle, IonInput,
    IonToolbar, IonSelect, IonSelectOption, IonDatetime,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon
  },
  setup() {
    let dataLoaded = ref(false)
    let loadingSpinner = null
    let forceSpinner = ref(false)
    const content = ref(null)
    const route = useRoute()
    const router = useRouter()
    let selectedDate = ref(dayjs.tz(new Date()).format('YYYY-MM-DD'))
    if (route.params.date)
      selectedDate = ref(dayjs.tz(new Date(route.params.date)).format('YYYY-MM-DD'))
    const customDayShortNames = [
      translate('sunday'),
      translate('monday'),
      translate('tuesday'),
      translate('wednesday'),
      translate('thursday'),
      translate('friday'),
      translate('saturday')
    ];
    const currentSlot = ref();

    const { refetch: getProRefetch, result: getProResult, loading: getProLoading } = useQuery(getProQueryFile, () => ({ id: route.path.startsWith('/pro/') ? parseInt(route.params.id) : 0, date: dayjs(selectedDate.value).format('YYYY-MM-DD')  }), () => ({ pollInterval: 60000, fetchPolicy: 'no-cache', enabled: route.params.id != null && route.path.startsWith('/pro/') }))
    const selectedPro = useResult(getProResult, null, data => data.getPro.calendar)
    const selectedSelection = useResult(getProResult, null , data => data.getPro.calendar.selection.find(f => f.id == route.params.id))

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(async ()  => { 
        if (!getProLoading.value && loadingSpinner != null) {
          loadingSpinner.dismiss() 
          loadingSpinner = null
          if (!dataLoaded.value)
            setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.parentElement.offsetTop - currentSlot.offsetTop - currentSlot.parentElement.parentElement.offsetTop - currentSlot.parentElement.parentElement.parentElement.offsetTop) }, 1000)
          dataLoaded.value = true
          forceSpinner.value = false
        }
      })
    }).catch(err => { })

    watch(
      () => route.params.date,
      (date, prevId) => {
        if (route.path.startsWith('/pro/') && date != undefined)
          selectedDate.value = dayjs.tz(new Date(route.params.date)).format('YYYY-MM-DD')
          forceSpinner.value = true
      }
    )


    watch(getProLoading, async (newLoading, prevLoading)=> {
      if (!newLoading && loadingSpinner != null) {
          loadingSpinner.dismiss()
          loadingSpinner = null
          if (!dataLoaded.value)
            setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.parentElement.offsetTop - currentSlot.offsetTop - currentSlot.parentElement.parentElement.offsetTop - currentSlot.parentElement.parentElement.parentElement.offsetTop) }, 1000)
          dataLoaded.value = true
          forceSpinner.value = false
          return
      }
      else if (newLoading && (!dataLoaded.value || forceSpinner.value)) {
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!getProLoading.value && loadingSpinner) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                  if (!dataLoaded.value)
                    setTimeout(() => { var currentSlot = document.getElementById('currentSlot'); if (currentSlot) content.value.$el.scrollToPoint(0, currentSlot.parentElement.offsetTop - currentSlot.offsetTop - currentSlot.parentElement.parentElement.offsetTop - currentSlot.parentElement.parentElement.parentElement.offsetTop) }, 1000)
                  dataLoaded.value = true
                  forceSpinner.value = false
                }
              })
            }).catch(err => { })
      }
    })

    const { mutate: saveProBookingMutation } = useMutation(saveProBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteProBookingMutation } = useMutation(deleteProBookingMutationFile, { fetchPolicy: 'no-cache' });

    return {
      selectedPro, personOutline, isPlatform, selectedDate, todayOutline, arrowBackOutline, arrowForwardOutline,
      helpCircleOutline, refreshOutline, customDayShortNames, getProRefetch, getProLoading, loadingSpinner, content,
      dayjs, openOutline, trashOutline, saveProBookingMutation, deleteProBookingMutation, currentSlot,
      forceSpinner, downloadOutline, dataLoaded, colorWandOutline, selectedSelection
    }
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      isLoggedIn : function() { 
        if (this.isLoggedIn != undefined && this.isLoggedIn != this.$store.getters.isLoggedIn) {
          this.getProRefetch()
        }
        return this.$store.getters.isLoggedIn
      },
      UserData : function(){ return this.$store.getters.UserData}
  },
  methods: {
    onSwipeRight() {
      this.goDateBack()
	  },
    onSwipeLeft() {
      this.goDateForward()
	  },
    async doRefresh(event) {
      event.target.complete();
      await this.getProRefetch()
    },
    itemChanged(value) {
      if (value != undefined && value.id != this.selectedSelection.id) {
        this.forceSpinner = true
        this.dataLoaded = false
        this.$router.push('/pro/' + value.id + '/' + dayjs.tz(this.selectedDate).format('YYYY-MM-DD'))
      }
    },
    async openProHelp(pro) {
      const modal = await modalController
        .create({
          component: HelpProDialog,
          componentProps: {
            propsData: {
              selectedPro: this.selectedPro,
            }
          },
        })
      return modal.present();
    },
    async editBooking(slot) {
      let comp = EditProBookingDialog
      if (this.UserData.proCalID == this.selectedSelection.id)
        comp = EditProBookingForProDialog

      const modal = await modalController
        .create({
          component: comp,
          componentProps: {
            propsData: {
              slot: slot,
              selectedPro: this.selectedPro,
            }
          },
        })
      modal.onDidDismiss()
        .then((data) => {
          if (data.data) {
            this.getProRefetch()
          }
        })

      return modal.present();
    },
    async deleteBooking(slot) {
      const alert = await alertController
        .create({
          header: 'Pro',
          message: this.$t('delete') + ': ' + dayjs(slot.date).tz().format('DD.MM.YYYY HH:mm') + '?',
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                this.deleteProBookingMutation({ date: slot.date, proId: this.selectedPro.selectedItem.id, id: parseInt(slot.bookingId), language: this.$store.getters.language })
                .then(res => {
                  toastController
                    .create({
                      message: this.$t('deleteSuccessful'),
                      duration: 2000,
                      color: 'warning'
                    }).then(res => res.present())
                    this.getProRefetch()
                })
                .catch(err => {
                  toastController
                    .create({
                      message: this.$t('deleteNotSuccessful' + err),
                      duration: 2000,
                      color: 'danger',
                    }).then(res => res.present())
                })
              },
            },
          ],
        });
      return alert.present();
    },
    async doBooking(slot) {
      if (this.selectedPro.paymentEnabled && slot.priceFloat > 0) {
        this.editBooking(slot)
        return
      }
      this.saveProBookingMutation({ date: slot.date, proId: this.selectedPro.selectedItem.id, interval: 1, price: 0, language: this.$store.getters.language })
      .then(res => {
          this.getProRefetch()
          toastController
            .create({
              message: this.$t('bookingAdded'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 

    },
    setId(slot) {
      if(slot.currentSlot) {
        return "currentSlot"
      }
    },
    refresh() {
      this.forceSpinner = true
      this.getProRefetch()
    },
    goDateToday() {
      this.forceSpinner = true
      this.$router.push('/pro/' + this.selectedPro.selectedItem.id + '/' + dayjs.tz(new Date()).format('YYYY-MM-DD'))
      this.selectedDate = dayjs.tz(new Date()).format('YYYY-MM-DD')
    },
    goDateBack() {
      this.forceSpinner = true
      this.$router.push('/pro/' + this.selectedPro.selectedItem.id + '/' + dayjs.tz(this.selectedDate).subtract(1, 'day').format('YYYY-MM-DD'))
      this.selectedDate = dayjs.tz(this.selectedDate).subtract(1, 'day').format('YYYY-MM-DD')
    },
    goDateForward() {
      this.forceSpinner = true
      this.$router.push('/pro/' + this.selectedPro.selectedItem.id + '/' + dayjs.tz(this.selectedDate).add(1, 'day').format('YYYY-MM-DD'))
      this.selectedDate = dayjs.tz(this.selectedDate).add(1, 'day').format('YYYY-MM-DD')
    },
  },
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.itemFlight {
    --background-hover: unset;
}

ion-button {
  --padding-end: 0;
  --inner-padding-end: 0;
  margin-inline: 0px 15px;
}

</style>