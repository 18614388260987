<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('editBooking') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title v-if="slot">
          {{ dayjs(slot.date).tz().format('DD.MM.YYYY HH:mm') }} {{ selectedIndoor.selectedItem.name }}
        </ion-card-title>
      </ion-card-header>
      <ion-list  v-show="selectedIndoor" lines="full" class="ion-no-margin">
        <ion-item>
          <ion-icon slot="start" :ios="arrowForwardCircleOutline" :md="arrowForwardCircleOutline"></ion-icon>
          <ion-label slot="start">{{$t('units')}}</ion-label>
          <ion-select v-if="selectedIndoor" interface="popover" slot="start" v-model="selectedInterval">
            <ion-select-option v-for="(item, index) in selectedIndoor.interval_selection" :key="index" :value="item">{{ item }}</ion-select-option>          
          </ion-select>
          <ion-label  v-if="selectedIndoor" slot="start">{{ selectedIndoor.interval_name }}</ion-label>
        </ion-item>
        <ion-item v-if="selectedIndoor && selectedIndoor.interval_hint != ''">
          <ion-label class="ion-text-wrap">{{ selectedIndoor.interval_hint }}</ion-label>
        </ion-item>
        <ion-item v-if="slot && slot.priceFloat > 0">
          <h3>{{ $t('amountToPay') }}: {{ (slot.priceFloat * selectedInterval * selectedIndoor.intervalDivisor).toFixed(2) }} {{ slot.currencySymbol }}</h3>
          <ion-checkbox v-if="selectedIndoor && selectedIndoor.paymentEnabled" :disabled="slot.forceIndoorPayment && slot.payIndoorfee" v-model="slot.payIndoorfee" slot="end"></ion-checkbox>
        </ion-item>

        

        <ion-list v-show="selectedIndoor && selectedIndoor.paymentEnabled && slot.payIndoorfee && slot.priceFloat > 0">
          
          <ion-item position="stacked">{{ $t('bookAndPay')}}</ion-item>
          <ion-item v-if="(clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canLastschriftIndoor)">
              <ion-label slot="start">{{ $t('payLastschrift')}}</ion-label>
              <ion-checkbox v-model="lastschriftChecked" slot="end"></ion-checkbox>
            </ion-item>
            
            <ion-item v-if="lastschriftChecked">
              <ion-label class="ion-text-wrap">{{ $t('payLastschriftHint')}}</ion-label>
              <ion-button @click="createIndoorBookingByLastschrift()" color="success">{{ $t('bookAndPay') }}</ion-button>
            </ion-item>

            
            <ion-item v-show="!lastschriftChecked">
              <div  id="paypalButton"></div>
            </ion-item>
        </ion-list>

        <ion-button v-if="(selectedIndoor && !selectedIndoor.paymentEnabled)  || (slot && !slot.payIndoorfee)" style="margin-top: 15px;" expand="block" color="primary" @click="doBooking()">{{ slot.payIndoorfee && selectedIndoor.paymentEnabled ? $t('makeAndPayIndoorBooking') : $t('makeIndoorBooking') }}</ion-button>
      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonSelect, IonSelectOption,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, loadingController,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, 
          personAddOutline, trashOutline, timeOutline, arrowForwardCircleOutline } from 'ionicons/icons';
import { useMutation } from '@vue/apollo-composable'
import saveIndoorBookingMutationFile from '../../graphql/saveIndoorBooking.mutation.gql'
import createPaymentMutationFile from '../../graphql/createPayment.mutation.gql'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { loadScript } from "@paypal/paypal-js";
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'EditIndoorBookingDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonSelect, IonSelectOption,
              },
  props: ['propsData'],
  setup() {
    let slot = ref()
    let selectedIndoor = ref()
    let selectedInterval = ref(0)
    const clubInfo = ref(getClubInfo())
    const lastschriftChecked = ref(false)

    const { mutate: saveIndoorBookingMutation } = useMutation(saveIndoorBookingMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: createPaymentMutation } = useMutation(createPaymentMutationFile, { fetchPolicy: 'no-cache' });

    return {
      saveIndoorBookingMutation, 
      dayjs,
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline,
      trashOutline,timeOutline, slot, selectedIndoor, selectedInterval, arrowForwardCircleOutline, createPaymentMutation, clubInfo,
      lastschriftChecked
    }
  },
  computed: {
      isSuccessfullBraintreePaypalAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreePaypalAuthorized},
      isSuccessfullBraintreeCreditCardAuthorized : function(){ return this.$store.getters.isSuccessfullBraintreeCreditCardAuthorized },
      paymenttype : function(){ 
        return 'PayPalAccount'
/*        if (this.isSuccessfullBraintreePaypalAuthorized && this.isSuccessfullBraintreeCreditCardAuthorized )
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreePaypalAuthorized)
          return 'PayPalAccount'
        else if (this.isSuccessfullBraintreeCreditCardAuthorized)
          return 'CreditCard' */
       },
  },
  mounted() {
    this.slot = this.propsData.slot
    this.selectedIndoor = this.propsData.selectedIndoor
    this.selectedInterval = this.selectedIndoor.interval_selection[0]

    loadScript({ "client-id": this.clubInfo.clubInfo.paypalClientID, currency: this.clubInfo.clubInfo.currency }).then((paypal) => {
    paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
          
        },
        createOrder: async () => {
          const price = this.slot.payIndoorfee ? (this.slot.priceFloat * this.selectedInterval * this.selectedIndoor.intervalDivisor) : 0
          const p = await this.createPaymentMutation({ description: 'Indoorplätze' + " " + this.selectedIndoor.selectedItem.name, amount: parseFloat(price), paymentType: 'payPal' })
          if (!p.data.createPayment.success) {
            return ''
          }
          return p.data.createPayment.token
         },
         onError: async (err) => {
            toastController
              .create({
                message: this.$t('invalidPayment'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
         },
         onApprove: async (data,actions) => {
            this.buttonDisabled = true
            loadingController.create().then(res => {
              res.present()
            }).catch(err => { })
            this.saveIndoorBookingMutation({ date: this.slot.date, indoorId: this.selectedIndoor.selectedItem.id, interval: this.selectedIndoor.interval_selection.findIndex(i => i == this.selectedInterval) + 1, price: this.slot.payIndoorfee ? (this.slot.priceFloat * this.selectedInterval * this.selectedIndoor.intervalDivisor) : 0, paymentType: 'PayPalAccount', language: this.$store.getters.language, orderId: data.orderID })
            .then(res => {
                toastController
                  .create({
                    message: this.$t('bookingAdded'),
                    duration: 3000,
                    color: 'success'
                  }).then(res => res.present())
                  loadingController.dismiss()
                  return modalController.dismiss(true)
            })
            .catch(err => {
                loadingController.dismiss()
                toastController
                  .create({
                    message: this.$t('notSuccessful') + ': ' + err,
                    duration: 3000,
                    color: 'danger'
                  }).then(res => res.present())
            }) 
         },
         onCancel: async () => {
          const price = this.slot.payIndoorfee ? (this.slot.priceFloat * this.selectedInterval * this.selectedIndoor.intervalDivisor) : 0
          await this.createPaymentMutation({ amount: parseFloat(price), paymentType: 'payPalCancel' })
         }
     }).render("#paypalButton")});

  },
  methods: {
    doBooking() {
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.saveIndoorBookingMutation({ date: this.slot.date, indoorId: this.selectedIndoor.selectedItem.id, interval: this.selectedIndoor.interval_selection.findIndex(i => i == this.selectedInterval) + 1, price: this.slot.payIndoorfee ? (this.slot.priceFloat * this.selectedInterval * this.selectedIndoor.intervalDivisor) : 0, paymentType: this.paymenttype, language: this.$store.getters.language })
      .then(res => {
          toastController
            .create({
              message: this.$t('bookingAdded'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
            loadingController.dismiss()
            return modalController.dismiss(true)
      })
      .catch(err => {
          loadingController.dismiss()
          toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
    closeDialog() {
      return modalController.dismiss()
    },
    createIndoorBookingByLastschrift(){
      this.buttonDisabled = true
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.saveIndoorBookingMutation({ date: this.slot.date, indoorId: this.selectedIndoor.selectedItem.id, interval: this.selectedIndoor.interval_selection.findIndex(i => i == this.selectedInterval) + 1, price: this.slot.payIndoorfee ? (this.slot.priceFloat * this.selectedInterval * this.selectedIndoor.intervalDivisor) : 0, paymentType: 'Lastschrift', language: this.$store.getters.language})
            
            .then(res => {
                toastController
                  .create({
                    message: this.$t('bookingAdded'),
                    duration: 3000,
                    color: 'success'
                  }).then(res => res.present())
                  loadingController.dismiss()
                  return modalController.dismiss(true)
            })
            .catch(err => {
                loadingController.dismiss()
            }) 
    }
  }
});
</script>