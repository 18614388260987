<template>
  <ion-page>
      <ion-content :fullscreen="true">
        <ion-item  v-if="comment && comment != ''">
          <ion-grid class="center">
          <ion-row>
            <ion-col size="12">
              <span v-html="comment.split('<br>')[0]" class="course center ion-no-padding ion-no-margin" style="text-align: center; margin-left: 0px; white-space: pre-wrap;" ></span>
              <span v-if="comment.split('<br>').length > 1" v-html="comment.split('<br>')[1]" class="course center ion-no-padding ion-no-margin" style="text-align: center; margin-left: 0px; white-space: pre-wrap;" ></span>
            </ion-col>
          </ion-row>
          </ion-grid>
        </ion-item>
        <ion-item>
        <ion-grid class="center">
          <ion-row>
            <ion-col size="auto">
              <ion-icon class="center" style="zoom: 1.4; margin-right: 10px;" :color="cartsAllowed ? 'success' : 'danger'" :md="carOutline" :ios="carOutline"></ion-icon>
            </ion-col>
            <ion-col size="auto">
              <ion-label :style="cartsAllowed ? 'color: green;' : 'color: red;'" class="course center ion-text-wrap">{{ cartsAllowed ? 'Carts erlaubt' : 'Carts verboten' }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,IonImg,
IonInput, IonItem, IonList } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import findTeetimestatusQueryFile from '../graphql/findTeetimestatus.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import { timeOutline, carOutline } from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput,
    IonHeader, IonItem, IonList,
    IonPage, IonImg,
    IonTitle, IonIcon,
    IonToolbar, IonGrid, IonRow, IonCol,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const { result: findTeetimestatusResult } = useQuery(findTeetimestatusQueryFile, () => ({ id: parseInt(route.params.id) }), () => ({ fetchPolicy: 'no-cache', pollInterval: 21000 }))
    const comment = useResult(findTeetimestatusResult, null, data => data.findTeetimestatus.comment)
    const cartsAllowed = useResult(findTeetimestatusResult, null, data => data.findTeetimestatus.cartsAllowed)

    return {
      timeOutline, comment, dayjs, cartsAllowed, carOutline
    }
  },
});
</script>

<style scoped>

.card {
  background-color: rgb(75, 59, 219); 
}

.playercard {
   height: 80px;
}

.player {
   padding: 5px !important;
}

ion-col.rows {
   margin: 0px;
   padding: 0px;
   height: 100px;
   font-weight: bold;
   border-bottom: 1px solid grey;
   vertical-align: center;
}

ion-col.header {
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    vertical-align: center;
  }

ion-col.time {
    font-size: 24px; 
    font-weight: bold;
  }

.course {
    font-family: "Minion, Arial";
    font-size: 16px; 
    font-weight: bold;
  }

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.center {
  display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

</style>