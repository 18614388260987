<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>MyClub</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <section v-if="isLoggedIn">
        <ion-grid>
          <ion-row class="ion-align-items-stretch">
            <ion-col size="12">
              <ion-card class="cardconteudo">
                <ion-card-content>
                  <ion-label class="ion-text-wrap">{{ $t('contactClub') }}</ion-label><br>
                  <ion-label class="ion-text-wrap">{{ $t('email') }}: {{ clubEmail }}</ion-label><br>
                  <ion-label class="ion-text-wrap">{{ $t('telephone') }}: {{ clubPhone }}</ion-label>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
          <ion-row class="ion-align-items-stretch">
            <ion-col v-for="(info, index) in myClubFound" :key="index" size="6" size-lg="6" size-sm="12" size-xs="12">
              <ion-card class="cardconteudo">
                <ion-card-header>
                  <ion-item>
                    <ion-card-title>{{ $t(info.name) }}</ion-card-title>
                  </ion-item>
                </ion-card-header>
                <ion-card-content>
                  <ion-list lines="full" class="ion-no-margin">
                    <ion-item  v-for="(detail, index) in info.details" :key="index" >
                      <ion-label style="padding-left: 5px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ $t(detail.key) }}:</ion-label> <ion-label style="padding-left: 5px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ detail.key == 'lastName' || detail.key == 'firstName' ? detail.value : $t(detail.value) }}</ion-label>
                      <ion-button v-if="info.name=='aboInfo'" @click="openData(detail)" style="margin-left: 10px;" class="ion-no-margin ion-no-padding" fill="clear">
                        <ion-icon style="zoom: 1.4; vertical-align: middle;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
                      </ion-button>
                    </ion-item>
                  </ion-list>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, alertController, toastController, modalController,
IonCard, IonCardContent, IonCardTitle, IonImg, IonCardHeader, IonGrid, IonRow, IonCol, IonIcon, IonItem, loadingController, isPlatform,
IonList } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useQuery, onResult, useResult, useMutation } from '@vue/apollo-composable'
import { chevronDownOutline, chevronUpOutline, trashOutline, refreshOutline, logInOutline, createOutline, checkmarkDoneOutline,
golfOutline, peopleOutline, peopleCircleOutline, schoolOutline, reorderFourOutline, medalOutline, fastFoodOutline,
documentOutline, pencilOutline, cashOutline,personOutline,checkmarkOutline, mailOutline, personAddOutline, helpOutline, openOutline  } from 'ionicons/icons';
import getMyClubQueryFile from '../graphql/getMyClub.query.gql'
import AboHistoryDialog from './dialogs/AboHistoryDialog'

export default defineComponent({
  name: 'MyClub',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonGrid, IonRow, IonCol, IonIcon, IonItem, IonList,
    IonButton, IonLabel, IonCard, IonCardContent, IonCardTitle, IonImg, IonCardHeader
  },
  setup() {

    const {onResult, refetch: getMyClubRefetch, result: getMyClubResult, loading: getMyClubLoading } = useQuery(getMyClubQueryFile, () => ({ }, { fetchPolicy: 'no-cache' } ))
    const myClubFound = useResult(getMyClubResult, null, data => data.getMyClub.myClub)
    const clubPhone = useResult(getMyClubResult, null, data => data.getMyClub.clubPhone)
    const clubEmail = useResult(getMyClubResult, null, data => data.getMyClub.clubEmail)

    return {
      chevronDownOutline, chevronUpOutline, trashOutline, refreshOutline,  logInOutline, createOutline, checkmarkDoneOutline,
      golfOutline, peopleOutline, peopleCircleOutline, schoolOutline, reorderFourOutline, medalOutline, fastFoodOutline, documentOutline,
      pencilOutline, cashOutline, personOutline, checkmarkOutline, mailOutline, personAddOutline, helpOutline, myClubFound, clubPhone, clubEmail, openOutline, getMyClubRefetch
    }
  },
  computed: {
      isLoggedIn : function() { 
        if (this.isLoggedIn != undefined && this.isLoggedIn != this.$store.getters.isLoggedIn) {
          this.getMyClubRefetch()
        }
        return this.$store.getters.isLoggedIn
      },
  },
  methods: {
    async openData(item) {
      const modal = await modalController
          .create({
            component: AboHistoryDialog,
            componentProps: {
              propsData: {
                selectedAbo: item
              }
            },
          })
        return modal.present();
      }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-item {
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

</style>