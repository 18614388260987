<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>{{ $t('GuestsQRCode') }} </ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <h3 style="margin-left: 15px;" v-if="livescorings && livescorings.length <= 0">{{ $t('noTournaments') }}</h3>
      <ion-grid v-if="livescorings && livescorings.length > 0">
        <ion-row>
          <ion-col>
            <ion-select  interface="action-sheet" v-model="selectedRound">
              <ion-select-option v-for="(item, index) in livescorings" :key="index" :value="item">{{ item.name }} Runde: {{ item.round_index }} {{ dayjs(item.start_time).format('DD.MM.YYYY HH:mm') }}</ion-select-option>          
            </ion-select>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid v-if="startList && startList.length > 0">
        <ion-row v-for="flight in startList" :key="flight">
          <ion-col>
            <ion-card>
              <ion-card-header>
                <ion-label style="font-weight: bold;">Spielgruppe: {{ flight.flighId }}, Tee {{ flight.startTee }}, {{ flight.startTime }}</ion-label>
              </ion-card-header>
              <ion-card-content>
                <ion-grid>
                  <ion-row class="link" v-for="player in flight.players" :key="player" @click="player.showQRCode = !player.showQRCode">
                    <ion-col>{{ player.name }}</ion-col>
                    <ion-col>{{ player.clubName }} ({{ player.hcp }})</ion-col>
                    <ion-col><img :src="player.qrcode" alt="" v-if="player.showQRCode"></ion-col>
                  </ion-row>
                </ion-grid>
              </ion-card-content>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController } from '@ionic/vue';
import { defineComponent, watch, ref, computed } from 'vue';
import { personOutline, calendarClearSharp, golfOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getLivescoringRoundsQueryFile from '../graphql/getLivescoringRounds.query.gql'
import findTournamentStartListQueryFile from "../graphql/findTournamentStartList.query.gql";
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'QRCodes',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null
   const router = useRouter()
   let selectedRound = ref()

   const {onResult, refetch: getLivescoringRoundsRefetch, result: getLivescoringRoundsResult, loading: getLivescoringRoundsLoading } = useQuery(getLivescoringRoundsQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const livescorings = useResult(getLivescoringRoundsResult, null, data => data.getLivescoringRounds.livescorings)

    const {result: findTournamentStartListResult, refetch: findTournamentStartListRefetch} = useQuery(findTournamentStartListQueryFile, () => ({ 
        tournament_id: parseInt(selectedRound.value?.id ?? 0),
        round_index: parseInt(selectedRound.value?.round_index ?? 0),
        switchURL: 0,
        switchTournaments: 0
    }), () => ({ fetchPolicy: 'no-cache', enabled: selectedRound.value && selectedRound.value.id > 0 } ))
    const startList = computed(() => (selectedRound.value?.id && findTournamentStartListResult.value?.findTournamentStartList.tournamentStartList) ?? [])

    onResult(queryResult => {
      if (queryResult.data && queryResult.data.getLivescoringRounds && queryResult.data.getLivescoringRounds.livescorings.length > 0) {
        selectedRound.value = queryResult.data.getLivescoringRounds.livescorings[0]
      }
    })

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getLivescoringRoundsLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getLivescoringRoundsLoading, (getLivescoringRoundsLoading, prevLoading) => {
      if (!getLivescoringRoundsLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getLivescoringRoundsLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getLivescoringRoundsLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      livescorings, personOutline, calendarClearSharp, dayjs, golfOutline, selectedRound, startList
    }
  },
  methods: {
    async showQRCode() {
      /*const modal = await modalController
          .create({
            component: AddRPRDialog,
            componentProps: {
              propsData: {
                selectedCourse: this.selectedCourse,
                selectedTee: this.selectedTee,
                selectedHCPI: this.selectedHCPI,
                courses: this.courses,
                priceRPR: this.priceRPR
              }
            },
          })
          modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              this.getLivescoringsRefetch()
            }
          })

      return modal.present();*/
    },
  }
});
</script>

<style scoped>
.link {
  cursor: pointer;
}
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>