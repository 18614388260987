<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row>
            <ion-col>
              <div>
                <ion-title>{{ $t(title) }}</ion-title>
              </div>
            </ion-col>
            <ion-col>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list lines="full" class="ion-no-margin">  
        
          <h3  >{{ $t(message) }}</h3>
          <hr>
          <hr>
          <hr>
          <ion-button style="margin-left: 40%; width:20%;"  @click="yes()"  color="success">{{ $t('updateApp') }}</ion-button>

      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import * as IonicIcon from 'ionicons/icons';
import { toastController, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'


export default defineComponent({
  name: 'YesDialog',
  data() {
    return {
      title: '',
      message: '',
      result: false
    }
  },
  props: ['propsData'],
  mounted() {
    this.title = this.propsData.title
    this.message = this.propsData.message
  },
  setup() {
    return {IonicIcon}
  },
  methods: {
    closeDialog() {
      return modalController.dismiss(this.result);
    },
    yes(){
      this.result = true;
      this.closeDialog();
    }
  }
});
</script>