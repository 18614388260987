<template>
  <ion-page>
      <ion-content :fullscreen="true">
        <div style="margin-left: 10%;margin-right: 10%; margin-bottom: 20px;">
          <ion-item  v-if="tournamentName">
            <ion-img style="width: 60px; height:60px; margin-right: 10px;" :src="logo" />
            <ion-label :class="fontName && fontName != '' ? fontName + ' tournament' : 'tournament'">{{ tournamentName }}</ion-label>
            <ion-label slot="end" :class="fontName && fontName != '' ? fontName + ' tournament' : 'tournament'">{{ tournamentDate }}</ion-label>
          </ion-item>
          <ion-card>
            <ion-item v-if="!showStartlist && tournamentName">
              <ion-label :id="-1" v-observe-visibility="visibilityChanged" color="warning" :class="fontName && fontName != '' ? fontName + ' tournament' : 'tournament'">Keine Startzeiten zur Anzeige gefunden</ion-label>
            </ion-item>
            <ion-item v-if="showStartlist" style="margin: 3px;">
            <ion-grid>
              <ion-row v-for="(item, sindex) in startlistFound" :key="sindex">
                <ion-col :class="fontName && fontName != '' ? fontName + ' time rows' : 'time rows'">{{ dayjs(item.date).format('HH:mm') }}<br>Tee {{ item.tee }}</ion-col>
                <ion-col v-for="(slot, index) in item.slots" :key="index" :value="slot" class="rows">
                  <ion-card color="success" class="playercard ion-no-padding">
                    <ion-card-content class="ion-no-margin ion-no-padding player">
                      <div v-for="(player, i) in slot.players" :key="i" :value="player">
                        <ion-label style="padding-left: 5px; margin: 0px; margin-top: 5px; font-size: 20px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap' : 'ion-text-wrap'">{{ player.lastName }} {{ player.firstName }} {{player.hcp}}</ion-label>
                        <ion-label color="dark" style="padding-left: 5px; margin: 0px; margin-top: 0px; font-size: 16px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap' : 'ion-text-wrap'">{{ player.clubname }} </ion-label>
                      </div>
                      <span :id="sindex" v-observe-visibility="visibilityChanged"></span>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
              </ion-row>
            </ion-grid>
            </ion-item>
          </ion-card>
        </div>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,IonImg,
IonInput, IonItem, IonList } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import findStartlistsQueryFile from '../graphql/findStartlist.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import { timeOutline } from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput,
    IonHeader, IonItem, IonList,
    IonPage, IonImg,
    IonTitle, IonIcon,
    IonToolbar, IonGrid, IonRow, IonCol,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    let firstIndexNotShown = ref(0)

    const { refetch: findStartlistRefetch, result: findStartlistResult } = useQuery(findStartlistsQueryFile, { index: parseInt(firstIndexNotShown.value) }, () => ({ fetchPolicy: 'no-cache' }))
    const startlistFound = useResult(findStartlistResult, null, data => data.findStartlist.startList)
    const tournamentName = useResult(findStartlistResult, null, data => data.findStartlist.tournamentname)
    const tournamentDate = useResult(findStartlistResult, null, data => data.findStartlist.tournamentdate)
    const showStartlist = useResult(findStartlistResult, null, data => data.findStartlist.showTeetimes)
    const logo = useResult(findStartlistResult, null, data => data.findStartlist.logo)
    const idToUseTeetime = useResult(findStartlistResult, null, data => data.findStartlist.id)
    const isShotGunStart = useResult(findStartlistResult, null, data => data.findStartlist.isShotGunStart)
    const random = useResult(findStartlistResult, null, data => data.findStartlist.random)
    const fontName = useResult(findStartlistResult, null, data => data.findStartlist.fontName)

    let scrollScreen = ref(true)

    watch(scrollScreen, (newVal, prevVal) => {
      if (newVal == false && idToUseTeetime.value > 0 && route.path == '/startlistscreen') {
        setTimeout(() => { router.push('/infoscreen/' + idToUseTeetime.value + '?type=t') }, startlistFound.value.length == 0 ? 10000: 10000)
      }
      else if (newVal == false && idToUseTeetime.value == -1 && route.path == '/startlistscreen') {
        setTimeout(() => { router.push('/nextcompetitionsscreen') }, startlistFound.value.length == 0 ? 10000: 10000)
      }
    })

    watch(route, (newVal, prevVal) => {
      if (newVal.path == '/startlistscreen'){
        firstIndexNotShown.value = 0
        scrollScreen.value = true
        findStartlistRefetch({ index: firstIndexNotShown.value }).then(res => {
          setTimeout(() => { 
            if (scrollScreen.value) {
              findStartlistRefetch({ index: firstIndexNotShown.value })
              firstIndexNotShown.value = 0
            }
          }, 20000)

        })
      }
    })

    watch(random, (newVal, prevVal) => {
      if (isShotGunStart.value == false)
        setTimeout(() => { findStartlistRefetch({ index: 0 }) }, startlistFound.value.length == 0 ? 10000: 20000)        
    })

    return {
      timeOutline, startlistFound, dayjs, tournamentName, tournamentDate, logo, showStartlist, firstIndexNotShown, scrollScreen, findStartlistRefetch, isShotGunStart, fontName
    }
  },
  mounted() {
    setTimeout(() => { 
      if (this.scrollScreen) {
        this.findStartlistRefetch({ index: this.firstIndexNotShown })
        this.firstIndexNotShown = 0
      }
 
     }, 20000)
  },
  methods: {
    visibilityChanged (isVisible, entry) {
      if (entry.target.id == -1) {
        this.scrollScreen = false
        return
      }
      if (!this.isShotGunStart) {
        this.scrollScreen = false
        return
      }
      if (entry.target.id >= this.firstIndexNotShown && isVisible == true) {
        this.firstIndexNotShown = parseInt(entry.target.id) + 1
      }
      if (this.firstIndexNotShown == this.startlistFound.length) {
        this.scrollScreen = false
      }
    }
  }
});
</script>

<style scoped>

.card {
  background-color: rgb(75, 59, 219); 
}

.minion { 
  font-family: "Minion, Arial" !important;
}

.playercard {
   height: auto;
}

.player {
   padding: 5px !important;
}

ion-col.rows {
   margin: 0px;
   padding: 0px;
   font-weight: bold;
   border-bottom: 1px solid grey;
   vertical-align: center;
}

ion-col.header {
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    vertical-align: center;
  }

ion-col.time {
    font-size: 24px; 
    font-weight: bold;
  }

.tournament {
    font-size: 24px; 
    font-weight: bold;
  }

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}
</style>