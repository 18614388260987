<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>News</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <h3 style="margin-left: 15px;" v-if="news && news.length <= 0">{{ $t('noNews') }}</h3>
      <ion-item lines="full">
        <ion-select interface="popover" slot="start" v-model="selectedCategory">
          <ion-select-option value="-1">{{ $t('all')}}</ion-select-option>
          <ion-select-option value="0">{{ $t('general')}}</ion-select-option>
          <ion-select-option value="1">{{ $t('competitions')}}</ion-select-option>
          <ion-select-option value="2">{{ $t('teetime')}}</ion-select-option>
          <ion-select-option value="3">{{ $t('bookings')}}</ion-select-option>
          <ion-select-option value="4">{{ $t('bills')}}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-card v-for="(item, index) in news" :key="index">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding"> 
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="newspaperOutline" :ios="newspaperOutline"></ion-icon>
            <ion-label class="noselect ion-text-wrap" color="primary">{{ dayjs(item.date).format('DD.MM.YYYY HH:mm') }}: {{ item.subject }}</ion-label>
            <ion-button v-if="item.categorie != 4" fill="none" @click="deleteMessage(item)" >
              <ion-icon style="zoom: 1.2" slot="end" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-card-header>
        <ion-card-content>
          <span v-html="item.message" class="comment ion-no-padding ion-no-margin" style="margin-left: 0px; white-space: pre-wrap;" ></span>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, 
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonCardContent, alertController } from '@ionic/vue';
import { defineComponent, watch, ref } from 'vue';
import { personOutline, calendarClearSharp, newspaperOutline, trashOutline } from 'ionicons/icons';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getNewsPersonQueryFile from '../graphql/getNewsPerson.query.gql'
import deletePersonNewsMutationFile from '../graphql/deletePersonNews.mutation.gql'
import * as dayjs from 'dayjs';
import translate from '@/plugins/translate';

require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')

export default defineComponent({
  name: 'News',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonCardContent,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null
   let selectedCategory = ref('-1')

    const { refetch: getNewsPersonRefetch, result: getNewsPersonResult, loading: getNewsPersonLoading } = useQuery(getNewsPersonQueryFile, () => ({ category: parseInt(selectedCategory.value) }), { fetchPolicy: 'no-cache' })
    const news = useResult(getNewsPersonResult, null, data => data.getNewsPerson.news)

    const { mutate: deletePersonNewsMutation } = useMutation(deletePersonNewsMutationFile, { fetchPolicy: 'no-cache' });

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getNewsPersonLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getNewsPersonLoading, (getNewsPersonLoading, prevLoading) => {
      if (!getNewsPersonLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getNewsPersonLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getNewsPersonLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    return {
      news, personOutline, calendarClearSharp, dayjs, newspaperOutline, selectedCategory, trashOutline, deletePersonNewsMutation, getNewsPersonRefetch
    }
  },
  methods: {
    deleteMessage(item) {
      alertController.create({
                  header: translate('deleteNews'),
                  message: translate('deleteThisNews') + ':' + item.subject,
                  buttons: [
                    {
                      text: translate('no'),
                    },
                    {
                      text: translate('yes'),
                      handler: () => {
                        this.deletePersonNewsMutation({ id: item.id }).then(res => {
                          toastController
                            .create({
                              message: this.$t('deleteSuccessful'),
                              duration: 2000,
                              color: 'warning'
                            }).then(res => res.present())
                          this.getNewsPersonRefetch()
                        })
                        .catch(err => {
                          toastController
                            .create({
                              message: this.$t('deleteNotSuccessful' + err),
                              duration: 2000,
                              color: 'danger',
                            }).then(res => res.present())
                        })
                      }
                    }
                    ] }).then(res => { res.present() })
    },
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>