<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Livescoring</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card v-if="livescoring" id="scorecard" >
        <ion-card-header>
          <ion-card-title>
            <ion-item class="ion-no-padding">
              <h3>{{ livescoring.name }} RPR</h3>
              <ion-button v-if="livescoring.allowRanking" @click="openRanking()" slot="end" class="ion-no-margin ion-no-padding" fill="clear">
                <ion-icon style="zoom: 1.4; vertical-align: top;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-card-title>

          <ion-label color="primary" class="ion-text-wrap" style="font-size: 18px; font-weight: bold; --margin-top: 0px;--padding: 0px;">{{ $t('player') }}: {{ livescoring.player.nameLong }}</ion-label><br>
          <ion-label class="ion-text-wrap" style="--margin-top: 0px;--padding: 0px;">{{ livescoring.player.course}}: {{ $t(livescoring.player.teegender) }} - <span :style='"background-color: lightgrey; color: rgba(" + livescoring.player.rgba[0] + "," + livescoring.player.rgba[1] + "," + livescoring.player.rgba[2] + " );"'>{{ livescoring.player.teename }}</span>, Par: {{livescoring.player.par}}, CR: {{livescoring.player.cr}} Slope: {{livescoring.player.slope}}</ion-label><br><br>

          <ion-label color="secondary" class="ion-text-wrap" style="font-size: 18px; font-weight: bold; --margin-top: 0px;--padding: 0px;">{{ $t('marker') }}: {{ livescoring.marker.nameLong }}</ion-label><br>

        </ion-card-header>
        <ion-card-content>
          <ion-grid v-if="livescoring.scoring_mode != 3">
            <ion-row>
              <ion-col class="ion-no-padding text-center numcol bordertop">#</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">Par</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">//</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol bordertop">{{ $t('player') }}</ion-col>
              <ion-col class="ion-no-padding text-center gbecol bordertop">GBE</ion-col>
              <ion-col class="ion-no-padding text-center scorecol bordertop borderright">{{ $t('marker') }}</ion-col>
            </ion-row>
            <ion-row v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol"><ion-label>{{index+1}}</ion-label></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                  <ion-button :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index] == true" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index);livescoring.scoresPlayerSaved[index] = false;" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index] == true" @click="livescoring.scoresPlayer[index] = livescoring.pars[index];livescoring.scoresPlayerSaved[index] = false;" fill="outline" :color="livescoring.scoresPlayerSaved[index] ? 'success' : livescoring.scoresPlayer[index] != 0 ? 'warning' : 'primary'" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index) }}
                    </ion-label>
                  </ion-button>

                  <ion-button @click="livescoring.scoresPlayer[index]++;livescoring.scoresPlayerSaved[index] = false;" :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index] == true" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>
                  
                  <ion-button v-if="livescoring.scoresPlayer[index] != 0 && livescoring.scoresPlayerSaved[index] == false" :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index] == true" @click="livescoring.scoresPlayerTrySave[index] = true;saveScore(index+1)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="success" :md="saveOutline" :ios="saveOutline">
                    </ion-icon>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol gbecolPaddingTop">{{ getGBE(livescoring.pars, livescoring.vorgabenPlayer, livescoring.scoresPlayer, index, livescoring.gbe) }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol  borderright">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresMarker, index);" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index] = livescoring.pars[index];" fill="outline" color="secondary" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresMarker, index) }}
                    </ion-label>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index]++;" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresMarker.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18" v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol">{{index+10}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index+9]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                  <ion-button :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index+9] == true" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9);livescoring.scoresPlayerSaved[index+9] = false;" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index+9] == true" @click="livescoring.scoresPlayer[index+9] = livescoring.pars[index+9];livescoring.scoresPlayerSaved[index+9] = false;" fill="outline" :color="livescoring.scoresPlayerSaved[index+9] ? 'success' : livescoring.scoresPlayer[index+9] != 0 ? 'warning' : 'primary'" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9) }}
                    </ion-label>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index+9] == true" @click="livescoring.scoresPlayer[index+9]++;livescoring.scoresPlayerSaved[index+9] = false;" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button v-if="livescoring.scoresPlayer[index+9] != 0 && livescoring.scoresPlayerSaved[index+9] == false" :disabled="scoreCardSigned || livescoring.scoresPlayerTrySave[index+9] == true" @click="livescoring.scoresPlayerTrySave[index+9] = true;saveScore(index+10)" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="success" :md="saveOutline" :ios="saveOutline">
                    </ion-icon>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol gbecolPaddingTop">{{ getGBE(livescoring.pars, livescoring.vorgabenPlayer, livescoring.scoresPlayer, index+9, livescoring.gbe) }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                  <ion-button :disabled="scoreCardSigned" @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresMarker, index+9);" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index+9] = livescoring.pars[index+9];" fill="outline" color="secondary" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresMarker, index+9) }}
                    </ion-label>
                  </ion-button>

                  <ion-button :disabled="scoreCardSigned" @click="livescoring.scoresMarker[index+9]++;" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="secondary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>

              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx >= 9 ).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresMarker.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.scoring_mode == 1 ? '' : livescoring.pars.reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol borderright">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresMarker.reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button v-if="livescoring.scoring_mode != 3" @click="read()">{{ $t('read') }}</ion-button><br>
          <ion-label v-if="livescoring.player.numHoles == livescoring.scoresPlayerSaved.filter(f => f == true).length && saveCount == saveCountDone"><strong>{{ $t('signaturePlayer') }}</strong></ion-label>
          <Signature v-if="livescoring.player.numHoles == livescoring.scoresPlayerSaved.filter(f => f == true).length && !livescoring.isSigned && saveCount == saveCountDone" :isConfirmed="isConfirmedPlayerPad" @saveSignature="saveSignaturePlayer" @clearSignature="livescoring.signaturePlayer = ''" v-model="livescoring.signaturePlayer" />
          <ion-img v-if="livescoring.isSigned" :src="livescoring.signaturePlayer" style="width: 300px; height: 150px;"></ion-img>
          <ion-label v-if="livescoring.player.numHoles == livescoring.scoresPlayerSaved.filter(f => f == true).length && saveCount == saveCountDone"><strong>{{ $t('signatureMarker') }}</strong></ion-label>
          <Signature v-if="livescoring.player.numHoles == livescoring.scoresPlayerSaved.filter(f => f == true).length && !livescoring.isSigned && saveCount == saveCountDone" :isConfirmed="isConfirmedMarkerPad" @saveSignature="saveSignatureMarker" v-model="livescoring.signatureMarker"/>
          <ion-img v-if="livescoring.isSigned" :src="livescoring.signatureMarker" style="width: 300px; height: 150px;"></ion-img>
          <ion-label v-if="saveCount != saveCountDone" color="danger"><strong>{{ $t('noInternet') }}</strong></ion-label>
          <ion-label v-if="!livescoring.isSigned && livescoring.player.numHoles != livescoring.scoresPlayerSaved.filter(f => f == true).length" color="danger"><strong>{{ $t('notAllScoresSaved') }}</strong></ion-label>

          <ion-grid v-if="livescoring.scoring_mode != 3" class="ion-no-padding" style="margin-top: 15px;">
            <ion-row style="align-items: center;">
              <ion-col size="auto">
                <ion-button :disabled="true" fill="clear" class="ion-no-padding sizebig" >
                  <ion-icon class="sizebig" color="success" :md="saveOutline" :ios="saveOutline">
                  </ion-icon>
                </ion-button>
              </ion-col>
              <ion-col size="10">
                <ion-label  class="ion-text-wrap" style="font-size: medium; font-weight: bold">{{ $t('digitalScorecardLegend1')}}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row style="align-items: center;">
              <ion-col size="auto">
                <ion-button :disabled="true" fill="outline" color="primary" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;"></ion-label>
                  </ion-button>
              </ion-col>
              <ion-col size="10">
                <ion-label  class="ion-text-wrap" style="font-size: medium; font-weight: bold;">{{ $t('digitalScorecardLegend2') }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row style="align-items: center;">
              <ion-col size="auto">
                <ion-button :disabled="true" fill="outline" color="warning" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;"></ion-label>
                  </ion-button>
              </ion-col>
              <ion-col size="10">
                <ion-label  class="ion-text-wrap" style="font-size: medium; font-weight: bold;">{{ $t('digitalScorecardLegend3') }}</ion-label>
              </ion-col>
            </ion-row>
            <ion-row style="align-items: center;">
              <ion-col size="auto">
                <ion-button :disabled="true" fill="outline" color="success" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;"></ion-label>
                  </ion-button>
              </ion-col>
              <ion-col size="10">
                <ion-label class="ion-text-wrap" style="font-size: medium; font-weight: bold;">{{ $t('digitalScorecardLegend4') }}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>

        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>

import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardTitle,
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, IonBadge, loadingController, IonChip,
        toastController, IonImg, modalController, alertController } from '@ionic/vue';
import { defineComponent, ref, watch, computed } from 'vue';
import { personOutline, calendarClearSharp, createOutline, removeCircle, addCircle, arrowDownCircleOutline, arrowUpCircleOutline, openOutline, saveOutline } from 'ionicons/icons';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getLivescoringRPRQueryFile from '../graphql/getLivescoringRPR.query.gql'
import saveScoreRPRMutationFile from '../graphql/saveScoreRPR.mutation.gql'
import saveScorecardRPRMutationFile from '../graphql/saveScorecardRPR.mutation.gql'
import * as dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
import Signature from "./SignaturePad"
import { isPlatform } from '@ionic/vue';
import translate from '@/plugins/translate'
import domtoimage from "dom-to-image-more"
import { Geolocation } from '@capacitor/geolocation';
import ShowLivescoringResultDialog from './dialogs/ShowLivescoringResultDialog'
import { ScreenOrientation } from '@ionic-native/screen-orientation'

export default defineComponent({
  name: 'Livescorings',
  components: {
    IonContent,
    IonHeader, Signature, IonImg,
    IonPage,
    IonTitle, 
    IonToolbar, IonCardContent, IonCardTitle, IonBadge, IonChip,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
    let loadingSpinner = null
    const router = useRouter()
    const route = useRoute()
    const isConfirmedPlayer = ref(false)
    const isConfirmedMarker = ref(false)
    const printMe = ref(null);
    let saveCount = ref(0)
    let saveCountDone = ref(0)

    const { result: getLivescoringResult, loading: getLivescoringLoading } = useQuery(getLivescoringRPRQueryFile, () => ({ id: route.path.startsWith('/livescoringrpr/') ? parseInt(route.params.id) : 0  }), () => ({ fetchPolicy: 'no-cache', enabled: route.params.id != null && route.path.startsWith('/livescoringrpr/') }))
    const livescoring = useResult(getLivescoringResult, null, data => data.getLivescoringRPR.livescoring)

    const { mutate: saveScoreMutation } = useMutation(saveScoreRPRMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: saveScorecardMutation } = useMutation(saveScorecardRPRMutationFile, { fetchPolicy: 'no-cache' });

    const isConfirmedPlayerPad = computed(() => isConfirmedPlayer.value || livescoring.value.isSigned ) 
    const isConfirmedMarkerPad = computed(() => isConfirmedMarker.value || livescoring.value.isSigned ) 

    const saveSignatureMarker = (signature) => {
      if (livescoring.value.scoring_mode != 3 && livescoring.value.scoresPlayerSaved.slice(0, livescoring.value.player.numHoles).filter(f => f == false).length > 0) {
        toastController
          .create({
            message: translate('missingScores'),
            duration: 5000,
            color: 'warning'
          }).then(res => res.present())
          return
      }
      if (signature == 'data:,')
       return
       
      livescoring.value.signatureMarker = signature
      isConfirmedMarker.value = true

    }

    const saveSignaturePlayer = async (signature) => {
      if (livescoring.value.scoring_mode != 3 && livescoring.value.scoresPlayerSaved.slice(0, livescoring.value.player.numHoles).filter(f => f == false).length > 0) {
        toastController
          .create({
            message: translate('missingScores'),
            duration: 5000,
            color: 'warning'
          }).then(res => res.present())
          return
      }
      if (signature == 'data:,')
       return

      livescoring.value.signaturePlayer = signature
      isConfirmedPlayer.value = true
    }
    const saveScore = (hole) => {
      Geolocation.getCurrentPosition().then(res => {
        saveCount.value++
        saveScoreMutation({ roundPlayerId: parseInt(livescoring.value.player.roundPlayerId), hole: parseInt(hole), score: parseInt(livescoring.value.scoresPlayer[hole-1]), latitude: res.coords.latitude, longitude: res.coords.longitude, date: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS') }).then(res => { 
          livescoring.value.scoresPlayerSaved[hole-1] = true
          livescoring.value.scoresPlayerTrySave[hole-1] = false
          saveCountDone.value++ 
        })
      }).catch(err => {
        saveCount.value++
        saveScoreMutation({ roundPlayerId: parseInt(livescoring.value.player.roundPlayerId), hole: parseInt(hole), score: parseInt(livescoring.value.scoresPlayer[hole-1]), date:  dayjs().format('YYYY-MM-DD HH:mm:ss.SSS')  }).then(res => { 
          livescoring.value.scoresPlayerSaved[hole-1] = true
          livescoring.value.scoresPlayerTrySave[hole-1] = false
          saveCountDone.value++ 
        })
      })

    }
    const decreaseScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return
      else if (scoring_mode != 1 && scores[index] == 0)
        return
      else
        scores[index]--
    }

    const showScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return '-'
      else if (scores[index] == 0)
        return ''
      else
        return scores[index]
    }

    watch([isConfirmedPlayer, isConfirmedMarker], ([player, marker], [prevPlayer, prevMarker]) => {
      if (isConfirmedPlayer.value && isConfirmedMarker.value) {
        let netto = []
        let gbestatus = []
        livescoring.value.vorgabenPlayer.forEach(function (value, i) {
          netto.push(livescoring.value.scoresPlayer[i] > 0 ? livescoring.value.scoresPlayer[i] - value.length : 0)
          gbestatus.push(livescoring.value.gbe[i] < livescoring.value.scoresPlayer[i] ? 1 : 0 )
        })

        domtoimage
          .toJpeg(document.getElementById("scorecard"), { quality: 0.5 })
          .then(function (dataUrl) {
            saveScorecardMutation({ roundPlayerId: parseInt(livescoring.value.player.roundPlayerId), signaturePlayer: livescoring.value.signaturePlayer, signatureMarker: livescoring.value.signatureMarker, scorecard: dataUrl, gbe: livescoring.value.gbe, netto: netto, gbestatus: gbestatus }).then(res => {
              if (res.data.saveScorecardRPR.success == true) {
                toastController
                  .create({
                    message: translate('saveRPRRound'),
                    duration: 5000,
                    color: 'success'
                  }).then(res => res.present())
                }
              else {
                isConfirmedPlayer.value = false
                toastController
                  .create({
                    message: translate('saveRPRError'),
                    duration: 5000,
                    color: 'danger'
                  }).then(res => res.present())
              }
            })
          })
          .catch(function (error) {
            isConfirmedPlayer.value = false
            toastController
                  .create({
                    message: translate('saveRPRError') + error,
                    duration: 5000,
                    color: 'danger'
                  }).then(res => res.present())
          })
      }
      
    })

    
    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(async ()  => { 
        if (!getLivescoringLoading.value && loadingSpinner != null) {
          loadingSpinner.dismiss() 
          loadingSpinner = null
        }
      })
    }).catch(err => { })

    watch(getLivescoringLoading, async (newLoading, prevLoading)=> {
      if (!newLoading && loadingSpinner != null) {
          loadingSpinner.dismiss()
          loadingSpinner = null
          return
      }
      else if (newLoading) {
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!getLivescoringLoading.value && loadingSpinner) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                }
              })
            }).catch(err => { })
      }
    })

    if (isPlatform('capacitor'))
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE)
//      console.log("🚀 ~ file: LivescoringRPR.vue:431 ~ setup ~ ScreenOrientation:", ScreenOrientation.type)

    /*if (isPlatform('capacitor') && isPlatform('ios') && ScreenOrientation.type != 'landscape-primary') {
      const alert =  alertController
        .create({
          header: translate('livescorings'),
          message: translate('useLandscape'),
          buttons: [
            {
              text: translate('ok') ,
              handler: async () => {
              },
            },
          ],
        }).then(res => {
          res.present()
        })
    }*/

    watch(
      () => route.params.id,
      (id, prevId) => {
        if (id == undefined && prevId > 0 && isPlatform('capacitor'))
          ScreenOrientation.unlock()
        else if (route.path.startsWith('/livescoringrpr/') && id != undefined && isPlatform('capacitor')) {
          ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE)
          /*if (isPlatform('capacitor') && isPlatform('ios') && ScreenOrientation.type != 'landscape-primary') {
            const alert =  alertController
              .create({
                header: translate('livescorings'),
                message: translate('useLandscape'),
                buttons: [
                  {
                    text: translate('ok') ,
                    handler: async () => {
                    },
                  },
                ],
              }).then(res => {
                res.present()
              })
          }*/
        }
      }
    )

    const getGBE = (pars, vorgaben, scores, index, gbe) => {
      if (scores[index] == -1 || scores[index] > 0) {
        const nettoDB = pars[index] + vorgaben[index].length + 2
        if (nettoDB > scores[index]) {
          gbe[index] = scores[index]
          return scores[index]
        }
        else {
          gbe[index] = nettoDB
          return nettoDB
        }
      }
      else if (scores[index] == 0) {
        gbe[index] = 0
        return 0
      }
    }


    return {
      livescoring, personOutline, calendarClearSharp, dayjs, createOutline, route,
      removeCircle, addCircle, saveSignatureMarker, saveSignaturePlayer, arrowDownCircleOutline, arrowUpCircleOutline, isConfirmedPlayer, isConfirmedMarker, saveScore, printMe, showScore, decreaseScore, isConfirmedPlayerPad, isConfirmedMarkerPad, openOutline, isPlatform, saveCount, saveCountDone, saveOutline, getGBE
    }
  },
  computed: {
      scoreCardSigned : function(){ return  this.livescoring.signaturePlayer != '' || this.livescoring.isSigned },
    },
  data () {
    return {
      synth: window.speechSynthesis,
    }
  },
  methods: {
    async openRanking() {
      const modal = await modalController
        .create({
          component: ShowLivescoringResultDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              livescoring: this.livescoring,
            }
          },
        })
      return modal.present();
    },
    speak(list, interval, count) {
      if (list.length) {
        var msg = new SpeechSynthesisUtterance();
        msg.text = list[0];

        this.synth.speak(msg);

        setTimeout(() => {
            this.speak(list.slice(1), (count % 3 == 0 ? 2000 : 300), count + 1)
        }, interval)
      }
    },
    read() {
      let list = []
      for (const score of this.livescoring.scoresPlayer) {
        if (score > 0)
          list.push(score)
      }
      this.speak(list, 300, -1)
    }
  }
});
</script>

<style scoped>

ion-col.gbecol {
    flex: 0 0 50px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    vertical-align: middle;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

  ion-col.gbecolPaddingTop {
    padding-top:10px;
  }

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

ion-col.numcol {
    flex: 0 0 25px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    vertical-align: center;
  }

ion-col.parindexcol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

ion-col.vorgabecol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }


ion-col.scorecol {
    flex: 0 0 150px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.matchplayscorecol {
    flex: 0 0 50px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.wincol {
    flex: 0 0 100px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

.sizebig {
  height: 45px;
  width: 45px;
  vertical-align: top;
}

.borderright {
    border-right: 1px solid grey;
}

.bordertop {
    border-top: 1px solid grey;
}

ion-button {
    --box-shadow: 0px 0px 2px 1px rgb(0, 0, 0, 0.25);
}

</style>