<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('aboutApp') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-label>Version: {{ currentPackageInfo }}</ion-label>
    <hr>
    <ion-label>{{ remotePackageInfo }}</ion-label>
    <hr>
    <ion-button :disabled="remotePackage == '' || remotePackageInfo == 'The application is up to date.'" style="margin-left: 30%;"  @click="codePushSync(false)"  color="success">{{ $t('updateApp') }}</ion-button>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent, 
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline } from 'ionicons/icons';

import { codePush, InstallMode  } from 'capacitor-codepush';

export default defineComponent({
  name: 'AboutAppDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonAvatar,IonCardContent
              },
  setup() {
    return { 
      closeOutline
    }
  },
  mounted() {
    this.getCurrentPackage()
    this.checkMobileAppUpdate()
  },
  data() {
    return{
      currentPackageInfo: '',
      remotePackageInfo: '',
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss()
    },
    getCurrentPackage(){
      try {        
        codePush.getCurrentPackage().then(this.onCurrentPackageCheck);
      } catch (error) {
        console.log('codePush error',error)
      }

    },
    onCurrentPackageCheck(update) {        
      if (!update) {
        this.currentPackageInfo = "No updates have been installed"
      }else{
        this.currentPackageInfo = update.label          
      }
    },
    checkMobileAppUpdate(){      
      try {        
        var onError = function (error) {
            console.log("An error occurred. " + error);            
            toastController.create({message: error,duration: 5000,color: 'danger'}).then(res => res.present())
        };
        codePush.checkForUpdate(this.onUpdateCheck, onError);
      } catch (error) {
        toastController.create({message: error,duration: 5000,color: 'danger'}).then(res => res.present())
      }
    },
    onUpdateCheck(remotePackage) {          
      if (!remotePackage) {
          this.remotePackageInfo = "The application is up to date.";
      } else {
          this.remotePackageInfo = "Please update application";
      }
    },
    codePushSync(ignoreFailedUpdates){
      try {      
        console.log('deviceready checkNewVersion', codePush, InstallMode)
        toastController.create({message: this.$t('appUpdating'),duration: 5000,color: 'success'}).then(res => res.present())          
        codePush.sync(
                  {
                    ignoreFailedUpdates: ignoreFailedUpdates,
                    installMode:InstallMode.IMMEDIATE,
                    mandatoryInstallMode: InstallMode.IMMEDIATE,
                    onSyncStatusChanged: (status) => {
                      console.log('codePush onSyncStatusChanged status',status)
                      
                      switch (status) {
                          case 0:
                              console.log('onSyncStatusChanged status UP_TO_DATE')
                              break;
                          case 1:
                              console.log('onSyncStatusChanged status UPDATE_INSTALLED')
                              codePush.notifyApplicationReady();
                              break;
                          case 2:
                              console.log('onSyncStatusChanged status UPDATE_IGNORED')
                              break;
                          case 3:
                              console.log('onSyncStatusChanged status ERROR')
                              break;
                          case 4:
                              console.log('onSyncStatusChanged status IN_PROGRESS')
                              break;
                          case 5:
                              console.log('onSyncStatusChanged status CHECKING_FOR_UPDATE')
                              break;
                          case 6:
                              console.log('onSyncStatusChanged status AWAITING_USER_ACTION')
                              break;
                          case 7:
                              console.log('onSyncStatusChanged status DOWNLOADING_PACKAGE')
                              break;
                          case 8:
                              console.log('onSyncStatusChanged status INSTALLING_UPDATE')
                              break;
                      }
                    },
                  },
                  (progress) => {
                    console.log(`codePush Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`);
                  }
                )
                .then((status) => {
                  //if (status == 1)
                    //codePush.notifyApplicationReady();
                  console.log('codePush status ',status)
                });
      } catch (error) {
        console.log('codePush error',error)
      }

    },
  }
});
</script>