//import { Plugins } from "@capacitor/core";
//const { Storage } = Plugins;
import { Preferences } from '@capacitor/preferences';

async function setItem(key, value) {
  await Preferences.set({
    key: key,
    value: value
  });
}

async function getItem(key) {
  const item = await Preferences.get({ key: key });
  return item.value;
}

async function removeItem(key) {
  await Preferences.remove({
    key: key
  });
}

export default {
  setItem,
  getItem,
  removeItem
}