<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title slot="start">{{ $t('login') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <div v-if="errorMessage != ''">
        <ion-label v-if="emailMask != ''" class="ion-text-wrap" color="danger">{{ $t(errorMessage) }} ({{ $t(emailMask) }})</ion-label>
        <ion-label v-else class="ion-text-wrap" color="danger">{{ $t(errorMessage) }}</ion-label>
        <!-- <ion-input v-if="errorMessage == 'noEmail'" :placeholder="$t('email')" type="email" v-model="connectEmail"></ion-input>-->
      </div>
        <ion-button position="stacked" v-if="connectEmail && connectEmail != '' && connectEmail.includes('@') && connectEmail.includes('.')" @click="requestEmailVerification(connectEmail)" expand="block" color="primary">{{ $t('sendActivationLink') }}</ion-button>
        <ion-button position="stacked" v-if="errorMessage == 'notActivated'" @click="requestEmailVerification(emailClub)" expand="block" color="primary">{{ $t('sendActivationLink') }}</ion-button>
        
      <div v-if="canResetEmailByMobile">
        <ion-card>
          <ion-card-content>            
            <ion-item lines="none"> 
              <ion-label>{{ $t('countryCallingCode') }}</ion-label>
              <ion-select :disabled="sentMobileCode" v-model="countryCallingCode"> 
                <ion-select-option :value="'+43'">Austria (+43) </ion-select-option>
                <ion-select-option :value="'+420'">Czech Republic (+420) </ion-select-option>
                <ion-select-option :value="'+49'">Germany (+49) </ion-select-option>
                <ion-select-option :value="'+41'">Switzerland (+41) </ion-select-option>
              </ion-select>
            </ion-item> 
            <ion-input  :disabled="sentMobileCode" :placeholder="$t('mobileNoZero')" type="tel" v-model="mobileNumber"></ion-input>
            
            <section>
              <vue-recaptcha 
                :sitekey="siteKey"
                @verify="onVerifyRecaptcha"
                @expired="onExpiredRecaptcha"
              >
              </vue-recaptcha>   
              <ion-button :disabled="this.recaptchaResponse=='' || mobileNumber == '' || sentMobileCode==true" @click="requestMobileCode()" color="success">{{ $t('getMobileCode') }}</ion-button>
            
            </section>

            <br>
            <ion-label v-if="sentMobileCode" class="ion-text-wrap" color="danger">{{ $t('sentMobileCode') }}</ion-label>
            <ion-input :placeholder="$t('1234')" type="number" v-model="mobileCode"></ion-input>
            <ion-input :placeholder="$t('email')" type="email" v-model="newEmail"></ion-input>
            <ion-button :disabled="sentMobileCode==false || setNewEmailDone==true" @click="setEmailByMobileCode()" color="success">{{ $t('setNewEmail') }}</ion-button>
            <br>
            <ion-label v-if="setNewEmailDone" class="ion-text-wrap" color="danger">{{ $t('setNewEmailDone') }}</ion-label>
          </ion-card-content>
        </ion-card>
      </div>
      
      <ion-item>
        <ion-label class="ion-text-wrap" position="stacked">{{ $t('username') }} ({{ $t('noBlanks') }})</ion-label><br>
        <ion-input :disabled="canResetEmailByMobile" placeholder="9008000001" type="email" ref="focusMe" v-model="email"></ion-input>
      </ion-item>
        <ion-item>
        <ion-label position="stacked">{{ $t('password') }}</ion-label>
        <ion-item class="ion-no-padding" style="margin-top: 10px;margin-left: 0px;">
          <ion-input  :disabled="canResetEmailByMobile" placeholder="" :type="showPassword ? 'text' : 'password'" v-model="password"></ion-input>
          <ion-icon style="font-size: 25px;" @click="showPassword = !showPassword" :ios="showPassword ? eyeOffOutline : eyeOutline" :md="showPassword ? eyeOffOutline : eyeOutline" class="ion-float-right"></ion-icon>
          <ion-icon color="success" v-if="useTouch" style="font-size: 25px;margin-left: 10px;" @click="useBiometric()" :ios="fingerPrintOutline" :md="fingerPrintOutline" class="ion-float-right"></ion-icon>
          <ion-icon color="success" v-if="useFaceID" style="font-size: 25px;margin-left: 10px;" @click="useBiometric()" :src="faceID" class="ion-float-right"></ion-icon>
        </ion-item>
      </ion-item>
      <ion-button @click="loginUser()" expand="block" color="success">{{ $t('login') }}</ion-button>
      <ion-label v-if="showEmailForVerification" class="ion-text-wrap" color="danger">{{ $t('enterEmail') }}</ion-label>
      <ion-input v-if="showEmailForVerification" :placeholder="verificationEmailStars" type="email" v-model="verificationEmail"></ion-input>
      <ion-button v-if="email != ''" @click="requestPasswordReset()" expand="block" color="primary">{{ $t('passwordReset') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, eyeOutline, eyeOffOutline, cameraOutline, fingerPrintOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import loginUserMutationFile from '../graphql/loginUser.mutation.gql'
import requestPasswordResetMutationFile from '../graphql/requestPasswordReset.mutation.gql'
import requestPasswordResetVerificationEmailMutationFile from '../graphql/requestPasswordResetVerificationEmail.mutation.gql'
import requestEmailVerificationMutationFile from '../graphql/requestEmailVerification.mutation.gql'
import requestMobileCodeMutationFile from '../graphql/requestMobileCode.mutation.gql'
import setEmailByMobileCodeMutationFile from '../graphql/setEmailByMobileCode.mutation.gql'
import Storage from "../plugins/storage.js";
import { VueRecaptcha } from 'vue-recaptcha';
import { BiometricAuth, BiometryType, BiometryErrorType, BiometryError } from '@aparajita/capacitor-biometric-auth'
import { SecureStorage } from '@aparajita/capacitor-secure-storage'

export default defineComponent({
  name: 'LoginDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow,
                VueRecaptcha },
  data() {    
    var siteKey = '6LdApusiAAAAAMYQrG8ATY5E7A5wmdjNqV3Wu69z'

    return {
      siteKey,
      faceID: require('@/assets/images/face_id.svg'),
      recaptchaResponse: '',
      email: '',
      password: ''
    }
  },
  setup() {
    const errorMessage = ref('')
    const emailMask = ref('')
    const emailClub = ref('')
    const connectEmail = ref('')
    const id = ref(0)
    const canResetEmailByMobile = ref(false)
    const sentMobileCode = ref(false)
    const countryCallingCode = ref('+49')
    const mobileNumber = ref('')
    const mobileCode = ref()
    const newEmail = ref('')
    const setNewEmailDone = ref(false)
    const showEmailForVerification = ref(false)
    const verificationEmailStars = ref('')
    const verificationEmail = ref('')
    const showPassword = ref(false)
    const useTouch = ref(false)
    const useFaceID = ref(false)

    const { mutate: loginUserMutation } = useMutation(loginUserMutationFile, { errorPolicy: 'all'});
    const { mutate: requestPasswordResetMutation } = useMutation(requestPasswordResetMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: requestPasswordResetVerificationEmailMutation } = useMutation(requestPasswordResetVerificationEmailMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: requestEmailVerificationMutation } = useMutation(requestEmailVerificationMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: requestMobileCodeMutation } = useMutation(requestMobileCodeMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: setEmailByMobileCodeMutation } = useMutation(setEmailByMobileCodeMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline, showEmailForVerification, verificationEmailStars, verificationEmail,
      loginUserMutation, requestEmailVerificationMutation, cameraOutline, fingerPrintOutline,
      requestPasswordResetMutation, errorMessage, emailMask, emailClub, id, connectEmail,
      canResetEmailByMobile,sentMobileCode, showPassword, eyeOutline, eyeOffOutline,
      countryCallingCode,mobileNumber,mobileCode,newEmail,setNewEmailDone, useTouch, useFaceID,
      requestMobileCodeMutation,setEmailByMobileCodeMutation, requestPasswordResetVerificationEmailMutation
    }
  },
  async mounted() {
      const username = await SecureStorage.get('4Uusername', true, false)
      const password = await SecureStorage.get('4Uusername', true, false)

      //await BiometricAuth.setBiometryType(BiometryType.faceId)
      const info = await BiometricAuth.checkBiometry()
      //console.log("🚀 ~ file: Login.vue:161 ~ mounted ~ info:", info)

      this.useTouch = username && password && info.isAvailable && info.biometryType == 1
      this.useFaceID = username && password && info.isAvailable && info.biometryType == 2

//      console.log("🚀 ~ file: Login.vue:160 ~ mounted ~ info:", info)

    setTimeout(() => {
        this.$refs.focusMe.$el.setFocus();
      }, 500);
  },
  methods: {
    async useBiometric() {
      try {
        await BiometricAuth.authenticate({
          reason: this.$t('authenticate'),
          cancelTitle: this.$t('cancel'),
          allowDeviceCredential: true,
          iosFallbackTitle: this.$t('usepasscode'),
          androidTitle: this.$t('biometriclogin'),
          androidSubtitle: this.$t('biometricloginsubtitle'),
          androidConfirmationRequired: false,
          })
          this.email = await SecureStorage.get('4Uusername', true, false)
          this.password = await SecureStorage.get('4Upassword', true, false)
          this.loginUser()

      } catch (error) {
        // error is always an instance of BiometryError.
        if (error instanceof BiometryError) {
          if (error.code !== BiometryErrorType.userCancel) {
            // Display the error.
          }
        }
      }      
    },
    closeDialog(value) {
      return modalController.dismiss(value)
    },
    async sendEmailActivation () {

    },
    async loginUser() {
      await this.loginUserMutation({ email: this.email, password: this.password })
      .then(async res => {
        if (res.data.loginUser) {
          if (!res.data.loginUser.success && res.data.loginUser.errorCode == 1) {
            this.errorMessage = 'noEmail'
            this.id = res.data.loginUser.id
          }
          else if (!res.data.loginUser.success && res.data.loginUser.errorCode == 2) {
            this.errorMessage = 'notActivated'
            this.emailMask = res.data.loginUser.emailmask
            this.emailClub = res.data.loginUser.email
            this.id = res.data.loginUser.id
          }
          else if (!res.data.loginUser.success && res.data.loginUser.errorCode == 3) {
            this.errorMessage = 'noEmailSMSLogIn'
            this.canResetEmailByMobile = true
            this.id = res.data.loginUser.id
            console.log(res.data.loginUser)
          }
          else {
            await Storage.setItem('accessToken', res.data.loginUser.accessToken);
            await Storage.setItem('refreshToken', res.data.loginUser.refreshToken);

            if (this.useTouch || this.useFaceID) {
              await SecureStorage.set('4Uusername', this.email, true, false)
              await SecureStorage.set('4Upassword', this.password, true, false)
          }
            this.$store.dispatch('login')
            this.closeDialog(true)
          }
        }
      })
      .catch(err => {
        console.log(err)
      }) 
    },
    requestPasswordReset() {
      if (this.verificationEmail == '') {
        this.requestPasswordResetVerificationEmailMutation({email: this.email })
        .then(res => {
          if (res && res.data && res.data.requestPasswordResetVerificationEmail && res.data.requestPasswordResetVerificationEmail.success) {
            this.verificationEmailStars = res.data.requestPasswordResetVerificationEmail.addInfo
            this.showEmailForVerification = true
          }
          else {
            toastController
              .create({
                message: this.$t('notSuccessful'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
          }
        })
        .catch(err => {
            toastController
              .create({
                message: this.$t('notSuccessful'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
        }) 
      }
      else {
          this.requestPasswordResetMutation({verificationEmail: this.verificationEmail, email: this.email, language: this.$store.getters.language })
        .then(res => {
          if (res && res.data && res.data.requestPasswordReset && res.data.requestPasswordReset.success) {
            this.verificationEmailStars = ''
            this.showEmailForVerification = false
            toastController
              .create({
                message: this.$t('emailSendOK'),
                duration: 3000,
                color: 'success'
              }).then(res => res.present())
          }
          else {
            toastController
              .create({
                message: this.$t('emailSendError'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
          }
        })
        .catch(err => {
            toastController
              .create({
                message: this.$t('emailSendError'),
                duration: 3000,
                color: 'danger'
              }).then(res => res.present())
        }) 
      }
    },
    requestEmailVerification(email) {
      this.requestEmailVerificationMutation({ id: this.id, email: email, language: this.$store.getters.language })
      .then(res => {
        if (res && res.data && res.data.requestEmailVerification && res.data.requestEmailVerification.success) {
          this.connectEmail = ''
          this.errorMessage = ''
          toastController
            .create({
              message: this.$t('emailSendOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
        }
        else {
          toastController
            .create({
              message: this.$t('emailSendError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
        toastController
          .create({
            message: this.$t('emailSendOK'),
            duration: 3000,
            color: 'success'
          }).then(res => res.present())
      }) 
    },
    onVerifyRecaptcha: function (response) {
      this.recaptchaResponse = response;
    },
    onExpiredRecaptcha: function () {
      this.recaptchaResponse = ''
    },
    requestMobileCode() {
      this.requestMobileCodeMutation({recaptchaResponse: this.recaptchaResponse, mobileNumber: this.countryCallingCode + this.mobileNumber, teetimeuserid: this.email, password: this.password  })
      .then(res => {
        if (res && res.data && res.data.requestMobileCode && res.data.requestMobileCode.success) {
          this.sentMobileCode = true
          toastController
            .create({
              message: this.$t('mobileCondeSendOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
        }
        else {
          toastController
            .create({
              message: this.$t('mobileCondeSendError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('mobileCondeSendError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
    setEmailByMobileCode() {
      this.setEmailByMobileCodeMutation({ mobileNumber: this.countryCallingCode + this.mobileNumber,mobileCode: parseInt(this.mobileCode), email: this.newEmail, language: this.$store.getters.language})
      .then(res => {
        if (res && res.data && res.data.setEmailByMobileCode && res.data.setEmailByMobileCode.success) {
          this.setNewEmailDone = true
          toastController
            .create({
              message: this.$t('setEmailByMobileCodeOK'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
        }
        else {
          toastController
            .create({
              message: this.$t('setEmailByMobileCodeError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('setEmailByMobileCodeError'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
  }
});
</script>