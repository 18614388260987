<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-button fill="none" @click="generatePDF">
            <ion-icon color="primary" :ios="printOutline" :md="printOutline" ></ion-icon>
          </ion-button>      
        <ion-title>{{ $t('helpRoundSL') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="selectedTournament">
          {{ selectedTournament.name }}
        </ion-card-title>
        <ion-card-title :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="selectedRound">
          {{ $t('round') }} {{ selectedRound.round_index }}: {{ dayjs(selectedRound.start).format('DD.MM.YYYY') }}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-list v-for="(item, index) in startList" :key="index" :value="item">
          <ion-list-header :style="isPlatform('mobile') ? 'padding: 0px; margin: 0px; margin-top: 5px; font-size: 18px !important;': 'padding: 0px; margin: 0px; margin-top: 5px; font-size: 20px !important;'">
            Tee {{ item.tee }}: {{ dayjs(item.date).tz().format('HH:mm') }}
          </ion-list-header>
          <ion-item v-for="(player, index) in item.team" :key="index" :value="player" :lines="(player.drawLine ? 'full' : 'none')">
            <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 0.8; margin-right: 5px;"></ion-icon>
            <ion-label style="padding-left: 5px; margin: 0px; margin-top: 5px;" class="ion-text-wrap">{{ player.lastName }} {{ player.firstName }} {{player.hcp}}</ion-label>
            <ion-avatar @contextmenu.prevent="" :class="imgClass" @click="changeImg()" v-if="player.avatarLink != '' && player.showAvatar">
              <img :src="player.avatarLink">
            </ion-avatar>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonCardContent,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonListHeader,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform,
IonAvatar } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, manSharp, womanSharp, personOutline, printOutline } from 'ionicons/icons';
import { useQuery, useResult } from '@vue/apollo-composable'
import getStartListByRoundIdQueryFile from '../../graphql/getStartListByRoundId.query.gql'
import * as dayjs from 'dayjs'; 
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { File } from '@awesome-cordova-plugins/file';
import { FileOpener } from '@awesome-cordova-plugins/file-opener';

export default defineComponent({
  name: 'ShowStartListDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let selectedRound = ref(null)
    let selectedTournament = ref(null)
    const imgClass = ref('playerImg')

    const { result: getStartListByRoundIdResult } = useQuery(getStartListByRoundIdQueryFile, () => ({ id: (selectedRound.value ? parseInt(selectedRound.value.id) : 0) }), { fetchPolicy: 'no-cache' })
    let startList = useResult(getStartListByRoundIdResult, null, data => data.getStartListByRoundId.startList)
    return { dayjs, closeOutline, selectedRound, startList, manSharp, womanSharp, personOutline, selectedTournament, imgClass,
             isPlatform, printOutline
    }
  },
  mounted() {
    this.selectedRound = this.propsData.selectedRound
    this.selectedTournament = this.propsData.selectedTournament
  },
  data() {
    var listDetail = []
    var listHeader = []
    return {  
      listDetail, listHeader
    }
  },
  methods: {
    generatePDF(){   
      this.listDetail = []

      this.startList.forEach(item => {
        this.listDetail.push([{text: 'Tee ' + item.tee +': ' + dayjs(item.date).tz().format('HH:mm'), fontSize: 12, bold: true}])
        item.team.forEach(player => {
            this.listDetail.push([{ text: player.lastName + ' ' + player.firstName + ' ' + player.hcp, fontSize: 10}])
            if (player.drawLine)
              this.listDetail.push([{canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 0.5 } ]}])
          }
        )
        //this.listDetail.push([{canvas: [ { type: 'line', x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 } ]}])
        this.listDetail.push([{text: '\n'}])
      }); 
      this.listHeader = []
      this.listHeader.push({ text: this.$t('name') })

      const docDefinition = {
        footer: function(currentPage, pageCount, pageSize) {
            return [
              { text: dayjs().format('DD.MM.YYYY HH:mm') + ' ' +currentPage+'/'+pageCount, alignment: 'right', fontSize: 10, margin: [0, 0, 20, 0] },
            ]
          },
        content: [
            {text: this.$t('helpTournamentSL'), fontSize: 14, bold: true},
            '\n',
            {text: this.selectedTournament.name + ' ' + dayjs(this.selectedTournament.begin).format('DD.MM.YYYY HH:mm'), fontSize: 12, bold: true},
            {text: this.$t('round') + ' ' + this.selectedRound.round_index + ': ' + dayjs(this.selectedRound.start).format('DD.MM.YYYY'), fontSize: 12, bold: true},
            '\n',  
            {
                style: 'tableItems',
                table: {
                    headerRows: 0,
                    body: this.listDetail
                },
                layout: 'headerLineOnly'
            },
        ],
        styles: {
            footer: {
                fontSize: 8,
            },
            tableItems: {
                fontSize: 8
            }
        },
        defaultStyle: {
            columnGap: 20
        }        
      }

      const pdfDocGenerator = pdfMake.createPdf(docDefinition)
      if (isPlatform('capacitor'))
        pdfDocGenerator.getBuffer((buffer) => {
          var blob = new Blob([buffer], { type: 'application/pdf' });
          File.writeFile(File.dataDirectory, 'sl.pdf', blob, { replace: true }).then(fileEntry => {
          // Open the PDf with the correct OS tools
          FileOpener.open(File.dataDirectory + 'sl.pdf', 'application/pdf');
          })
        })
      else
        pdfDocGenerator.open()
    },
    changeImg() {
      if (this.imgClass == 'playerImg')
        this.imgClass = 'playerImgBig'
      else
        this.imgClass = 'playerImg'
    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>

<style scoped>

.playerImg {
  width: 40px; 
  height: 40px;
}

.playerImgBig {
  width: 120px; 
  height:120px
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

ion-item {
  --ion-background-color: white !important;
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-list {
  border-bottom: 0.55px solid #c8c7cc;
}

ion-list:first-of-type {
  border-top: 0.55px solid #c8c7cc;
}
section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>