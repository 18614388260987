<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Livescorings</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card v-if="isLoggedIn && clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canRPR">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-label class="noselect ion-text-wrap" >{{ $t('RPRs') }}</ion-label>
            <ion-button :disabled="rprs && rprs.filter(f => dayjs(f.date).format('YYYYMMDD') == dayjs().format('YYYYMMDD')).length>1" fill="clear" @click="newRPR()">
            <ion-icon style="zoom: 1.4" slot="end" color="success" :md="addCircleOutline" :ios="addCircleOutline"></ion-icon>
            </ion-button>
          </ion-item>
          <h3 style="margin: 15px;" v-if="rprs && rprs.length <= 0">{{ $t('noRPRs') }}</h3>
          <ion-item v-for="(rpr, index) in rprs" :key="index" lines="full" class="ion-no-padding" router-direction="root" :router-link="rpr.canClick ? ('/livescoringrpr/' + rpr.id) : '/livescorings'">
            <ion-icon v-if="rpr.status == 1" style="zoom: 1.0" slot="start" color="success" :md="checkmarkDoneOutline" :ios="checkmarkDoneOutline"></ion-icon>
            <ion-icon v-if="rpr.status == 0" style="zoom: 1.0" slot="start" color="primary" :md="createOutline" :ios="createOutline"></ion-icon>
            <ion-label :class="(rpr.canClick ? 'clickable ' : '')  + 'ion-text-wrap'" color="primary">{{ dayjs(rpr.date).format('DD.MM.YYYY HH:mm') }}: {{ rpr.comment }}</ion-label>
          </ion-item>
        </ion-card-header>
      </ion-card>

      <ion-card v-if="isLoggedIn">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-label class="noselect ion-text-wrap" >{{ $t('digitalScorecard') }}</ion-label>
          </ion-item>
          <h3 style="margin: 15px;" v-if="livescorings && !livescorings.find(f => f.is_qescore == true)">{{ $t('noLivescorings') }}</h3>
          <div v-if="livescorings" >
          <ion-item v-for="(livescoring, index) in livescorings.filter(f => f.is_qescore == true)" :key="index" lines="full" class="ion-no-padding" router-direction="root" :router-link="'/livescoring/' + livescoring.id">
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="createOutline" :ios="createOutline"></ion-icon>
            <ion-label class="clickable ion-text-wrap" color="primary">{{ dayjs(livescoring.start_time).format('HH:mm') }} Tee {{ livescoring.start_tee }}: {{ livescoring.name }} - {{ $t('round') }} {{ livescoring.round_index }}</ion-label>
          </ion-item></div>
        </ion-card-header>
      </ion-card>
      <ion-card v-if="(isLoggedIn && clubInfo && clubInfo.clubInfo && clubInfo.clubInfo.canLivescoring)">
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-label class="noselect ion-text-wrap" >{{ $t('digitalLivescoring') }}</ion-label>
          </ion-item>
          <h3 style="margin: 15px;" v-if="livescorings && !livescorings.find(f => f.allow_livescoring == true)">{{ $t('noLivescorings') }}</h3>
          <div v-if="livescorings" >
          <ion-item v-for="(livescoring, index) in livescorings.filter(f => f.allow_livescoring == true)" :key="index" lines="full" class="ion-no-padding" router-direction="root" :router-link="'/livescoringonly/' + livescoring.id">
            <ion-icon style="zoom: 1.0" slot="start" color="primary" :md="createOutline" :ios="createOutline"></ion-icon>
            <ion-label class="clickable ion-text-wrap" color="primary">{{ dayjs(livescoring.start_time).format('HH:mm') }} Tee {{ livescoring.start_tee }}: {{ livescoring.name }} - {{ $t('round') }} {{ livescoring.round_index }}</ion-label>
          </ion-item></div>
        </ion-card-header>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-item lines="full" class="ion-no-padding">
            <ion-label class="noselect ion-text-wrap" >{{ $t('personalScorecard') }}</ion-label>
          </ion-item>
          <h3 style="margin: 15px;" v-if="courses && courses.length <= 0">{{ $t('noClubCourses') }}</h3>
          <ion-grid v-if="courses && courses.length > 0">
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('coursename')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-select  interface="action-sheet" v-model="selectedCourse">
                  <ion-select-option v-for="(item, index) in courses" :key="index" :value="item">{{ item.name }} ({{ item.numHoles }} {{ $t('holes')  }})</ion-select-option>          
                </ion-select>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('tee')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-select  interface="action-sheet" v-model="selectedTee">
                  <ion-select-option v-for="(item, index) in selectedCourse.tees" :key="index" :value="item">{{ item.name }}  {{ (item.gender == 1 ? $t('gentleman') : $t('ladies')) }}</ion-select-option>          
                </ion-select>
              </ion-col>
            </ion-row>
            
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap" >{{ $t('hcpi')}}: </ion-label>
              </ion-col>
              <ion-col>
                <ion-input type="text" v-model="selectedHCPI"></ion-input>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col>
                <ion-label class="ion-text-wrap">{{ $t('hcpiInputHint') }}</ion-label>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-button color="primary" @click="getScorecardBlank()">{{  $t('getScorecard') }}</ion-button>

          <ion-list v-if="isLoggedIn">
            <ion-item class="ion-no-padding">
              <ion-label class="ion-text-wrap" >{{ $t('savedScoreCard') }}:</ion-label><ion-datetime v-model="selectedYear" display-format="MM.YYYY" ></ion-datetime>
            </ion-item>
              <ion-item v-for="(item, index) in personScorecardHistory" :key="index"> 
                <ion-label>
                  {{ dayjs(parseInt(item.createdAt)).format('DD.MM.YYYY')}}
                  ({{item.courseName}})
                  {{ item.teeName +" "+(item.gender == 1 ? $t('gentleman') : $t('ladies'))}}
                  {{$t('hcpi') +": "+ item.hcpi  }}
                  {{item.comment }}
                </ion-label>
                  <ion-button @click="getScorecard(item)"  fill="clear">
                    <ion-icon color="primary" slot="end"  :md="createOutline" :ios="createOutline">
                    </ion-icon>
                  </ion-button>
                  <ion-button @click="deletePersonalScoreCard(item)"  fill="none">
                    <ion-icon color="danger"  slot="end"  :md="trashOutline" :ios="trashOutline">
                    </ion-icon>
                  </ion-button>
              </ion-item>
                    
          </ion-list>
        </ion-card-header>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonSelect, IonSelectOption,
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, loadingController, IonInput, IonList,
        toastController, modalController, IonDatetime, alertController } from '@ionic/vue';
import { defineComponent, watch, ref, computed } from 'vue';
import { personOutline, calendarClearSharp, createOutline, addCircleOutline, trashOutline, checkmarkDoneOutline } from 'ionicons/icons';
import { useQuery, useResult, onResult, useMutation } from '@vue/apollo-composable'
import { apolloClient } from '../main.js';
import getLivescoringsQueryFile from '../graphql/getLivescorings.query.gql'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')

import { useRouter, useRoute } from 'vue-router';
import store from '../main'
import Storage from ".././plugins/storage.js";
import getPersonScorecardHistoryFile from '../graphql/getPersonScorecardHistory.query.gql'
import deletePersonalScoreCardMutationFile from '../graphql/deletePersonalScoreCard.mutation.gql'
import AddRPRDialog from './dialogs/AddRPRDialog.vue'
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'Livescorings',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonSelect, IonSelectOption, IonInput, IonList,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol,
    IonDatetime
  },
  setup() {
   let loadingSpinner = null
   const router = useRouter()
   const route = useRoute()
   let selectedCourse = ref()
   let selectedTee = ref()
   let selectedHCPI = ref(28.4)
   let personScorecardHistory = ref()
   let selectedYear = ref(dayjs.tz(new Date()).format('YYYY-MM-DD'))
   const clubInfo = ref(getClubInfo())

    const isLoggedIn = computed(() => store.getters.isLoggedIn ) 

    const {onResult, refetch: getLivescoringsRefetch, result: getLivescoringsResult, loading: getLivescoringsLoading } = useQuery(getLivescoringsQueryFile, () => ({  }), { fetchPolicy: 'no-cache' })
    const livescorings = useResult(getLivescoringsResult, null, data => data.getLivescorings.livescorings)
    const rprs = useResult(getLivescoringsResult, null, data => data.getLivescorings.rprs)
    const priceRPR = useResult(getLivescoringsResult, null, data => data.getLivescorings.priceRPR)
    const courses = useResult(getLivescoringsResult, null, data => data.getLivescorings.courses)
    onResult(queryResult => {
      if (queryResult.data && queryResult.data.getLivescorings && queryResult.data.getLivescorings.courses.length > 0) {
        if (route.query.courseid && queryResult.data.getLivescorings.courses.find(f => f.cid == route.query.courseid))
          selectedCourse.value = queryResult.data.getLivescorings.courses.find(f => f.cid == route.query.courseid)
        else if (route.query.course && route.query.plant && queryResult.data.getLivescorings.courses.find(f => f.course == route.query.course && f.plant == route.query.plant))
          selectedCourse.value = queryResult.data.getLivescorings.courses.find(f => f.course == route.query.course && f.plant == route.query.plant)
        else
          selectedCourse.value = queryResult.data.getLivescorings.courses[0]
      }
      if (queryResult.data && queryResult.data.getLivescorings && queryResult.data.getLivescorings.hcp)
        selectedHCPI.value = parseFloat(queryResult.data.getLivescorings.hcp)
    })

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getLivescoringsLoading.value)  
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getLivescoringsLoading, (getLivescoringsLoading, prevLoading) => {
      if (!getLivescoringsLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getLivescoringsLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getLivescoringsLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })

    watch(selectedCourse, (newVal, preVal) => {
      selectedTee.value = newVal.tees[0]
    })

    const { refetch: getPersonScorecardHistoryRefetch, result: getPersonScorecardHistoryResult, query: getPersonScorecardHistoryQuery, onResult: getPersonScorecardHistoryOnResult } = useQuery(getPersonScorecardHistoryFile,{year: selectedYear}, { fetchPolicy: 'no-cache' })
    
/*     personScorecardHistory = useResult(getPersonScorecardHistoryResult, null, data => {
      data.getPersonScorecardHistory.personScorecardHistory
      if(data.getPersonScorecardHistory.personScorecardHistory.length > 0){        
          return data.getPersonScorecardHistory.personScorecardHistory
      }else{
        []
      }
    })
     */
    getPersonScorecardHistoryOnResult(queryResult => {
      personScorecardHistory.value = []
      if (queryResult.data && queryResult.data.getPersonScorecardHistory && queryResult.data.getPersonScorecardHistory.personScorecardHistory) {
        if(queryResult.data.getPersonScorecardHistory.personScorecardHistory.length > 0){        
          queryResult.data.getPersonScorecardHistory.personScorecardHistory.forEach(element => {
            personScorecardHistory.value.push(element)
          });
        }
      }
    })

    const { mutate: deletePersonalScoreCardMutation } = useMutation(deletePersonalScoreCardMutationFile, { fetchPolicy: 'no-cache' });

    return {
      livescorings, personOutline, calendarClearSharp, dayjs, createOutline, rprs, addCircleOutline, trashOutline,
      selectedCourse, selectedTee, courses, selectedHCPI, isLoggedIn, clubInfo,
      selectedYear, personScorecardHistory,  getPersonScorecardHistoryRefetch,
      deletePersonalScoreCardMutation, priceRPR, checkmarkDoneOutline, getLivescoringsRefetch
    }
  },
  watch: {
    selectedTee: function() {
      this.getPersonScorecardHistoryRefetch({ year: this.selectedYear})
    },
    selectedYear: function() {
      this.getPersonScorecardHistoryRefetch({ year: this.selectedYear})
    },
    $route (to, from){
        this.getPersonScorecardHistoryRefetch({ year: this.selectedYear})
    }
  },
  methods: {
    async newRPR() {
      const modal = await modalController
          .create({
            component: AddRPRDialog,
            componentProps: {
              propsData: {
                selectedCourse: this.selectedCourse,
                selectedTee: this.selectedTee,
                selectedHCPI: this.selectedHCPI,
                courses: this.courses,
                priceRPR: this.priceRPR
              }
            },
          })
          modal.onDidDismiss()
          .then((data) => {
            if (data.data) {
              this.getLivescoringsRefetch()
            }
          })

      return modal.present();
    },
    async getScorecardBlank() {
      await apolloClient.query({
          query: getPersonScorecardHistoryFile,
          variables: {     
            year: dayjs.tz(new Date()).format('YYYY-MM-DD'),
            teeId: parseInt(this.selectedTee.tid),
            hcpi: parseFloat(this.selectedHCPI)
          }
        })
        .then((queryResult) => {          
          if(queryResult.data.getPersonScorecardHistory.personScorecardHistory.length > 0){
            this.getScorecard(queryResult.data.getPersonScorecardHistory.personScorecardHistory[0])
          }else{

            if (isNaN(this.selectedHCPI))
              this.selectedHCPI = 54
            this.$router.push('/livescoringsc/' + this.selectedTee.tid + '/' + this.selectedHCPI+ '?PersonScorecardId=0&comment=&UsingLocalStorage=false')
          
          }
        })
    },
    getScorecard(personScoreCard) {
            
      var today =  dayjs.tz(new Date()).format('YYYY-MM-DD') 
      var date = dayjs.tz(parseInt(personScoreCard.createdAt)).format('YYYY-MM-DD')

      this.$router.push('/livescoringsc/' + personScoreCard.tee_id + '/' + personScoreCard.hcpi+'?PersonScorecardId='+personScoreCard.id+ '&comment='+personScoreCard.comment+'&UsingLocalStorage='+(date==today?'true':'false'))
    },
    async deletePersonalScoreCard(personScorecard){
      var personScorecardId = personScorecard.id

      const alert = await alertController
        .create({
          header: this.$t('personalScorecard'),
          message: this.$t('wantToDeleteScoreCard'),
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                this.deletePersonalScoreCardMutation({id: personScorecardId})
                .then(res => {
                  if (res && res.data && res.data.deletePersonalScoreCard && res.data.deletePersonalScoreCard.success) {                    
                    Storage.removeItem('personscorecard_'+personScorecard.tee_id+'_'+personScorecard.hcpi+'_'+personScorecard.comment);
                    Storage.removeItem('personscorecard_'+personScorecard.tee_id+'_'+personScorecard.hcpi+'_'+personScorecard.comment+'_updatedAt');
                    
                    this.getPersonScorecardHistoryRefetch({ year: this.selectedYear})
                    toastController
                      .create({
                        message: this.$t('deleteSuccessful'),
                        duration: 2000,
                        color: 'success'
                      }).then(res => res.present())
                  }
                  else {
                    toastController
                      .create({
                        message: this.$t('notSuccessful'),
                        duration: 3000,
                        color: 'danger'
                      }).then(res => res.present())
                  }
                })
                .catch(err => {
                    toastController
                      .create({
                        message: this.$t('notSuccessful'),
                        duration: 3000,
                        color: 'danger'
                      }).then(res => res.present())
                }) 
              
              },
            },
          ],
        });
      return alert.present();

    }
  },
  mounted(){
  }
});
</script>

<style scoped>
.clickable {
    cursor: pointer;
}
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>