<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-item lines="none">
          <ion-title>{{ $t('infoScreenTitel') }}</ion-title>
          <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
        </ion-item>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-list lines="full" class="ion-no-margin">  
        
          <h4>{{ $t('infoScreenMessage') }}</h4>
          <hr>
          <ion-button style="margin-left: 10%; width:20%;"  @click="setPrivacy(true)"  color="success">{{ $t('yes') }}</ion-button>
          <ion-button style="margin-left: 35%; width:20%;"  @click="setPrivacy(false)"  color="danger">{{ $t('no') }}</ion-button>

      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonButton, IonContent, IonHeader, IonTitle, toastController,IonCardContent, IonTextarea, IonSearchbar,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonSelect, IonSelectOption,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle,
IonListHeader, IonAvatar, isPlatform } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline,
         addCircleOutline, trashOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'

import savePersonInfoScreenMutationFile from '../../graphql/savePersonInfoScreen.mutation.gql'

export default defineComponent({
  name: 'ShowInfoScreenDialog',
  setup() {
    const { mutate: savePersonInfoScreenMutation } = useMutation(savePersonInfoScreenMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline, savePersonInfoScreenMutation
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    setPrivacy(value){
      this.savePersonInfoScreenMutation( { privacyInfoscreen: value, isPopup: true })
      .then(res => {
        if (res.data.savePersonInfoScreen.success) { 
         this.closeDialog();
        }
        else {
          toastController
            .create({
              message: this.$t('changeNotSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
    }
  }
});
</script>