<template>
  <ion-page>
    <ion-content :fullscreen="true" id='print' ref="content">
      <h3  :class="fontName && fontName != '' ? fontName : ''" style="margin: 15px;" v-if="tournaments && tournaments.length <= 0">{{ $t('noTournaments') }}</h3>
      <div v-if="tournaments && tournaments.length > 0" style="margin-left: 10%;margin-right: 10%; margin-bottom: 20px;">
        <ion-grid>
          <ion-row>
            <ion-col size="auto">
              <ion-img style="width: 60px; height:60px; margin-right: 10px;" :src="logo" />
            </ion-col>
            <ion-col size="auto">
              <h3 :class="fontName && fontName != '' ? fontName : ''">
                Unsere nächsten Turniere
              </h3>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
      <ion-card style="margin-left: 10%;margin-right: 10%; margin-bottom: 20px;" :id="(tournament.currentSlot ? 'currentSlot' : '')" v-for="(tournament, index) in tournaments" :key="index">
        <ion-card-header class="ion-no-padding ion-no-margin">
          <ion-item>
            <ion-grid>
              <ion-row>
                <ion-col size="auto">
                  <ion-icon :style="isPlatform('mobile') ? 'zoom: 1.0' : 'margin-left: 5px;zoom: 1.4'" color="primary" :md="timeOutline" :ios="timeOutline"></ion-icon>
                </ion-col>
                <ion-col size="auto">
                  <ion-label :class="fontName && fontName != '' ? fontName + ' ion-no-padding ion-no-margin noselect ion-text-wrap' : 'ion-no-padding ion-no-margin noselect ion-text-wrap'" color="primary">{{ $t(dayjs(tournament.begin).format('dddd')) }}, {{ dayjs(tournament.begin).format('DD.MM.YYYY HH:mm') }} - {{ dayjs(tournament.end).format('HH:mm') }}</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding" @click="tournaments[index].showContent = !tournaments[index].showContent">
            <ion-card-title :class="fontName && fontName != '' ? fontName + ' clickable' : 'clickable'" :color="getTournamentColor(tournament)" :style="isPlatform('mobile') && (tournament.sponsorSrc && tournament.sponsorSrc != '') ? 'font-size: 12px !important;' : isPlatform('mobile') ? 'font-size: 14px !important;' : 'font-size: 20px !important;margin-top: 5px;margin-left: 15px;'">{{ tournament.name}}</ion-card-title>
            <ion-icon v-if="tournament.imageSrc != ''" @click="showImage(tournament.imageSrc)" :style="isPlatform('mobile') ? 'zoom: 0.8' : 'zoom: 1.2'" slot="end" color="primary" :md="documentTextOutline" :ios="documentTextOutline"></ion-icon>
            <ion-img v-if="tournament.sponsorSrc != ''" :style="isPlatform('mobile') ? 'width: 30px;' : 'width: 80px'" slot="end" :src="tournament.sponsorSrc"></ion-img>
            <ion-card-title :class="fontName && fontName != '' ? fontName : ''" :style="isPlatform('mobile') ? 'font-size: 18px !important;': 'font-size: 20px !important;'" v-if="(tournament.payOnline || tournament.showPrice) && tournament.priceFloat > 0" slot="end">{{ tournament.price }}</ion-card-title>
          </ion-item>
        </ion-card-header>
        <ion-card-content v-if="tournaments[index].showContent" class="ion-no-padding ion-no-margin" style="margin: 0px;">
          <ion-item v-if="tournament.eventInfo && tournament.eventInfo != ''" lines="full" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px; margin-top: 5px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap': 'ion-text-wrap'">{{ tournament.eventInfo }}</ion-label>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px; margin-top: 5px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap': 'ion-text-wrap'">{{ $t('participants') }}: {{ tournament.registeredPlayers }} {{ $t('of') }} {{ tournament.teiln_max }} ({{ $t('guests') }} {{ tournament.teiln_gast_max }})</ion-label>
            <ion-icon v-if="tournament.gender == 2 || tournament.gender == 1" class="ion-no-padding" style="zoom: 0.8" color="female" :md="womanSharp" :ios="womanSharp"></ion-icon>
            <ion-icon v-if="tournament.gender == 2 || tournament.gender == 0" class="ion-no-padding" style="zoom: 0.8" color="male" :md="manSharp" :ios="manSharp"></ion-icon>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
          <ion-progress-bar style="margin-left: 15px;"  :color="getPercentage(tournament.registeredPlayers, tournament.teiln_max).color" :value="getPercentage(tournament.registeredPlayers, tournament.teiln_max).percentage"></ion-progress-bar>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap': 'ion-text-wrap'">{{ $t('webAvailable') }}: {{ tournament.teiln_web_available }} ({{ $t('guests') }} {{ tournament.teiln_web_gast_available }})</ion-label>
          </ion-item>
          <ion-item lines="none" style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap': 'ion-text-wrap'">{{ $t('regPeriod') }}: {{ tournament.registration_period }} </ion-label>
          </ion-item >
          <ion-item style="margin: 0px;">
            <ion-label style="padding-left: 15px; margin: 0px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap': 'ion-text-wrap'">{{ $t('hcpi') }}: {{ tournament.hcp_from }} {{ $t('toStr') }} {{ tournament.hcp_to }}</ion-label>
          </ion-item>
          <ion-item class="ion-no-padding" v-for="(round, rindex) in tournament.rounds" :key="rindex">
            <ion-label style="padding-left: 15px;" :class="fontName && fontName != '' ? fontName + ' ion-text-wrap': 'ion-text-wrap'">{{ $t('round') }} {{ (rindex + 1) }}: {{ $t(('PF' + round.play_form)) }}, {{ $t(('SM' + round.scoring_mode)) }}, {{ $t(('W' + round.wertung)) }} , {{ $t((round.coursename)) }} {{ round.is_inclusion ? ', ' + $t('isInclusion') : '' }} <strong>({{ $t(('HCPREL' + round.hcp_relevance)) }})</strong></ion-label>
            <ion-icon v-if="round.showEntryList" @click="showEntryList(tournament)" :style="isPlatform('mobile') ? 'zoom: 0.8' : 'zoom: 1.2'" slot="end" color="primary" :md="listOutline" :ios="listOutline"></ion-icon>
            <ion-icon v-if="round.showStartList" @click="showStartList(tournament, round)" :style="isPlatform('mobile') ? 'zoom: 0.8' : 'zoom: 1.2'" slot="end" color="primary" :md="listCircleOutline" :ios="listCircleOutline"></ion-icon>
            <ion-icon v-if="round.showResultList" @click="showResultList(tournament, round)" :style="isPlatform('mobile') ? 'zoom: 0.8' : 'zoom: 1.2'" slot="end" color="primary" :md="medalOutline" :ios="medalOutline"></ion-icon>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>
import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, toastController, IonCard, IonCardHeader, IonCardContent, IonGrid, IonRow, IonCol, IonIcon,IonImg,
IonInput, IonItem, IonList, isPlatform } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import findNextCompetitionsQueryFile from '../graphql/findNextCompetitions.query.gql'
import { useQuery, useResult } from '@vue/apollo-composable'
import { arrowForwardOutline, arrowBackOutline, todayOutline, helpCircleOutline,refreshOutline, chevronDownOutline,
         chevronUpOutline, listOutline, medalOutline, documentTextOutline, timeOutline,downloadOutline, 
         printOutline, manSharp, womanSharp, listCircleOutline, exitOutline } from 'ionicons/icons';
import { useRoute, useRouter } from 'vue-router';
import * as dayjs from 'dayjs';

export default defineComponent({
  name: 'Reset',
  components: {
    IonContent, IonInput,
    IonHeader, IonItem, IonList,
    IonPage, IonImg,
    IonTitle, IonIcon,
    IonToolbar, IonGrid, IonRow, IonCol,
    IonButton, IonLabel, IonCard, IonCardHeader, IonCardContent
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const isLoggedIn = ref(false)

    const { refetch: findNextCompetitionsRefetch, result: findNextCompetitionsResult } = useQuery(findNextCompetitionsQueryFile, () => ({  }), () => ({ fetchPolicy: 'no-cache', pollInterval: 21000 }))
    let tournaments = useResult(findNextCompetitionsResult, null, data => data.findNextCompetitions.tournaments)
    const logo = useResult(findNextCompetitionsResult, null, data => data.findNextCompetitions.logo)
    const idToUseCompetitions = useResult(findNextCompetitionsResult, null, data => data.findNextCompetitions.id)
    const random = useResult(findNextCompetitionsResult, null, data => data.findNextCompetitions.random)
    const fontName = useResult(findNextCompetitionsResult, null, data => data.findNextCompetitions.fontName)

    watch(random, (newVal, prevVal) => {
      if (idToUseCompetitions.value == 0 && route.path == '/nextcompetitionsscreen')
        setTimeout(() => { router.push('/startlistscreen') }, tournaments.value.length == 0 ? 10000: 20000)
      else if (idToUseCompetitions.value > 0 && route.path == '/nextcompetitionsscreen')
        setTimeout(() => { router.push('/infoscreen/' + idToUseCompetitions.value + '?type=t') }, tournaments.value.length == 0 ? 10000: 20000)
    })

    return {
      timeOutline, tournaments, dayjs, logo, isPlatform, arrowForwardOutline, arrowBackOutline, todayOutline, helpCircleOutline,refreshOutline, chevronDownOutline,
         chevronUpOutline, listOutline, medalOutline, documentTextOutline, timeOutline,downloadOutline, 
         printOutline, manSharp, womanSharp, listCircleOutline, exitOutline, isLoggedIn, fontName
    }
  },
  mounted() {
  },
  methods: {
    getTournamentColor(tournament) {
      if (tournament.canRegisterError == 7)
        return 'success'
      if (dayjs().isBefore(dayjs(tournament.begin)) && tournament.canRegisterError > 0 )
        return 'warning'
    },
    getPercentage (bookedPlayers, maxPlayers) {
      const percentage = (bookedPlayers / maxPlayers) == 0 ? 1: bookedPlayers / maxPlayers
      let color = "successlight"
      if (bookedPlayers == maxPlayers)
        color = "danger"
      else if (percentage > 0 && percentage <= 0.25)
        color = "success"
      else if (percentage > 0.25 && percentage <= 0.5)
        color = "warninglight"
      else if (percentage > 0.5 && percentage <= 0.75)
        color = "warning"
      else if (percentage > 0.75 && bookedPlayers != 0)
        color = "dangerlight"

      return { percentage: percentage, color: color }
    },
  }
});
</script>

<style scoped>

.minion { 
  font-family: "Minion, Arial" !important;
}

.clickable {
    cursor: pointer;
}

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

ion-button {
  --padding-end: 0;
  --inner-padding-end: 0;
  margin-inline: 0px 15px;
}

ion-item {
  --ion-background-color: white !important;
  --padding-start: 0;
  --padding-end: 0;
  
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  
  --min-height: 0;
}

ion-item.white {
  --ion-background-color: white !important;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

ion-icon:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>