<template>
  <ion-header>
    <ion-toolbar>
      <ion-item lines="none">
        <ion-title>{{ $t('editBooking') }}</ion-title>
        <ion-icon slot="end" style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" name="closeOutline"></ion-icon>
      </ion-item>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-card>
      <ion-card-header>
        <ion-card-title v-if="slot">
          {{ dayjs(slot.date).tz().format('DD.MM.YYYY HH:mm') }} {{ selectedPro.selectedItem.name }}
        </ion-card-title>
      </ion-card-header>
      <ion-list  v-show="selectedPro" lines="full" class="ion-no-margin">
        <ion-item>
          <ion-select v-if="selectedPro" interface="popover" slot="start" v-model="selectedInterval">
            <ion-select-option v-for="(item, index) in selectedPro.interval_selection" :key="index" :value="item">{{ item }}</ion-select-option>          
          </ion-select>
          <ion-label  v-if="selectedPro" slot="end">{{ selectedPro.interval_name }}</ion-label>
        </ion-item>
        <ion-item v-if="selectedPro && selectedPro.interval_hint && selectedPro.interval_hint != ''">
          <ion-label v-if="selectedPro" class="ion-text-wrap">{{ selectedPro.interval_hint }}</ion-label>
        </ion-item>
        <ion-item>
          <ion-icon slot="start" color="primary" :md="documentTextOutline" :ios="documentTextOutline" style="zoom: 1.0"></ion-icon>
          <ion-input :placeholder="$t('comment')" v-model="selectedComment"></ion-input>
        </ion-item>

        <ion-grid>
          <ion-row v-for="(item, index) in proPlayers" :key="index" :value="item">
            <ion-grid>
              <ion-row>
                <ion-col style="border-top: 1px solid lightgrey;" size="auto">
                  <ion-icon @click="deletePlayer(item)" color="danger" :md="trashOutline" :ios="trashOutline" style="zoom: 1.4; margin-right: 5px;"></ion-icon>
                </ion-col>
                <ion-col style="border-top: 1px solid lightgrey;" >
                  <ion-label style="font-weight: bold; font-size: 16px;" class="ion-text-wrap">&nbsp;{{ item.lastName }} {{ item.firstName }} ({{item.hcp > 54 ? '--' : item.hcp }})</ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-row>
        </ion-grid>

        <ion-item-divider v-if="proPlayers && proPlayers.length < 1">
          <ion-label>
            {{ $t('addPerson') }}
          </ion-label>
        </ion-item-divider>
        <ion-item v-if="proPlayers && proPlayers.length < 1" lines="full">
          <ion-icon @click="addManualPerson()" slot="start" color="success" :md="personAddOutline" :ios="personAddOutline" style="zoom: 1.0"></ion-icon>
          <ion-searchbar :placeholder="$t('searchPlayer')" v-model="searchText" @ionClear="findPlayer('')" @input="findPlayer($event.target.value)"></ion-searchbar>
        </ion-item>
        <ion-item v-if="playersFound && playersFound.length > 0">
          <ion-list>
            <ion-list-header>
              {{ $t('searchresult') }}
            </ion-list-header>
            <ion-item class="ion-text-wrap" v-for="(player, i) in playersFound" :key="i" @click="addPlayer(player)">
              <ion-icon  :color="player.sex_sport" :md="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" :ios="(player.sex_sport == 'female' ? womanSharp : (player.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
              <ion-label class="ion-text-wrap" style="margin-left: 5px">
                <h2>{{ player.nameLong }}</h2>
              </ion-label>
              <ion-avatar @contextmenu.prevent="" slot="end" v-if="player.showAvatar && player.avatarLink != ''">
                <img :src="player.avatarLink">
              </ion-avatar>
            </ion-item>
          </ion-list>
        </ion-item>

        <ion-button style="margin-top: 15px;" :disabled="proPlayers.length != 1" expand="block" color="primary" @click="doBooking()">{{ $t('makeProBooking') }}</ion-button>

        <ion-item>
          <ion-icon slot="start" color="danger" :md="lockClosedOutline" :ios="lockClosedOutline" style="zoom: 1.0"></ion-icon>
          <ion-input :placeholder="$t('sperrungnoshow')" v-model="selectedSperrung"></ion-input>
        </ion-item>
        <ion-button style="margin-top: 15px;" :disabled="selectedSperrung == ''" expand="block" color="warning" @click="doSperrung()">{{ $t('addLock') }}</ion-button>

      </ion-list>
    </ion-card>
  </ion-content>
</template>

<script>
import { IonButton, IonContent, IonHeader, IonTitle, toastController, IonSelect, IonSelectOption,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, loadingController,
IonGrid, IonCol, IonRow, modalController, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, isPlatform } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, manSharp, womanSharp, lockClosedOutline,
          personAddOutline, trashOutline, timeOutline, arrowForwardCircleOutline, documentTextOutline } from 'ionicons/icons';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import saveProBookingProMutationFile from '../../graphql/saveProBookingPro.mutation.gql'
import saveProBookingSperrungMutationFile from '../../graphql/saveProBookingSperrung.mutation.gql'
import findPlayersQueryFile from '../../graphql/findPlayers.query.gql'
import AddPersonManual from './AddPersonManual'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { getClubInfo } from '@/composition'

export default defineComponent({
  name: 'EditProBookingDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonSelect, IonSelectOption,
              },
  props: ['propsData'],
  setup() {
    let slot = ref()
    let selectedPro = ref()
    let selectedInterval = ref(0)
    let selectedComment = ref('')
    let selectedSperrung = ref('')
    const clubInfo = ref(getClubInfo())
    let searchText = ref('')
    let proPlayers = ref([]) 

    const { refetch: findPlayersRefetch, result: findPlayersResult } = useQuery(findPlayersQueryFile, () => ({ searchTerm: '' }))
    const playersFound = useResult(findPlayersResult, null, data => data.findPlayers.players)

    const { mutate: saveProBookingProMutation } = useMutation(saveProBookingProMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: saveProBookingSperrungMutation } = useMutation(saveProBookingSperrungMutationFile, { fetchPolicy: 'no-cache' });

    return {
      dayjs, selectedComment, isPlatform,
      closeOutline, pawSharp, stopCircleOutline, logInOutline, squareSharp, personSharp, personAddOutline, documentTextOutline,
      trashOutline,timeOutline, slot, selectedPro, selectedInterval, arrowForwardCircleOutline, clubInfo, lockClosedOutline,
      searchText, playersFound, findPlayersRefetch, proPlayers, manSharp, womanSharp, saveProBookingProMutation, saveProBookingSperrungMutation, selectedSperrung
    }
  },
  computed: {
  },
  mounted() {
    this.slot = this.propsData.slot
    this.selectedPro = this.propsData.selectedPro
    this.selectedInterval = this.selectedPro.interval_selection[0]
  },
  methods: {
    async addManualPerson() {
      const modal = await modalController
        .create({
          component: AddPersonManual,
          cssClass: this.isPlatform('mobile') ? '' : 'editBooking-modal',
        })
      modal.onDidDismiss()
        .then((data) => {
          if (data.data) {
            this.proPlayers.push({ id: 0, email: data.data.email, gender: data.data.gender, firstName: data.data.name, lastName: data.data.lastname, name: data.data.name, familyname: data.data.lastname, hcp: data.data.hcp })
          }
        })
      return modal.present();

    },
    deletePlayer(player) {
      //this.pricesum -= player.price
      this.proPlayers.splice(this.proPlayers.findIndex(p => p.id == player.id), 1)
    },
    async addPlayer(player) {
      if (this.proPlayers && this.proPlayers.findIndex(p => p.id == player.id) >= 0) {
         toastController
            .create({
              message: player.firstName + ' ' + player.lastName + this.$t('alreadyInFlight'),
              duration: 3000,
              color: 'warning'
            }).then(res => res.present())
        return
      }
      this.proPlayers.push({ id: player.id, firstName: player.firstName, lastName: player.lastName, hcp: player.hcp  })
      //this.pricesum += player.price
      this.searchText = ''
      this.findPlayersRefetch({ searchTerm: ''})
    },
    async findPlayer(searchTerm) {
      if (searchTerm.length > 3) {
        this.findPlayersRefetch({ searchTerm: searchTerm, bookingTypeId: this.bookingTypeId, slotDate: this.slot.date, checkPayment: false})
      }
      else {
        this.findPlayersRefetch({ searchTerm: ''})
      }
    },
    doBooking() {
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.saveProBookingProMutation({ date: this.slot.date, proId: this.selectedPro.selectedItem.id, interval: this.selectedPro.interval_selection.findIndex(i => i == this.selectedInterval) + 1, personId: this.proPlayers && this.proPlayers.length > 0 ? this.proPlayers[0].id : 0, comment: this.selectedComment, manualPlayer: JSON.stringify(this.proPlayers[0]), price: this.slot.payProfee ? (this.slot.priceFloat * this.selectedInterval * this.selectedPro.intervalDivisor) : 0, language: this.$store.getters.language })
      .then(res => {
          toastController
            .create({
              message: this.$t('bookingAdded'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
            loadingController.dismiss()
            return modalController.dismiss(true)
      })
      .catch(err => {
          loadingController.dismiss()
          toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
    doSperrung() {
      loadingController.create().then(res => {
        res.present()
      }).catch(err => { })
      this.saveProBookingSperrungMutation({ date: this.slot.date, proId: this.selectedPro.selectedItem.id, comment: this.selectedSperrung, interval: this.selectedPro.interval_selection.findIndex(i => i == this.selectedInterval) + 1 })
      .then(res => {
          toastController
            .create({
              message: this.$t('addLockSuccess'),
              duration: 3000,
              color: 'success'
            }).then(res => res.present())
            loadingController.dismiss()
            return modalController.dismiss(true)
      })
      .catch(err => {
          loadingController.dismiss()
          toastController
            .create({
              message: this.$t('notSuccessful') + ': ' + err,
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
    closeDialog() {
      return modalController.dismiss()
    },
  }
});
</script>