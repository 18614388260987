<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Friends</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="isLoggedIn" class="ion-padding" ref="content">
      <ion-card v-if="friendsNotConfirmed && friendsNotConfirmed.length > 0">
        <ion-card-header>
          <ion-card-title>
            <ion-item lines="none">
              <h3>{{ $t('friendsNotConfirmed') }}</h3>
            </ion-item>
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-item v-for="(friend, i) in friendsNotConfirmed" :key="i">
            <ion-icon  :color="friend.sex_sport" :md="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" :ios="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
            <ion-label class="ion-text-wrap" style="margin-left: 5px">
              <h2>{{ friend.nameLong }}</h2>
            </ion-label>
            <ion-avatar @contextmenu.prevent="" slot="end" :class="imgClass" @click="changeImg()" v-if="friend.showAvatar && friend.avatarLink != ''">
              <img :src="friend.avatarLink">
            </ion-avatar>
            <ion-button fill="none" @click="declineFriend(friend)" >
              <ion-icon slot="end" style="zoom: 1.2;" color="danger" :md="thumbsDownOutline" :ios="thumbsDownOutline"></ion-icon>
            </ion-button>
            <ion-button fill="none" @click="confirmFriend(friend)" >
              <ion-icon slot="end" style="zoom: 1.2;" color="success" :md="checkmarkOutline" :ios="checkmarkOutline"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            <ion-item lines="none">
              <h3>{{ $t('friends') }}</h3>
            </ion-item>
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-item v-for="(friend, i) in friends" :key="i">
            <ion-icon v-if="friend.isNew" color="success" :md="addCircleOutline" :ios="addCircleOutline"></ion-icon>
            <ion-icon v-if="friend.confirmed" color="success" :md="checkmarkOutline" :ios="checkmarkOutline"></ion-icon>
            <ion-icon v-if="!friend.confirmed" color="warning" :md="hourglassOutline" :ios="hourglassOutline"></ion-icon>
            <ion-icon  :color="friend.sex_sport" :md="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" :ios="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
            <ion-label v-if="friend.isDelete" class="ion-text-wrap" style="margin-left: 5px; text-decoration: line-through;">
              <h2>{{ friend.nameLong }}</h2>
            </ion-label>
            <ion-label v-if="!friend.isDelete" class="ion-text-wrap" style="margin-left: 5px;">
              <h2>{{ friend.nameLong }}</h2>
            </ion-label>
            <ion-avatar @contextmenu.prevent="" :class="imgClass" @click="changeImg()" slot="end" v-if="friend.showAvatar && friend.avatarLink != ''">
              <img :src="friend.avatarLink">
            </ion-avatar>
            <ion-button fill="none" @click="deleteFriend(friend)" >
              <ion-icon v-if="!friend.isDelete" style="zoom: 1.2;" slot="end" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
            </ion-button>
          </ion-item>
          <ion-item-divider color="">
            <ion-label style="font-weight: bold; font-size: 18px">
              {{ $t('addAdditionalFriends') }}
            </ion-label>
          </ion-item-divider>
          <ion-item lines="full">
            <ion-icon slot="start" color="primary" :md="personAddOutline" :ios="personAddOutline" style="zoom: 1.0"></ion-icon>
            <ion-searchbar :placeholder="$t('searchPlayer')" v-model="searchText" @ionClear="findPlayer('')" @input="findPlayer($event.target.value)"></ion-searchbar>
          </ion-item>
          <ion-item v-if="friendsFound && friendsFound.length > 0">
            <ion-list>
              <ion-list-header>
                {{ $t('searchresult') }}
              </ion-list-header>
              <ion-item class="ion-text-wrap" v-for="(friend, i) in friendsFound" :key="i" @click="addFriend(friend)">
                <ion-icon  :color="friend.sex_sport" :md="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" :ios="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
                <ion-label class="ion-text-wrap" style="margin-left: 5px">
                  <h2>{{ friend.nameLong }}</h2>
                </ion-label>
                <ion-avatar @contextmenu.prevent="" slot="end" v-if="friend.showAvatar && friend.avatarLink != ''">
                  <img :src="friend.avatarLink">
                </ion-avatar>
              </ion-item>
            </ion-list>
          </ion-item>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-header>
          <ion-card-title>
            <ion-item lines="none">
              <h3>{{ $t('friendGroups') }}</h3>
                <ion-button slot="end" fill="none" @click="addFriendGroup()" >
                  <ion-icon  style="zoom: 1.4;" color="success" :md="addCircleOutline" :ios="addCircleOutline"></ion-icon>
                </ion-button>
            </ion-item>
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-list v-for="(friendGroup, i) in friendGroups" :key="i">
            <ion-list-header>
              <ion-item lines="full">
                <h2>{{ friendGroup.name }}</h2>
                <ion-icon slot="end" v-if="!friendGroups[i].showContent && friendGroups[i].id != showGroupId" @click="friendGroups[i].showContent = true;showGroupId=-1;" color="primary" :md="chevronDownOutline" :ios="chevronDownOutline" style="zoom: 1.0"></ion-icon>
                <ion-icon slot="end" v-if="friendGroups[i].showContent || friendGroups[i].id == showGroupId" @click="friendGroups[i].showContent = false;showGroupId=-1;" color="primary" :md="chevronUpOutline" :ios="chevronUpOutline" style="zoom: 1.0"></ion-icon>
                <ion-button slot="end" fill="none" @click="deleteGroup(friendGroup)" >
                  <ion-icon  style="zoom: 1.2;" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
                </ion-button>
              </ion-item>
            </ion-list-header>
              <ion-item v-if="friendGroups[i].showContent || friendGroups[i].id == showGroupId" v-for="(friend, i) in friendGroup.players" :key="i">
                <ion-icon  :color="friend.sex_sport" :md="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" :ios="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
                <ion-label class="ion-text-wrap" style="margin-left: 5px">
                  <h2>{{ friend.nameLong }}</h2>
                </ion-label>
                <ion-avatar @contextmenu.prevent="" :class="imgClass" @click="changeImg()" slot="end" v-if="friend.showAvatar && friend.avatarLink != ''">
                  <img :src="friend.avatarLink">
                </ion-avatar>
                <ion-icon v-if="!friend.isDelete" slot="end" @click="deleteGroupFriend(friendGroup, friend)" color="danger" :md="trashOutline" :ios="trashOutline"></ion-icon>
              </ion-item>
              <ion-item v-if="(friendGroups[i].showContent || friendGroups[i].id == showGroupId) && friendGroup.players.length < 4" >
                <ion-icon color="success" :md="addCircleOutline" :ios="addCircleOutline"></ion-icon>
                <ion-select interface="action-sheet" slot="end" @ionChange="addGroupFriend($event.target.value, friendGroup)">
                  <ion-select-option v-for="(item, i) in friendsSelect" :key="i" :value="item">{{ item.nameLong }}</ion-select-option>
                </ion-select>
              </ion-item>
          </ion-list>
        </ion-card-content>
      </ion-card>
      <ion-card v-if="friendsDeclined && friendsDeclined.length > 0">
        <ion-card-header>
          <ion-card-title>
            <ion-item lines="none">
              <h3>{{ $t('friendsDeclined') }}</h3>
            </ion-item>
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-item v-for="(friend, i) in friendsDeclined" :key="i">
            <ion-icon color="danger" :md="thumbsDownOutline" :ios="thumbsDownOutline"></ion-icon>
            <ion-icon  :color="friend.sex_sport" :md="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" :ios="(friend.sex_sport == 'female' ? womanSharp : (friend.sex_sport == 'male' ? manSharp: personOutline))" style="zoom: 1.0; margin-right: 5px;"></ion-icon>
            <ion-label class="ion-text-wrap" style="margin-left: 5px">
              <h2>{{ friend.nameLong }}</h2>
            </ion-label>
            <ion-avatar @contextmenu.prevent="" :class="imgClass" @click="changeImg()" slot="end" v-if="friend.showAvatar && friend.avatarLink != ''">
              <img :src="friend.avatarLink">
            </ion-avatar>
            <ion-button slot="end" fill="none" @click="confirmFriend(friend)" >
              <ion-icon style="zoom: 1.2;" color="success" :md="checkmarkOutline" :ios="checkmarkOutline"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonButton, IonContent, IonHeader, IonTitle, toastController,IonCardContent, IonTextarea, IonSearchbar,
IonToolbar, IonLabel, IonInput, IonItem, IonList, IonIcon, IonItemDivider, IonSelect, IonSelectOption,
IonGrid, IonCol, IonRow, IonCheckbox, IonCard, IonCardHeader, IonCardTitle, alertController, modalController,loadingController,
IonListHeader, IonAvatar, isPlatform } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline,
         addCircleOutline, trashOutline, chevronDownOutline, chevronUpOutline, checkmarkOutline, hourglassOutline,
         thumbsDownOutline } from 'ionicons/icons';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import * as dayjs from 'dayjs';
import store from '../main'
import translate from '@/plugins/translate'
import saveFriendsMutationFile from '../graphql/saveFriends.mutation.gql'
import saveGroupFriendMutationFile from '../graphql/saveGroupFriend.mutation.gql'
import deleteFriendGroupMutationFile from '../graphql/deleteFriendGroup.mutation.gql'
import confirmFriendMutationFile from '../graphql/confirmFriend.mutation.gql'
import findPlayersQueryFile from '../graphql/findPlayers.query.gql'
import findFriendsQueryFile from '../graphql/findFriends.query.gql'
import AddFriendGroupDialog from './dialogs/AddFriendGroupDialog'

export default defineComponent({
  name: 'Friends',
  components: { IonPage, IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonItemDivider, IonCheckbox, IonCard, IonCardHeader,
                IonCardTitle, IonCardContent, IonSelect, IonSelectOption, IonTextarea, IonSearchbar,
                IonListHeader, IonAvatar
              },
  props: ['propsData'],
  setup() {
    let searchText = ref('')
    let friendsSelect = ref([])
    let showGroupId = ref(-1)
    let loadingSpinner = null
    const imgClass = ref('playerImg')

    const { refetch: findPlayersRefetch, result: findPlayersResult } = useQuery(findPlayersQueryFile, () => ({ searchTerm: '' }), { fetchPolicy: 'no-cache' })
    const friendsFound = useResult(findPlayersResult, null, data => data.findPlayers.players)

    const { onResult, refetch: findFriendsRefetch, result: findFriendsResult, loading: findFriendsLoading } = useQuery(findFriendsQueryFile, null, { fetchPolicy: 'no-cache' })
    const friends = useResult(findFriendsResult, null, data => data.findFriends.players)
    const friendGroups = useResult(findFriendsResult, null, data => data.findFriends.playerGroups)
    const friendsNotConfirmed = useResult(findFriendsResult, null, data => data.findFriends.friendsNotConfirmed)
    const friendsDeclined = useResult(findFriendsResult, null, data => data.findFriends.friendsDeclined)

    onResult(queryResult => {
      if (queryResult && queryResult.data && queryResult.data.findFriends && queryResult.data.findFriends.players) {
        friendsSelect.value = queryResult.data.findFriends.players.filter((p) => p.confirmed == true)
        if (store.getters.UserData)
          friendsSelect.value.push({ avatarLink: store.getters.UserData.imageUrl, confirmed: true, id: store.getters.UserData.personId, nameLong: translate('@me'), showAvatar: store.getters.UserData.showAvatar})
        }
    })

    const { mutate: saveFriendsMutation } = useMutation(saveFriendsMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: saveGroupFriendMutation } = useMutation(saveGroupFriendMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deleteFriendGroupMutation } = useMutation(deleteFriendGroupMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: confirmFriendMutation } = useMutation(confirmFriendMutationFile, { fetchPolicy: 'no-cache' });

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!findFriendsLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(findFriendsLoading, (findFriendsLoading, prevLoading) => {
      if (!findFriendsLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (findFriendsLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!findFriendsLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })


    return {
      saveFriendsMutation, findPlayersRefetch, findFriendsRefetch, saveGroupFriendMutation, deleteFriendGroupMutation, confirmFriendMutation, friendsFound, friends, searchText,
      dayjs, addCircleOutline, trashOutline, isPlatform, friendGroups, chevronDownOutline, chevronUpOutline,
      closeOutline, timeOutline, documentTextOutline, personAddOutline, womanSharp, manSharp, personOutline, imgClass,
      checkmarkOutline, hourglassOutline, friendsSelect, showGroupId, friendsNotConfirmed, friendsDeclined, thumbsDownOutline
    }
  },
  computed: {
      UserData : function(){ return this.$store.getters.UserData},
      isLoggedIn : function() { 
        /*if (this.isLoggedIn != undefined && this.isLoggedIn != this.$store.getters.isLoggedIn) {
          this.findFriendsRefetch()
        }*/
        return this.$store.getters.isLoggedIn
      },
  },
  methods: {
    changeImg() {
      if (this.imgClass == 'playerImg')
        this.imgClass = 'playerImgBig'
      else
        this.imgClass = 'playerImg'
    },
    async confirmFriend(friend) {
      await this.confirmFriendMutation({ friendId: friend.id, value: 1 })
      this.$store.dispatch('setUserDataFriendBatch', this.UserData.friendBatchNum - 1)
      this.findFriendsRefetch()
    },
    async declineFriend(friend) {
      await this.confirmFriendMutation({ friendId: friend.id, value: 2 })
      this.$store.dispatch('setUserDataFriendBatch', this.UserData.friendBatchNum - 1)
      this.findFriendsRefetch()
    },
    async deleteGroup(group) {
      const alert = await alertController
        .create({
          header: this.$t('friendGroups'),
          message: this.$t('delete') + ': ' +  group.name + '?',
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                this.deleteFriendGroupMutation({ id: parseInt(group.id) })
                .then(res => {
                  toastController
                    .create({
                      message: this.$t('deleteSuccessful'),
                      duration: 2000,
                      color: 'warning'
                    }).then(res => res.present())
                    this.findFriendsRefetch()
                })
                .catch(err => {
                  toastController
                    .create({
                      message: this.$t('deleteNotSuccessful' + err),
                      duration: 2000,
                      color: 'danger',
                    }).then(res => res.present())
                })
              },
            },
          ],
        });
      return alert.present();
    },
    async addFriendGroup() {
      const modal = await modalController
        .create({
          component: AddFriendGroupDialog,
        })
      modal.onDidDismiss()
        .then((data) => {
          if (data.data) {
            this.findFriendsRefetch()
          }
        })
      return modal.present();
    },
    async addGroupFriend(friend, group) {
      if (!friend)
        return
      if (this.friendGroups[this.friendGroups.findIndex(g => g.id == group.id)].players.findIndex(p => p.id == friend.id) >= 0)
        return
      await this.saveGroupFriendMutation({ friend: JSON.stringify({ isNew: true, groupId: group.id, friendId: friend.id }) })
      this.showGroupId = group.id
      this.findFriendsRefetch()
    },
    async deleteGroupFriend(group, friend) {
      await this.saveGroupFriendMutation({ friend: JSON.stringify({ isDeleted: true, groupId: group.id, friendId: friend.id }) })
      this.showGroupId = group.id
      this.findFriendsRefetch()
    },
    async deleteFriend(friend) {
      if (friend.isNew)
        this.friends.splice(this.friends.findIndex(p => p.id == friend.id), 1)
      else {
      const alert = await alertController
        .create({
          header: this.$t('friends'),
          message: this.$t('delete') + ': ' +  friend.nameLong + '?',
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                friend.isDelete = true
                this.saveFriendsMutation({ friends: JSON.stringify([friend]) })
                .then(res => {
                  toastController
                    .create({
                      message: this.$t('deleteSuccessful'),
                      duration: 2000,
                      color: 'warning'
                    }).then(res => res.present())
                    this.findFriendsRefetch()
                })
                .catch(err => {
                  toastController
                    .create({
                      message: this.$t('deleteNotSuccessful' + err),
                      duration: 2000,
                      color: 'danger',
                    }).then(res => res.present())
                })
              },
            },
          ],
        });
      return alert.present();
      }
    },
    async addFriend(player) {
      if (this.friends && this.friends.findIndex(p => p.id == player.id) >= 0)
        return
      if (this.friendsNotConfirmed.findIndex(p => p.id == player.id) >= 0)
        return
      if (this.friendsDeclined.findIndex(p => p.id == player.id) >= 0)
        return
      this.searchText = ''
      this.findPlayersRefetch({ searchTerm: ''})
      player.isNew = true
      await this.saveFriendsMutation({ friends: JSON.stringify([player]) })
      this.findFriendsRefetch()
//      this.friends.push(player)
    },
    async findPlayer(searchTerm) {
      if (searchTerm.length > 3) {
        this.findPlayersRefetch({ searchTerm: searchTerm})
      }
      else {
        this.findPlayersRefetch({ searchTerm: ''})
      }
    },
  }
});
</script>

<style scoped>

.playerImg {
  width: 40px; 
  height: 40px;
}

.playerImgBig {
  width: 120px; 
  height:120px
}

</style>