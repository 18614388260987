<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Livescoring</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card v-if="livescoring" id="scorecard" >
        <ion-card-header>
          <ion-card-title>{{ $t('personalScorecard') }}</ion-card-title><br>
          
          <ion-label class="ion-text-wrap" style="font-size: 18px; font-weight: bold; --margin-top: 0px;--padding: 0px;">{{ $t('player') }} {{ livescoring.player.nameLong }} {{ $t('hcpi') }}: {{ livescoring.HCPI }} ({{ livescoring.courseHCPI }})</ion-label><br>
          <ion-label class="ion-text-wrap" style="--margin-top: 0px;--padding: 0px;">{{ livescoring.player.course}}: {{ $t(livescoring.player.teegender) }} - {{ livescoring.player.teename }}, Par: {{livescoring.player.par}}, CR: {{livescoring.player.cr}} Slope: {{livescoring.player.slope}}</ion-label><br><br>
          <ion-item class="ion-no-margin ion-no-padding" v-if="comment && comment != ''">
            <ion-label position="stacked">{{ $t('time') }}</ion-label>
            <ion-input type="text" v-model="comment"></ion-input>
          </ion-item>

        </ion-card-header>
        <ion-card-content>
          <ion-list lines="none" class="ion-no-margin ion-no-padding">
            <ion-item v-if="UserData && this.$route.query.PersonScorecardId!=0" class="ion-no-margin ion-no-padding">
              <ion-button @click="createNewScoreCard()" fill="clear" class="ion-no-padding" >
                  {{ $t('createNewRound') }}
                </ion-button>
            </ion-item>
            <ion-item class="ion-no-margin ion-no-padding">
              <ion-button slot="start" @click="clearScoreCard()" fill="clear" class="ion-no-padding" >
                  <ion-icon color="danger" :md="closeCircleOutline" :ios="closeCircleOutline">
                  </ion-icon>&nbsp;{{ $t('clearScores') }}
                </ion-button>
            </ion-item>
          </ion-list>
          <ion-grid v-if="livescoring.scoring_mode != 3">       
            <ion-row>
              <ion-col class="ion-no-padding text-center numcol bordertop">#</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">Par</ion-col>
              <ion-col class="ion-no-padding text-center lengthcol bordertop">{{ $t('length') }}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol bordertop">//</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol bordertop"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol bordertop">{{ $t('player') }}</ion-col>
              <ion-col class="ion-no-padding text-center gbecol bordertop">Stb</ion-col>
              <ion-col class="ion-no-padding text-center gbecol bordertop borderright">GBE</ion-col>
            </ion-row>
            <ion-row v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol"><ion-label>{{index+1}}</ion-label></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index]}}</ion-col>
              <ion-col class="ion-no-padding text-center lengthcol">{{ livescoring.lengths[index]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                  <ion-button @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index); saveScoreLocal()" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button @click="livescoring.scoresPlayer[index] = livescoring.pars[index]; saveScoreLocal()" fill="outline" color="" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index) }}
                    </ion-label>
                  </ion-button>

                  <ion-button @click="livescoring.scoresPlayer[index]++;saveScoreLocal()" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>
              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol">{{ getStb(livescoring.pars, livescoring.vorgabenPlayer, livescoring.scoresPlayer, index, livescoring.stb) }}</ion-col>
              <ion-col class="ion-no-padding text-center gbecol borderright">{{ getGBE(livescoring.pars, livescoring.vorgabenPlayer, livescoring.scoresPlayer, index, livescoring.gbe) }}</ion-col>
            </ion-row>

            <ion-row>
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center lengthcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol">{{ livescoring.stb.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center gbecol borderright">{{ livescoring.gbe.filter((num,idx) => idx < 9).reduce((a, b) => a + b, 0) }}</ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18" v-for="(num, index) in 9" :key="index">
              <ion-col class="ion-no-padding text-center numcol">{{index+10}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars[index+9]}}</ion-col>
              <ion-col class="ion-no-padding text-center lengthcol">{{ livescoring.lengths[index+9]}}</ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.indexes[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol">{{ livescoring.vorgabenPlayer[index+9] }}</ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                  <ion-button @click="decreaseScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9); saveScoreLocal()" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowDownCircleOutline" :ios="arrowDownCircleOutline">
                    </ion-icon>
                  </ion-button>

                  <ion-button @click="livescoring.scoresPlayer[index+9] = livescoring.pars[index+9];saveScoreLocal()" fill="outline" color="" class="ion-no-padding sizebig" >
                    <ion-label style="font-size: 25px; font-weight: bold;">{{ showScore(livescoring.scoring_mode, livescoring.scoresPlayer, index+9) }}
                    </ion-label>
                  </ion-button>

                  <ion-button @click="livescoring.scoresPlayer[index+9]++;saveScoreLocal()" fill="clear" class="ion-no-padding sizebig" >
                    <ion-icon class="sizebig" color="primary" :md="arrowUpCircleOutline" :ios="arrowUpCircleOutline">
                    </ion-icon>
                  </ion-button>

              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol">{{ getStb(livescoring.pars, livescoring.vorgabenPlayer, livescoring.scoresPlayer, index+9, livescoring.stb) }}</ion-col>
              <ion-col class="ion-no-padding text-center gbecol borderright">{{ getGBE(livescoring.pars, livescoring.vorgabenPlayer, livescoring.scoresPlayer, index+9, livescoring.gbe) }}</ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.pars.filter((num,idx) => idx >= 9 ).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center lengthcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol">{{ livescoring.stb.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center gbecol borderright">{{ livescoring.gbe.filter((num,idx) => idx >= 9).reduce((a, b) => a + b, 0) }}</ion-col>
            </ion-row>

            <ion-row v-if="livescoring.player.numHoles == 18">
              <ion-col class="ion-no-padding text-center numcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol">{{ livescoring.scoring_mode == 1 ? '' : livescoring.pars.reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center lengthcol"></ion-col>
              <ion-col class="ion-no-padding text-center parindexcol"></ion-col>
              <ion-col class="ion-no-padding text-center vorgabecol"></ion-col>
              <ion-col class="ion-no-padding text-center scorecol">
                <ion-label>{{ livescoring.scoring_mode == 1 ? '' : livescoring.scoresPlayer.reduce((a, b) => a + b, 0) }}</ion-label>
              </ion-col>
              <ion-col class="ion-no-padding text-center gbecol">{{ livescoring.stb.reduce((a, b) => a + b, 0) }}</ion-col>
              <ion-col class="ion-no-padding text-center gbecol borderright">{{ livescoring.gbe.reduce((a, b) => a + b, 0) }}</ion-col>
            </ion-row>

            
          
            <ion-row>
              <ion-col class="ion-no-padding text-center">
              </ion-col>
              <ion-col class="ion-no-padding text-center">
              </ion-col>
              <ion-col class="ion-no-padding text-center">
              </ion-col>
              <ion-col class="ion-no-padding text-center">
              </ion-col>
              <ion-col class="ion-no-padding text-center">
              </ion-col>
              <ion-col class="ion-no-padding text-center">
              </ion-col>
              <ion-col class="ion-no-padding text-center">
              </ion-col>
            </ion-row>

            
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>

import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardTitle,
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, IonBadge, loadingController, IonChip,
        toastController, IonImg, modalController, alertController } from '@ionic/vue';
import { defineComponent, ref, watch, computed } from 'vue';
import { personOutline, calendarClearSharp, createOutline, removeCircle, addCircle, arrowDownCircleOutline, arrowUpCircleOutline, trashOutline, saveOutline, closeCircleOutline } from 'ionicons/icons';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getLivescoringQueryFile from '../graphql/getLivescoringSC.query.gql'
import * as dayjs from 'dayjs';
require('dayjs/locale/de')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Europe/Paris')
dayjs.locale('de')
import { isPlatform } from '@ionic/vue';
import { useRoute } from 'vue-router';
import Storage from ".././plugins/storage.js";
import createPersonalScoreCardMutationFile from '../graphql/createPersonalScoreCard.mutation.gql'
import savePersonalScoreCardMutationFile from '../graphql/savePersonalScoreCard.mutation.gql'
import deletePersonalScoreCardMutationFile from '../graphql/deletePersonalScoreCard.mutation.gql'
import { ScreenOrientation } from '@ionic-native/screen-orientation'
import translate from '@/plugins/translate'

export default defineComponent({
  name: 'LivescoringSC',
  components: {
    IonContent,
    IonHeader, IonImg,
    IonPage,
    IonTitle, 
    IonToolbar, IonCardContent, IonCardTitle, IonBadge, IonChip,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
   let loadingSpinner = null
   const route = useRoute()

   let comment = ref(route.query.comment)
   
   let livescoring = ref(null)
    const {onResult: getLivescoringResult, loading: getLivescoringLoading} = useQuery(getLivescoringQueryFile, () => ({ id: route.params.id == null ? 0 :  parseInt(route.params.id), hcpi: parseFloat(route.params.hcpi), comment: route.query.comment, personScorecardId: parseInt(route.query.PersonScorecardId) }), () => ({ fetchPolicy: 'no-cache', enabled: route.params.id != null && route.params.hcpi != null }))

    getLivescoringResult(async (queryResult) => {
      livescoring.value = queryResult.data.getLivescoringSC.livescoring
      
      if(livescoring.value.comment!=null){
        comment.value = livescoring.value.comment
      }else{
        comment.value = route.query.comment
      }

      // only using local storage for today score card
      // get scorecard from local storage, compare updatedAt and set into scoresPlayer
      let localScores =  await Storage.getItem('personscorecard_'+route.params.id+'_'+route.params.hcpi+'_'+comment.value)      
      let localScoresUpdatedAt =  await Storage.getItem('personscorecard_'+route.params.id+'_'+route.params.hcpi+'_'+comment.value+'_updatedAt')
      
      if(localScores && route.query.UsingLocalStorage==='true' ){
        if(!livescoring.value.updatedAt || parseInt(localScoresUpdatedAt)>parseInt(livescoring.value.updatedAt)){
          livescoring.value.scoresPlayer.forEach((element,index) => {     
            livescoring.value.scoresPlayer[index]=parseInt(localScores.split(",")[index])
          });
        }
      }

    })

    const decreaseScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return
      else if (scoring_mode != 1 && scores[index] == 0)
        return
      else
        scores[index]--
    }

    const getGBE = (pars, vorgaben, scores, index, gbe) => {
      if (scores[index] == -1 || scores[index] > 0) {
        const nettoDB = pars[index] + vorgaben[index].length + 2
        if (nettoDB > scores[index]) {
          gbe[index] = scores[index]
          return scores[index]
        }
        else {
          gbe[index] = nettoDB
          return nettoDB
        }
      }
      else if (scores[index] == 0) {
        gbe[index] = 0
        return 0
      }
    }

    const getStb = (pars, vorgaben, scores, index, stb) => {
      if (scores[index] > 0) {
        const nettoDB = pars[index] + vorgaben[index].length + 2
        if (nettoDB > scores[index]) {
          stb[index] = (pars[index] + vorgaben[index].length) - scores[index] + 2
          return (pars[index] + vorgaben[index].length) - scores[index] + 2
        }
        else {
          stb[index] = 0
          return 0
        }
      }
      else {
        stb[index] = 0
        return 0
      }
    }

    const showScore = (scoring_mode, scores, index) => {
      if (scoring_mode == 1 && scores[index] == -1)
        return '-'
      else if (scores[index] == 0)
        return ''
      else
        return scores[index]
    }

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(() => { 
        if (!getLivescoringLoading.value) 
          loadingSpinner.dismiss() 
        })
    }).catch(err => { })

    watch(getLivescoringLoading, (getLivescoringLoading, prevLoading) => {
      if (!getLivescoringLoading && loadingSpinner != null) {
        loadingSpinner.dismiss()
        return
      }
      else if (getLivescoringLoading)
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(() => { 
                if (!getLivescoringLoading) 
                  loadingSpinner.dismiss() 
                })
            }).catch(err => { })
    })
    
    if (isPlatform('capacitor'))
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE)

    /*if (isPlatform('capacitor') && isPlatform('ios') && ScreenOrientation.type != 'landscape-primary') {
      const alert =  alertController
        .create({
          header: translate('personalScorecard'),
          message: translate('useLandscape'),
          buttons: [
            {
              text: translate('ok') ,
              handler: async () => {
              },
            },
          ],
        }).then(res => {
          res.present()
        })
    }*/

    watch(
      () => route.params.id,
      (id, prevId) => {
        if (id == undefined && prevId > 0 && isPlatform('capacitor'))
          ScreenOrientation.unlock()
        else if (route.path.startsWith('/livescoringsc/') && id != undefined && isPlatform('capacitor')) {
          ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE)
          /*if (isPlatform('capacitor') && isPlatform('ios') && ScreenOrientation.type != 'landscape-primary') {
            const alert =  alertController
              .create({
                header: translate('personalScorecard'),
                message: translate('useLandscape'),
                buttons: [
                  {
                    text: translate('ok') ,
                    handler: async () => {
                    },
                  },
                ],
              }).then(res => {
                res.present()
              })
          }*/
        }
      }
    )

    const { mutate: createPersonalScoreCardMutation } = useMutation(createPersonalScoreCardMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: savePersonalScoreCardMutation } = useMutation(savePersonalScoreCardMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: deletePersonalScoreCardMutation } = useMutation(deletePersonalScoreCardMutationFile, { fetchPolicy: 'no-cache' });

    return {
      livescoring, personOutline, calendarClearSharp, dayjs, createOutline, 
      removeCircle, addCircle, arrowDownCircleOutline, arrowUpCircleOutline,trashOutline,saveOutline,closeCircleOutline,
       showScore, decreaseScore, getGBE, getStb,
       createPersonalScoreCardMutation, savePersonalScoreCardMutation, deletePersonalScoreCardMutation,
       comment
    }
  },
  computed: {    
    UserData : function(){ return this.$store.getters.UserData},
  },
  methods: {
    async saveScoreLocal() {
      if(this.$route.query.UsingLocalStorage==='true'){
        await Storage.setItem('personscorecard_'+this.$route.params.id+'_'+this.$route.params.hcpi+'_'+this.comment, this.livescoring.scoresPlayer);
        await Storage.setItem('personscorecard_'+this.$route.params.id+'_'+this.$route.params.hcpi+'_'+this.comment+'_updatedAt', new Date().getTime());
      }
      if(this.UserData){
        this.savePersonalScoreCard()
      }
    },
    async clearScoreCard(){
      const alert = await alertController
        .create({
          header: this.$t('personalScorecard'),
          message: this.$t('wantToCleanScoreCard'),
          buttons: [
            {
              text: this.$t('cancel') ,
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: this.$t('ok') ,
              handler: async () => {
                this.livescoring.scoresPlayer.forEach((element,index) => {     
                  this.livescoring.scoresPlayer[index]=0
                });
                Storage.removeItem('personscorecard_'+this.$route.params.id+'_'+this.$route.params.hcpi+'_'+this.comment);
                Storage.removeItem('personscorecard_'+this.$route.params.id+'_'+this.$route.params.hcpi+'_'+this.comment+'_updatedAt');
                if(this.UserData){   
                  
                  var newScore = "0";
                  for (var i = 1; i < this.livescoring.player.numHoles; i++) {
                    newScore += ",0";
                  }             
                  this.savePersonalScoreCardMutation({id: parseInt(this.$route.query.PersonScorecardId), teeId: parseInt(this.$route.params.id), scores: newScore, hcpi: parseFloat(this.$route.params.hcpi), comment: this.comment, updatedAt: new Date().getTime().toString()})
                  .then(res => {
                    if (res && res.data && res.data.savePersonalScoreCard && res.data.savePersonalScoreCard.success) {
                    }
                    else {
                      toastController
                        .create({
                          message: this.$t('notSuccessful'),
                          duration: 3000,
                          color: 'danger'
                        }).then(res => res.present())
                    }
                  })
                  .catch(err => {
                      toastController
                        .create({
                          message: this.$t('notSuccessful'),
                          duration: 3000,
                          color: 'danger'
                        }).then(res => res.present())
                  }) 
                }
              },
            },
          ],
        });
      return alert.present();
    },
    savePersonalScoreCard() {
      this.savePersonalScoreCardMutation({id: parseInt(this.$route.query.PersonScorecardId), teeId: parseInt(this.$route.params.id), scores: this.livescoring.scoresPlayer.toString(), hcpi: parseFloat(this.$route.params.hcpi), comment: this.comment, updatedAt: new Date().getTime().toString()})
      .then(res => {
        if (res && res.data && res.data.savePersonalScoreCard && res.data.savePersonalScoreCard.success) {
        }
        else {
          toastController
            .create({
              message: this.$t('notSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('notSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },    
    deletePersonalScoreCard() {
      this.deletePersonalScoreCardMutation({id: parseInt(this.$route.query.PersonScorecardId), teeId: parseInt(this.$route.params.id), hcpi: parseFloat(this.$route.params.hcpi), comment: this.comment})
      .then(res => {
        if (res && res.data && res.data.deletePersonalScoreCard && res.data.deletePersonalScoreCard.success) {
          toastController
            .create({
              message: this.$t('deleteSuccessful'),
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
        }
        else {
          toastController
            .create({
              message: this.$t('notSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('notSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    },
    createNewScoreCard(){
      var currentTime = new Date();
      var newComment= currentTime.getHours()+':'+(currentTime.getMinutes()<10?'0':'')+currentTime.getMinutes()+':'+(currentTime.getSeconds()<10?'0':'')+currentTime.getSeconds()
      var newScore = "0";
      for (var i = 1; i < this.livescoring.player.numHoles; i++) {
        newScore += ",0";
      }
      
      this.createPersonalScoreCardMutation({id: parseInt(this.$route.query.PersonScorecardId), teeId: parseInt(this.$route.params.id), scores: newScore, hcpi: parseFloat(this.$route.params.hcpi), comment: newComment, updatedAt: new Date().getTime().toString()})
      .then(res => {
        if (res && res.data && res.data.createPersonalScoreCard && res.data.createPersonalScoreCard.success) {
          this.$router.push(this.$route.path + '?PersonScorecardId='+res.data.createPersonalScoreCard.personScorecardId+'&comment='+newComment+'&UsingLocalStorage=true')
        }
        else {
          toastController
            .create({
              message: this.$t('notSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
        }
      })
      .catch(err => {
          toastController
            .create({
              message: this.$t('notSuccessful'),
              duration: 3000,
              color: 'danger'
            }).then(res => res.present())
      }) 
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

ion-col.numcol {
    flex: 0 0 25px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    vertical-align: center;
  }

ion-col.parindexcol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

ion-col.gbecol {
    flex: 0 0 50px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

ion-col.lengthcol {
    flex: 0 0 80px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

ion-col.vorgabecol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }


ion-col.scorecol {
    flex: 0 0 150px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.wincol {
    flex: 0 0 100px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

.sizebig {
  height: 45px;
  width: 45px;
  vertical-align: top;
}

.borderright {
    border-right: 1px solid grey;
}

.bordertop {
    border-top: 1px solid grey;
}

ion-button {
    --box-shadow: 0px 0px 2px 1px rgb(0, 0, 0, 0.25);
}

</style>