<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Livescoring</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-card v-if="livescoring" id="scorecard" >
        <ion-card-header>
          <ion-card-title>
            <ion-item class="ion-no-padding">
              <h3>{{ livescoring.name  }} - {{ $t('round')}} {{ livescoring.round_index  }}</h3>
              <ion-button v-if="livescoring.allowRanking" @click="openRanking()" slot="end" class="ion-no-margin ion-no-padding" fill="clear">
                <ion-icon style="zoom: 1.4; vertical-align: top;" color="primary" :md="openOutline" :ios="openOutline"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <main class="paginated" v-if="livescoring.scoring_mode != 3">
          <div class="holes score page"> <div class="hole multi hole1">
          <ion-grid>
            <ion-row>
              <ion-col size="auto">
                <ion-button v-if="checkGoBack()" color="warning" @click="goBack()">
                  <ion-icon :md="chevronBackOutline" :ios="chevronBackOutline" style="zoom: 1.6"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col size="auto">
                <ion-button v-if="checkGoForward()" color="warning" @click="goForward()">
                  <ion-icon :md="chevronForwardOutline" :ios="chevronForwardOutline" style="zoom: 1.6"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col size="auto">
                <ion-button v-if="livescoring.refCall" color="dangerlight" @click="doRefCall()">
                  {{ $t('Referee Call') }}
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <h3>{{ $t('hole') }} {{ livescoring.holes[selectedHole] }}</h3>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="auto"  v-for="(player, index) in livescoring.players.filter(f => f.nameLong != '')" :key="index">
                <div v-html="player.nameLong" class="name ion-no-padding ion-no-margin" style="margin-left: 0px; white-space: pre-wrap;" ></div>
                <div  v-if="livescoring.scoring_mode == 0 || livescoring.scoring_mode == 8" class="name ion-no-padding ion-no-margin">({{ player.sumStrokes }}/{{ player.overPar == 0 ? '+-0' : (player.overPar > 0 ? '+' + player.overPar : player.overPar)  }})</div>
                <div class="holeInfo" v-if="player.holeInfo">{{ player.holeInfo[selectedHole] }}</div>
                <div class="strokes">
                <input type="text" title="Schlaganzahl" class="strokes disabled" :value="player.scores[selectedHole] == 0 ? '' : player.scores[selectedHole] == -1 ? '-' : player.scores[selectedHole]" @click="player.scores[selectedHole] = player.pars[selectedHole]" />
                <div class="buttons">
                <button class="par" :value="player.pars[selectedHole]"  @click="player.scores[selectedHole] = player.pars[selectedHole]">Par</button>
                <button class="add" :value="player.pars[selectedHole]" @click="player.scores[selectedHole]++">&#x25b2;</button> 
                <button v-if="livescoring.scoring_mode == 1" class="slash" @click="player.scores[selectedHole] = - 1">—</button>
                <button class="sub" :value="player.pars[selectedHole]" @click="player.scores[selectedHole] == 0 ? '' : player.scores[selectedHole]--">&#x25bc;</button>
                </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <button v-if="checkSaveAndGo()" class="send holes" @click="saveAndGo()">{{ $t('saveAndGo') }}</button>
                <button v-else class="send holes" disabled>{{ $t('RoundFinished') }}</button>
              </ion-col>
            </ion-row>
          </ion-grid>
          </div></div>
          </main>

          <!-- Matchplay -->
          <main class="paginated" v-if="livescoring.scoring_mode == 3">
          <div class="holes score page"> <div class="hole multi hole1">
          <ion-grid>
            <ion-row>
              <ion-col size="auto">
                <ion-button v-if="checkGoBack()" color="warning" @click="goBack()">
                  <ion-icon :md="chevronBackOutline" :ios="chevronBackOutline" style="zoom: 1.6"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col size="auto">
                <ion-button v-if="checkGoForward()" color="warning" @click="goForward()">
                  <ion-icon :md="chevronForwardOutline" :ios="chevronForwardOutline" style="zoom: 1.6"></ion-icon>
                </ion-button>
              </ion-col>
              <ion-col size="auto">
                <ion-button v-if="livescoring.refCall" color="dangerlight" @click="doRefCall()">
                  {{ $t('Referee Call') }}
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <h3>{{ $t('hole') }} {{ livescoring.holes[selectedHole] }}</h3>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12"  v-for="(player, index) in livescoring.players.filter(f => f.nameLong != '')" :key="index">
                <div v-html="player.nameLong" class="name ion-no-padding ion-no-margin" style="margin-left: 0px; white-space: pre-wrap;" ></div>
                <div class="holeInfo" v-if="player.holeInfo">{{ player.holeInfo[selectedHole] }}</div>
                <div class="strokes">
                <input type="text" title="Schlaganzahl" class="strokes disabled" :value="player.scores[selectedHole] == 0 ? '' : player.scores[selectedHole] == -1 ? '-' : player.scores[selectedHole]" @click="player.scores[selectedHole] = player.pars[selectedHole]" />
                <div class="buttons">
                <button class="par" :value="player.pars[selectedHole]"  @click="player.scores[selectedHole] = player.pars[selectedHole]">Par</button>
                <button class="add" :value="player.pars[selectedHole]" @click="player.scores[selectedHole]++">&#x25b2;</button> 
                <button v-if="livescoring.scoring_mode == 1" class="slash" @click="player.scores[selectedHole] = - 1">—</button>
                <button class="sub" :value="player.pars[selectedHole]" @click="player.scores[selectedHole] == 0 ? '' : player.scores[selectedHole]--">&#x25bc;</button>
                </div>
                </div>
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="12">
                <button v-if="checkSaveAndGo()" class="send holes" @click="saveAndGo()">{{ $t('saveAndGo') }}</button>
              </ion-col>
            </ion-row>
          </ion-grid>
          </div></div>
          </main>

        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>


<script>

import { IonLabel, IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonCardTitle,
        IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol, IonBadge, loadingController, IonChip,
        toastController, IonImg, modalController, alertController } from '@ionic/vue';
import { defineComponent, ref, watch, computed } from 'vue';
import { personOutline, calendarClearSharp, createOutline, removeCircle, addCircle, arrowDownCircleOutline, arrowUpCircleOutline, openOutline, chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { useQuery, useResult, useMutation } from '@vue/apollo-composable'
import getLivescoringOnlyQueryFile from '../graphql/getLivescoringOnly.query.gql'
import saveLivescoringScoreMutationFile from '../graphql/saveLivescoringScore.mutation.gql'
import refereeCallMutationMutationFile from '../graphql/refereeCall.mutation.gql'
import * as dayjs from 'dayjs';
import { useRouter, useRoute } from 'vue-router';
import Signature from "./SignaturePad"
import { isPlatform } from '@ionic/vue';
import translate from '@/plugins/translate'
import domtoimage from "dom-to-image-more"
import { Geolocation } from '@capacitor/geolocation';
import ShowLivescoringResultDialog from './dialogs/ShowLivescoringResultDialog'

export default defineComponent({
  name: 'Livescorings',
  components: {
    IonContent,
    IonHeader, Signature, IonImg,
    IonPage,
    IonTitle, 
    IonToolbar, IonCardContent, IonCardTitle, IonBadge, IonChip,
    IonButton, IonLabel, IonCard, IonCardHeader, IonItem, IonIcon, IonProgressBar, IonGrid, IonRow, IonCol
  },
  setup() {
    let loadingSpinner = null
    const router = useRouter()
    const route = useRoute()
    let loaded = ref(false)
    let selectedHole = ref(0)

    const {onResult,  refetch: getLivescoringOnlyRefetch, result: getLivescoringOnlyResult, loading: getLivescoringOnlyLoading } = useQuery(getLivescoringOnlyQueryFile, () => ({ id: route.path.startsWith('/livescoringonly/') ? parseInt(route.params.id) : 0, fid: route.query.fid == undefined ? 0 : parseInt(route.query.fid), rpid: route.query.rpid == undefined ? 0 : parseInt(route.query.rpid), zid: route.query.zid == undefined ? 0 : parseInt(route.query.zid), pid: route.query.pid == undefined ? 0 : parseInt(route.query.pid)  }), () => ({ fetchPolicy: 'no-cache', enabled: route.params.id != null && route.path.startsWith('/livescoringonly/') }))
    const livescoring = useResult(getLivescoringOnlyResult, null, data => data.getLivescoringOnly.livescoring)

    onResult(queryResult => {
      if (!loaded.value && queryResult && queryResult.data && queryResult.data.getLivescoringOnly && queryResult.data.getLivescoringOnly.livescoring) {
        let numHoles = livescoring.value.wertung == 0 ? 18 : 9
        selectedHole.value = queryResult.data.getLivescoringOnly.livescoring.start_tee - 1
        let savedScores = 0
        for (const player of livescoring.value.players) {
          let pSavedScores = 0
          for (let i = 0; i < numHoles; i++) {
            if (player.scoresSaved[(i + selectedHole.value) > numHoles-1 ? 0 : (i + selectedHole.value)] == true)
              pSavedScores++
          }
          if (pSavedScores < savedScores || savedScores == 0)
            savedScores = pSavedScores
        }
        if (savedScores > 0) {
          selectedHole.value = selectedHole.value + savedScores
          if (selectedHole.value > (numHoles - 1))
            selectedHole.value = 0
        }
        loaded.value = true
      }
    })

    const { mutate: saveLivescoringScoreMutation } = useMutation(saveLivescoringScoreMutationFile, { fetchPolicy: 'no-cache' });
    const { mutate: refereeCallMutation } = useMutation(refereeCallMutationMutationFile, { fetchPolicy: 'no-cache' });

    loadingController.create().then(res => {
      loadingSpinner = res
      loadingSpinner.present().then(async ()  => { 
        if (!getLivescoringOnlyLoading.value && loadingSpinner != null) {
          loadingSpinner.dismiss() 
          loadingSpinner = null
        }
      })
    }).catch(err => { })

    watch(getLivescoringOnlyLoading, async (newLoading, prevLoading)=> {
      if (!newLoading && loadingSpinner != null) {
          loadingSpinner.dismiss()
          loadingSpinner = null
          return
      }
      else if (newLoading) {
        loadingController.create().then(res => {
              loadingSpinner = res
              loadingSpinner.present().then(async () => { 
                if (!getLivescoringOnlyLoading.value && loadingSpinner) {
                  loadingSpinner.dismiss() 
                  loadingSpinner = null
                }
              })
            }).catch(err => { })
      }
    })

    return {
      livescoring, personOutline, calendarClearSharp, dayjs, createOutline, route, saveLivescoringScoreMutation, getLivescoringOnlyRefetch,
      removeCircle, addCircle, arrowDownCircleOutline, arrowUpCircleOutline, openOutline, isPlatform, selectedHole, loaded, chevronBackOutline, chevronForwardOutline, refereeCallMutation, router
    }
  },
  data () {
    return {
      synth: window.speechSynthesis,
    }
  },
  methods: {
    doRefCall() {
      alertController.create({
                  header: translate('Referee Call'),
                  message: translate('refereeText'),
                  buttons: [
                    {
                      text: translate('no'),
                    },
                    {
                      text: translate('yes'),
                      handler: () => {
                        this.refereeCallMutation({ refCall: { flightId: this.livescoring.flightId, holeNumber: this.selectedHole+1} }).then(res => { 
                        })
                      }
                    }
                    ] }).then(res => { res.present() })
    },
    checkGoBack() {
      const currentHole = this.selectedHole.valueOf()
      if (!this.livescoring.goBackOnlyOne && currentHole+1 > this.livescoring.start_tee) {
        return true
      }
      else if (this.livescoring.goBackOnlyOne == true && currentHole > 0 && this.livescoring.players.filter(f => f.nameLong != '')[0].scoresSaved[currentHole] == false) {
        return true
      }
      else {
        return false
      }
    },
    checkGoForward() {
      if (!this.livescoring.goBackOnlyOne)
        return false
      else {
        return false
      }
    },
    goBack() {
      let numHoles = this.livescoring.wertung == 0 ? 18 : 9
      if (this.selectedHole == 0)
        this.selectedHole = numHoles -1
      else 
        this.selectedHole--
    },
    goForward() {
      let numHoles = this.livescoring.wertung == 0 ? 18 : 9
      if (this.selectedHole == numHoles-1)
        this.selectedHole = 0
      else 
        this.selectedHole++
    },
    checkSaveAndGo() {
      let numHoles = this.livescoring.wertung == 0 ? 18 : 9
      let returnVal = false
      if (this.selectedHole >= numHoles)
        return false
      for (const player of this.livescoring.players.filter(f => f.nameLong != '')) {
        if (player.scores.filter(f => f > 0).length < numHoles || player.scoresSaved.filter(f => f == true).length < numHoles) {
          returnVal = true
          break
        }
      }
      return returnVal
    },
    async saveAndGo() {
      let checkVal = true
      for (const player of this.livescoring.players.filter(f => f.nameLong != '')) {
        player.scoresSaved[this.selectedHole] = true
        if (player.scores[this.selectedHole] == 0) {
          checkVal = false
          //break
        }
      }
      if (!checkVal) {
        toastController.create({message: this.$t('saveScoreError'),duration: 5000,color: 'danger'}).then(res => res.present())
        return
      }

      let numHoles = this.livescoring.wertung == 0 ? 18 : 9

      let allScoresIn = true
      for (const player of this.livescoring.players.filter(f => f.nameLong != '')) {
        if (player.scoresSaved.filter(f => f == true).length != numHoles) {
          allScoresIn = false
          break
        }
      }

      const currentHole = this.selectedHole.valueOf()
      if (!allScoresIn) {
        if (this.selectedHole+1 == numHoles)
          this.selectedHole = 0
        else if (this.selectedHole < numHoles-1)
          this.selectedHole = this.selectedHole + 1
      }
      

      Geolocation.getCurrentPosition().then(res => {
        let scores = []
        for (const player of this.livescoring.players.filter(f => f.nameLong != '')) {
          scores.push({ roundNum: this.livescoring.round_id, teamNum: player.teamNum, personId: player.id, roundPlayerId: player.roundPlayerId, hole: currentHole+1, score: player.scores[currentHole], latitude: res.coords.latitude, longitude: res.coords.longitude })
        }
        for (const player of this.livescoring.players.filter(f => f.nameLong == '')) {
          scores.push({ roundNum: this.livescoring.round_id, teamNum: player.teamNum, personId: player.id, roundPlayerId: player.roundPlayerId, hole: currentHole+1, score: 0, latitude: res.coords.latitude, longitude: res.coords.longitude })
        }
        this.saveLivescoringScoreMutation({ scores: scores, flightId: this.livescoring.flightId, fid: this.livescoring.flightId, rpid: this.$route.query.rpid == undefined ? 0 : parseInt(this.$route.query.rpid), zid: this.$route.query.zid == undefined ? 0 : parseInt(this.$route.query.zid), pid: this.$route.query.pid == undefined ? 0 : parseInt(this.$route.query.pid) }).then(res => { 
          if (res.data.saveLivescoringScore.errorCode == 'f0002')
            this.router.push('/livescorings')
          else if (res.data.saveLivescoringScore.errorCode == 'f0003') {
            toastController.create({message: this.$t('newFlight'),duration: 5000,color: 'danger'}).then(res => res.present())
            this.selectedHole = currentHole
            this.getLivescoringOnlyRefetch()
          }
          else
            this.getLivescoringOnlyRefetch()
         })
      }).catch(err => {
        let scores = []
        for (const player of this.livescoring.players.filter(f => f.nameLong != '')) {
          scores.push({ roundNum: this.livescoring.round_id, teamNum: player.teamNum, personId: player.id, roundPlayerId: player.roundPlayerId, hole: currentHole+1, score: player.scores[currentHole] })
        }
        for (const player of this.livescoring.players.filter(f => f.nameLong == '')) {
          scores.push({ roundNum: this.livescoring.round_id, teamNum: player.teamNum, personId: player.id, roundPlayerId: player.roundPlayerId, hole: currentHole+1, score: 0 })
        }
        this.saveLivescoringScoreMutation({ scores: scores,  flightId: this.livescoring.flightId, fid: this.livescoring.flightId, rpid: this.$route.query.rpid == undefined ? 0 : parseInt(this.$route.query.rpid), zid: this.$route.query.zid == undefined ? 0 : parseInt(this.$route.query.zid), pid: this.$route.query.pid == undefined ? 0 : parseInt(this.$route.query.pid) }).then(res => {  
          if (res.data.saveLivescoringScore.errorCode == 'f0002')
            this.router.push('/livescorings')
          else if (res.data.saveLivescoringScore.errorCode == 'f0003') {
            toastController.create({message: this.$t('newFlight'),duration: 5000,color: 'danger'}).then(res => res.present())
            this.selectedHole = currentHole
            this.getLivescoringOnlyRefetch()
          }
          else
            this.getLivescoringOnlyRefetch()
        })
      })

      //this.saveScore()

    },
    async openRanking() {
      const modal = await modalController
        .create({
          component: ShowLivescoringResultDialog,
          cssClass: this.isPlatform('mobile') ? '' : 'tournamentLists-modal',
          componentProps: {
            propsData: {
              livescoring: this.livescoring,
            }
          },
        })
      return modal.present();
    },
    speak(list, interval, count) {
      if (list.length) {
        var msg = new SpeechSynthesisUtterance();
        msg.text = list[0];

        this.synth.speak(msg);

        setTimeout(() => {
            this.speak(list.slice(1), (count % 3 == 0 ? 2000 : 300), count + 1)
        }, interval)
      }
    },
    read() {
      let list = []
      for (const score of this.livescoring.scoresPlayer) {
        if (score > 0)
          list.push(score)
      }
      this.speak(list, 300, -1)
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

section {
  margin-top: 1em;
  margin-bottom: 2.5em;
}

section:not(.full-width),
.full-width > header {
  padding: 0 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

ion-col.numcol {
    flex: 0 0 25px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    vertical-align: center;
  }

ion-col.parindexcol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }

ion-col.vorgabecol {
    flex: 0 0 30px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
  }


ion-col.scorecol {
    flex: 0 0 150px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.matchplayscorecol {
    flex: 0 0 50px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

ion-col.wincol {
    flex: 0 0 100px;
    padding:0;
    margin-right:0px;
    font-size: 18px; 
    font-weight: bold;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
  }

.sizebig {
  height: 45px;
  width: 45px;
  vertical-align: top;
}

.borderright {
    border-right: 1px solid grey;
}

.bordertop {
    border-top: 1px solid grey;
}

div.strokes {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 280px;
    margin: 0 auto 0px auto;
}

div.strokes input.strokes {
    display: block;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #f5f5f5;
    border: solid 7px #999;
    color: red;
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    line-height: 130px;
    box-shadow: inset 0 0 25px rgba(0,0,0,.5);
    flex: 0 0 auto;
    margin-left: 40px;
    cursor: pointer;
    pointer-events: auto;
    outline: none;
    text-shadow: 0 0 0 red;
}

div.strokes input.strokes:focus {
    border-color: #008aca;
    text-shadow: 0 0 0 red;
    color: transparent;
}

main h3 {
    padding: 5px 10px;
    font-weight: 400;
    font-size: 16px
}

div.hole h3 {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        padding: 5px;
        background: #008aca;
        color: #ffe600
}

div.hole div.name {
        font-size: 20px;
        font-weight: bold;
        color: #008aca;
        padding: 5px 5px 0 5px;
        background: #e5e5e5;
        margin: 0;
        text-align: center;
}

div.hole div.holeInfo {
        margin: 0 0 20px 0;
        font-style: italic;
        font-size: 12px;
        background: #e5e5e5;
        padding: 0 0 5px 0;
        text-align: center;
        font-weight: bold;
}

div.hole.match div.holeInfo {
    margin: 0
}

div.hole div.holeInfo.noMargin {
    margin: 0
}

div.hole div.holeInfo.solo {
    padding: 5px;
    text-align: left
}

button[disabled], html input[disabled] {
        cursor: default
}

.blue {
    background: #008aca;
    color: #fff !important
}

.red {
    background: #da534f;
    color: #fff !important
}

.center {
    text-align: center
}

div.hole div.buttons button {
    font-size: 28px;
    font-weight: 700;
    width: 60px;
    height: 60px;
    line-height: 40px;
    text-align: center;
    padding: 0;
    display: block;
    margin-bottom: 10px;
    margin-left: 10px;
}

button {
    border: none;
    padding: 5px;
    border-radius: 3px;
    background: #008aca;
    color: #fff;
}

button.send.holes {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 0 auto
}
div.hole button.send {
    background: #da534f;
    font-size: 16px;
}
</style>